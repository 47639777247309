import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ItemBoxTitlesContainer from "pages/Components/CartAndCheckout/components/Item/ItemBoxTitlesContainer";
import ItemDiscountCode from "pages/Components/CartAndCheckout/components/Item/ItemDiscountCode";
import ItemGroupTitle from "pages/Components/CartAndCheckout/components/Item/ItemGroupTitle";
import ItemHeaderGroupContainer from "pages/Components/CartAndCheckout/components/Item/ItemHeaderGroupContainer";
import { useCartItemsContext } from "core/contexts/CartItemsContext";

type Props = {
    type: string,
    groupStatus?: string,
    itemGroupBoxWidth: number,
    itemBoxWidth: number,
    itemBoxImageWidth: number,
    itemBoxDescriptionWidth: number,
    itemBoxDescriptionLeftRightMargin: number,
    quantityBoxContainerWidth: number,
    itemBoxPriceWidth: number,
    items: CartProduct[],
    setValidationError: (discountCode: string, applyTarget: string, errorMessage: string) => void,
    isEmptyItems: boolean,
};

const ItemHeaderContainer: React.FC<Props> = (
    {
        type,
        groupStatus = undefined,
        items,
        itemGroupBoxWidth,
        itemBoxWidth,
        itemBoxImageWidth,
        itemBoxDescriptionWidth,
        itemBoxDescriptionLeftRightMargin,
        quantityBoxContainerWidth,
        itemBoxPriceWidth,
        setValidationError,
        isEmptyItems,
    }
) => {
    const theme: Theme = useTheme();
    const { setConfirmationPopup } = useCartItemsContext();
    const displayFirstGroupOnly = (groupStatus === 'both' && type === 'event') || (groupStatus !== 'both' && type === groupStatus);
    const showItemBoxTitles = groupStatus === 'both' || type === groupStatus;

    const removeAllItemsHandler = () => {
        setConfirmationPopup('remove-items', true);
    };

    const style = css`
        ${isEmptyItems && css`
        opacity: 0.5;
    `}
    `;

    const titleStyle = css`
        text-align: left;
        // image width + description width + left/right margins of description
        width: ${itemBoxImageWidth + itemBoxDescriptionWidth + itemBoxDescriptionLeftRightMargin * 2}px;
    `;

    const qtyStyle = css`
        width: ${quantityBoxContainerWidth}px;

        ${theme.breakpoints.down('xs')} {
            display: none;
        }
    `;

    const priceStyle = css`
        width: ${itemBoxPriceWidth}px;

        ${theme.breakpoints.down('xs')} {
            display: none;
        }
    `;

    const removeButtonStyle = css`
        width: ${itemGroupBoxWidth - itemBoxWidth}px;
        color: ${theme.colours.red1};
        font-weight: ${theme.fonts.weights.bold};
        white-space: nowrap;
        text-decoration: underline;

        ${displayFirstGroupOnly && css`
            cursor: pointer;
        `}
        ${theme.breakpoints.down('xs')} {
            width: fit-content;
        }
    `;

    return (
        <div css={style}>
            <ItemHeaderGroupContainer itemGroupBoxWidth={itemGroupBoxWidth} itemBoxWidth={itemBoxWidth}>
                <ItemGroupTitle type={type} />
                <ItemDiscountCode
                    items={items}
                    isEmptyItems={isEmptyItems}
                    setValidationError={setValidationError}
                />
            </ItemHeaderGroupContainer>

            {showItemBoxTitles && (
                <ItemBoxTitlesContainer>
                    <div css={titleStyle}>Your list</div>
                    <div css={qtyStyle}>Qty</div>
                    <div css={priceStyle}>Price</div>
                    <div
                        css={removeButtonStyle}
                        {...displayFirstGroupOnly && {
                            onClick: removeAllItemsHandler
                        }}
                    >
                        {displayFirstGroupOnly ? 'Remove all' : ''}
                    </div>
                </ItemBoxTitlesContainer>
            )}
        </div>
    );
};

export default ItemHeaderContainer;