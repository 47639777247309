import React, { ReactNode } from "react";
import { css } from "@emotion/react";

import theme from "theme/index";

type Props = {
    children: ReactNode,
    alignItem?: string,
    paddingLeftForSmallBreakpoint?: number,
    borderColour?: string,
};

const HeaderContainer: React.FC<Props> = (
    {
        children,
        alignItem = 'start',
        paddingLeftForSmallBreakpoint = 0,
        borderColour = theme.colours.curiousBlue2,
    }
) => {
    const style = css`
        display: flex;
        justify-content: space-between;
        align-items: ${alignItem};
        padding-bottom: 17px;
        border-bottom: 1px solid ${borderColour};

        ${theme.breakpoints.down('lg')} {
            padding-bottom: 10px;
        }

        ${theme.breakpoints.down('xs')} {
            padding-left: ${paddingLeftForSmallBreakpoint}px;
            align-items: end;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default HeaderContainer;