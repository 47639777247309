// Colours - as per http://chir.ag/projects/name-that-color
// Useful alternative Colours https://www.color-blindness.com/color-name-hue

const red = {
    50: '#fff2f2',
    100: '#ffcdd2',
    200: '#ef9a9a',
    300: '#e57373',
    350: '#DC676C',
    400: '#ef5350',
    500: '#f44336',
    550: '#F0174B',
    600: '#e53935',
    700: '#d32f2f',
    800: '#c62828',
    900: '#b71c1c',
    999: '#ff0000',
};

const green = {
    100: '#C8E6C9',
    200: '#A5D6A7',
    300: '#81C784',
    400: '#66BB6A',
    500: '#4CAF50',
    600: '#43A047',
    700: '#388E3C',
    800: '#2E7D32',
    900: '#1B5E20',
    950: '#145819',
};

const grey = {
    50: '#fafafa',
    80: '#F3F3F3',
    90: '#F4F4F4',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    450: '#AAAAAA',
    500: '#9b9b9b',
    600: '#757575',
    650: '#707070',
    700: '#757575',
    720: '#555555',
    800: '#424242',
    900: '#212121',
    920: '#1B1D28',
};

const blue = {
    50: '#E3F2FD',
    100: '#AAD4EF',
    200: '#05AEF0',
    250: '#1D8BDB',
    300: '#0076FF',
    400: '#156CBC',
    450: '#0869B2',
    500: '#0964A7',
    600: '#364D9B',
    620: '#0964A7',
    650: '#3B528F',
    700: '#0C4A93',
    800: '#222247',
    900: '#3C3E44',
};

const colours = {
    borderColor: grey[300],
    primary: green[900],
    secondary: red[500],
    black: '#000000',
    white: '#FFFFFF',
    blue,
    green,
    grey,
    red,
    transparent: 'rgba(0,0,0,0)', // Useful for transitions between no fill and fill.,

    alabaster: '#F8F8F8',
    alabaster2: '#F7F7F7',
    alabaster3: '#FCFCFC',
    alto: '#D3D3D3',
    alto2: '#CECECE',
    alto3: '#D0D0D0',
    alto4: '#D1D1D1',
    alto5: '#DEDEDE',
    aluminium: '#A9ABB4',
    amber: '#FFC400',
    anakiwa: '#8EDCFF',
    anakiwa2: '#B1D3FF',
    appleBlossom: '#A94442',
    athensGray: '#FAFAFB',
    azureRadiance: '#0188E6',
    azureRadiance2: '#1778F2',
    blueRibbon: '#0971FF',
    boulder: '#7B7B7B',
    boulder2: '#797979',
    brickRed: '#C73652',
    bridesmaid: '#FEEFEE',
    blueMarguerite: '#6559CA',
    buttercup: '#F7B41B',
    candyCorn: '#FCE359',
    carnation: '#FA6A6A',
    carnationPink: '#FFA1CE',
    cerise: '#D9317A',
    ceriseRed: '#E33F5F',
    cerulean: '#0EA0EB',
    chateauGreen: '#37B44E',
    cinnabar: '#E23D44',
    codGray: '#1C1C1C',
    concrete: '#F2F2F2',
    concrete2: '#F3F2F2',
    conifer: '#8ADE5E',
    corn: '#E6B407',
    cornflower: '#9DCDED',
    cornflower2: '#9BC7E8',
    cornflowerBlue: '#6195ED',
    cream: '#FFFFCE',
    crimson: '#ED1C24',
    crimson2: '#E7161B',
    crusta: '#F77638',
    curiousBlue: '#1D8BDB',
    curiousBlue2: '#1D88DB',
    deepCerulean: '#066AB2',
    denim: '#117AC6',
    dodgerBlue: '#2086FC',
    dodgerBlue2: '#3894FF',
    doveGray: '#707070',
    doveGray2: '#6E6E6E',
    doveGray3: '#666666',
    doveGray4: '#656565',
    dustyGray: '#9A9A9A',
    eastSide: '#AD98CB',
    emerald: '#3ECB54',
    emperor: '#535353',
    emperor2: '#525252',
    fuchsiaBlue: '#893DC2',
    gallery: '#EFEFEF',
    gallery2: '#EEEDED',
    gold: '#FFD409',
    gray: '#8E8E8E',
    gray2: '#909090',
    gray3: '#7E7E7E',
    hawkesBlue: '#D2E2FE',
    illusion: '#F8A7CF',
    indigo: '#5258CF',
    karry: '#FFEBD4',
    koromiko: '#FEC564',
    koromiko2: '#FEC66D',
    koromiko3: '#FEC059',
    lightningYellow: '#F8C014',
    linkedinBlue: '#0072B1',
    linkWater: '#ECF5FA',
    lochmara: '#0984DA',
    malibu: '#77C6FF',
    malibu2: '#7FDEF5',
    malibu3: '#68BAF9',
    malibu4: '#5FA5FF',
    malibu5: '#7CD0FF',
    malibu6: '#68BAF8',
    mediumRedViolet: '#BC318F',
    mercury: '#E6E6E6',
    mercury2: '#E3E3E3',
    mineShaft: '#2E2E2E',
    mineShaft2: '#3E3E3E',
    mySin: '#FDB92A',
    orangePeel: '#FF9D00',
    onahau: "#D0EAFF",
    pattensBlue: '#D9E6FF',
    pictonBlue: '#37C6E9',
    pictonBlue2: '#39CCEF',
    pictonBlue3: '#49A0EA',
    pinkSalmon: '#FF86AC',
    pizazz: '#FF8800',
    quartz: '#C6D3ED',
    red1: '#FF0200',
    redRibbon: '#F0164B',
    raspberry: '#E30B5D',
    redOrange: '#FF3A2F',
    regentStBlue: '#A5C6E5',
    sail: '#A0D4F5',
    scienceBlue: '#005BD6',
    scorpion: '#5F5F5F',
    scorpion2: '#5A5A5A',
    scorpion3: '#606060',
    shipGray: '#414042',
    spindle: '#A9C0EB',
    silver: '#BDBDBD',
    silver2: '#C6C6C6',
    silver3: '#C3C3C3',
    silver4: '#BEBEBE',
    silverChalice: '#A7A7A7',
    silverChalice2: '#B2B2B2',
    silverChalice3: '#A8A8A8',
    silverChalice4: '#A3A3A3',
    solitude: '#E3EFFE',
    sunglow: '#FFD033',
    supernova: '#FFD00A',
    sushi: '#84B53E',
    tropicalBlue: '#B9DBF5',
    tundora: '#4A4A4A',
    tundora2: '#4D4D4D',
    twitterBlue: '#26A7DE',
    viking: '#61B1E3',
    wellRead: '#B53533',
    wildWatermelon: '#FE4B76',
    wildWatermelon2: '#FD4B76',
};

const status = {
    success: colours.green[500],
    error: colours.red[500],
    info: colours.blue[500],
};

const countries: any = {
    nz: '#1D87DB',
    au: '#FAC002',
    multiple: '#FE8800',
};

const successStories: any = [
    { target: "primary", colour: "#1D88DB" },
    { target: "intermediate", colour: "#FAC002" },
    { target: "high", colour: "#F0174B" },
    { target: "college", colour: "#F0174B" },
    { target: "grammar", colour: "#F0174B" },
];

/**
 * Convert a hexadecimal integer to a decimal integer.
 *
 * @param {string} hex Hexadecimal integer.
 * @returns {number} Decimal integer.
 */
const hexToInt = (hex: string) => parseInt(parseInt(hex, 16).toString(10));

const lightenHsl = (hsl: any, light: any) => {
    if (!hsl || hsl.length <= 0) {
        return "";
    }

    let newHsl = hsl.replace(/[hsl()]/g, '');
    newHsl = newHsl.split(', ');

    return `hsl(${newHsl[0]}, ${newHsl[1]}, ${light}%)`;
};

/**
 * Get an rgba colour from a given 6-digit hex colour and an alpha value (defaults to 1).
 *
 * @param {string} hex 6-digit hex rgb colour.
 * @param {number} alpha Alpha channel value.
 * @returns {string} rgba colour.
 */
const rgba = (hex: string, alpha = 1) => {
    if (!hex || hex.length !== 7) {
        throw Error(`Hex colour must have length of 7, got ${hex}`);
    }

    const rgb = [hex.substring(1, 3), hex.substring(3, 5), hex.substring(5, 7)];

    return `rgba(${hexToInt(rgb[0])}, ${hexToInt(rgb[1])}, ${hexToInt(rgb[2])}, ${alpha})`;
};

const utils = {
    hexToInt,
    lightenHsl,
    rgba,
};

const v = {
    ...colours,
    status,
    utils,
    countries,
    successStories,
};

export default v;
