import React from "react";
import { useTheme, css } from "@emotion/react";

import { formatAndAddDecimals, improveDecimals } from "core/includes/formatters";
import OrderTotalSummary from "pages/Components/ProductListPage/components/OrderTotalSummary";
import { getCurrencyByCountry, getTaxLabel } from "core/includes/finance";

type Props = {
    products: OrderListItem[],
    subtotal: number,
    taxAmount: number,
    totalAmount: number,
    taxPrefix?: string,
    hasFeatureFlag: boolean,
    countryCode?: CountryCodeItem,
    shipping?: number,
    isShippingTaxIncluded?: boolean,
    decimalsNum?: number,
};

const OrderSummaryTable: React.FC<Props> = (
    {
        products,
        subtotal,
        taxAmount,
        totalAmount,
        taxPrefix = 'excl.',
        hasFeatureFlag,
        countryCode,
        shipping = 0,
        isShippingTaxIncluded = false,
        decimalsNum = 0,
    }
) => {
    const theme = useTheme();

    const orderSummaryTableStyle = css`
        width: 100%;
        margin-top: 13px;
        border-spacing: 5px 10px;
        border-collapse: separate;
        table-layout: fixed;
        white-space: break-spaces;

        font-size: 14px;
        text-align: left;

        ${theme.breakpoints.up("5xl")} {
            font-size: 16px;
        }

        ${theme.breakpoints.down("md")} {
            font-size: 12px;
        }
    `;

    const theadStyle = css`
        min-width: 100%;
        border-spacing: 5px 10px;
        border-collapse: separate;
        table-layout: fixed;
        font-weight: ${theme.fonts.weights.bold};
    `;

    const tbodyStyle = css`
        width: 100%;
        border-spacing: 5px 10px;
        border-collapse: separate;
        table-layout: fixed;
    `;

    const tdStyle = (width: number = 25, isRightAligned = false) => css`
        width: ${width}%;
        white-space: break-spaces;
        text-align: ${isRightAligned ? 'right' : 'left'};
    `;

    const crossedOutPriceStyle = css`
        text-decoration: line-through;
    `;

    const discountedPriceStyle = css`
        margin-left: 10px;
        color: green;
    `;

    const rightAlignedRowStyle = css`
        text-align: right;
    `;

    return (
        <table css={orderSummaryTableStyle}>
            <thead css={theadStyle}>
            <tr>
                <th css={tdStyle(55)}>Item</th>
                <th css={tdStyle(20)}>Quantity</th>
                <th css={tdStyle(25, true)}>Total ({taxPrefix}&nbsp;{getTaxLabel(countryCode)})</th>
            </tr>
            </thead>
            <tbody css={tbodyStyle}>
            {products.map((product: OrderListItem, index: number) => {
                const totalProductAmount = improveDecimals(product.totalAmount);
                const totalDiscountedProductAmount = improveDecimals(product.totalAmount - product.discountedPrice);
                return (
                    <tr key={`${product.name}-${index}`}>
                        <td>{product.name}</td>
                        <td>{formatAndAddDecimals(product.quantity)}</td>
                        <td css={rightAlignedRowStyle}>
                            <span {...product.discountedPrice && { css: crossedOutPriceStyle }}>
                                {getCurrencyByCountry(countryCode)}{formatAndAddDecimals(totalProductAmount, decimalsNum)}
                            </span>
                            {!!product.discountedPrice && (
                                <span css={discountedPriceStyle}>
                                    {getCurrencyByCountry(countryCode)}{formatAndAddDecimals(totalDiscountedProductAmount, decimalsNum)}
                                </span>
                            )}
                        </td>
                    </tr>
                )
            })}
            {hasFeatureFlag && (
                <OrderTotalSummary
                    subtotal={formatAndAddDecimals(subtotal, decimalsNum)}
                    taxAmount={formatAndAddDecimals(taxAmount, decimalsNum)}
                    totalAmount={formatAndAddDecimals(totalAmount, decimalsNum)}
                    shipping={formatAndAddDecimals(shipping, decimalsNum)}
                    countryCode={countryCode}
                    isShippingTaxIncluded={isShippingTaxIncluded}
                />
            )}
            </tbody>
        </table>
    );
};

export default OrderSummaryTable;
