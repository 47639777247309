import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import LinkTo from "core/Components/LinkTo";
import { NOOP } from "core/constants";
import useBreakpoint from "core/hooks/useBreakpoint";
import { isDown } from "theme/breakpoints";

type Props = {
    to?: string,
    quantity: number,
    quantityBoxWidth: number,
    onClick?: (index?: number) => void,
};

const QuantityWithLink: React.FC<Props> = (
    {
        to,
        quantity,
        quantityBoxWidth,
        onClick = NOOP,
    }
) => {
    const theme: Theme = useTheme();
    const { breakpoint } = useBreakpoint();

    const style = css`
        display: flex;
        justify-content: center;
        gap: 8px;
        width: ${quantityBoxWidth}px;

        > span {
            color: ${theme.colours.grey[650]};
        }

        > a {
            color: ${theme.colours.curiousBlue2};
            text-decoration: underline;
        }

        ${theme.breakpoints.only('sm')} {
            flex-direction: column;
        }

        ${theme.breakpoints.down('xs')} {
            margin-left: 8px;
            font-size: 16px;
            line-height: 20px;
            justify-content: flex-start;
        }
    `;

    const editStyle = css`
        text-decoration: underline;
        color: ${theme.colours.curiousBlue2};
        cursor: pointer;

        ${theme.breakpoints.down('xs')} {
            font-size: 10px;
        }
    `;

    return (
        <div css={style}>
            {isDown(breakpoint, 'xs') && (
                <span>Qty</span>
            )}
            <span>{quantity}</span>
            <LinkTo
                to={to}
                css={editStyle}
                onClick={onClick}
            >
                Edit
            </LinkTo>
        </div>
    );
}

export default QuantityWithLink;