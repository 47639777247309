import React from "react";
import { useTheme } from "@emotion/react";
import {
    HiPlus as PlusIcon,
    HiMinus as MinusIcon,
} from "react-icons/hi";
import Input from "core/Components/Buttons/QuantityButton/components/Input";
import CircleButton from "core/Components/Buttons/CircleButton";
import QuantityContainer from "core/Components/Buttons/QuantityButton/components/QuantityContainer";
import RoundQuantityInput from "core/Components/Buttons/QuantityButton/RoundQuantityInput";
import { MAXIMUM_PRODUCT_QUANTITY } from "pages/Components/ProductListPage/includes/includes";

type Props = {
    quantity: string | number,
    plusClickHandler: (e: React.MouseEvent) => void,
    minusClickHandler: (e: React.MouseEvent) => void,
    onChange: (quantity: React.ChangeEvent<HTMLInputElement>) => void,
    hasFeatureFlag?: boolean,
    maxQuantity?: number,
    minQuantity?: number,
    buttonSize?: number,
    onAddToCart?: () => void,
};

const BUTTON_SIZE = 32;
const BUTTON_ICON_SIZE = 20;
const MIN_QUANTITY = 0;

const RoundQuantityButton: React.FC<Props> = ({
    quantity,
    plusClickHandler,
    minusClickHandler,
    onChange,
    hasFeatureFlag = false,
    maxQuantity = MAXIMUM_PRODUCT_QUANTITY,
    minQuantity = MIN_QUANTITY,
    buttonSize = BUTTON_SIZE,
    onAddToCart,
}) => {
    const theme = useTheme();

    const minLimit = quantity > minQuantity;
    const maxLimit = quantity < maxQuantity;

    return (
        <QuantityContainer>
            <CircleButton
                size={buttonSize}
                svgSize={BUTTON_ICON_SIZE}
                onClick={minusClickHandler}
                roundColour={theme.colours.grey[minLimit ? 200 : 90]}
                svgColour={minLimit ? theme.colours.doveGray : theme.colours.alto2}
                noHoverEffect={!minLimit}
                disabled={!minLimit}
                {...minLimit && {
                    roundHoverColour: theme.colours.malibu,
                    svgHoverColour: theme.colours.white,
                }}
            >
                <MinusIcon />
            </CircleButton>
            {hasFeatureFlag && (
                <RoundQuantityInput
                    quantity={quantity}
                    textColour={theme.colours.doveGray}
                    title={`${MIN_QUANTITY} ~ ${maxQuantity}`}
                    circleButtonWidth={buttonSize}
                    onChange={onChange}
                    onResetValue={onAddToCart}
                />
            )}
            <Input
                onChange={onChange}
                quantity={quantity}
                textColour={theme.colours.doveGray}
            />
            <CircleButton
                size={buttonSize}
                svgSize={BUTTON_ICON_SIZE}
                onClick={plusClickHandler}
                roundColour={theme.colours.grey[maxLimit ? 200 : 90]}
                svgColour={maxLimit ? theme.colours.doveGray : theme.colours.alto2}
                noHoverEffect={!maxLimit}
                disabled={!maxLimit}
                {...maxLimit && {
                    roundHoverColour: theme.colours.malibu,
                    svgHoverColour: theme.colours.white,
                }}
            >
                <PlusIcon />
            </CircleButton>
        </QuantityContainer>
    );
}

export default RoundQuantityButton;