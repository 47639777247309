import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import { useAppState } from "core/contexts/AppContext";
import { getPrice } from "core/includes/formatters";
import { taxLabelWithTaxable } from "core/includes/finance";

type Props = {
    quantity: number,
    price: number,
    isTaxable: boolean,
};

const CartItemQuantityAndPrice: React.FC<Props> = ({ quantity, price, isTaxable }) => {
    const [appState] = useAppState();
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 6px;
        height: 100%;
        max-height: 67px;
        white-space: nowrap;
        text-align: right;
        color: ${theme.colours.shipGray};

        ${theme.breakpoints.between("md", "xl")} {
            margin-left: 5px;
        }

        ${theme.breakpoints.between("sm", "lg")} {
            margin-left: 3px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-left: 2px;
        }
    `;

    const quantityStyle = css`
        font-weight: ${theme.fonts.weights.bold};
    `;

    const gstStyle = css`
        font-size: 8px;
        line-height: 5px;
    `;

    return (
        <div css={style}>
            <div css={quantityStyle}>(Qty : {quantity})</div>
            <div>
                <p>{appState.ipCountry.currency}{getPrice(quantity * price)}</p>
                <p css={gstStyle}>({taxLabelWithTaxable(isTaxable, appState.ipCountry.taxLabel)})</p>
            </div>
        </div>
    );
};

export default CartItemQuantityAndPrice;