import React from "react";

type Props = {
    className?: string,
};

const CartWithCircles: React.FC<Props> = (
    {
        className = "",
    }
) => (
    <div className={className}>
        <svg viewBox="0 0 255 187">
            <g transform="translate(0.386 -0.265)">
                <ellipse cx="41.5" cy="40" rx="41.5" ry="40" transform="translate(-0.386 0.265)" fill="#ffd409"/>
                <ellipse cx="77" cy="77.5" rx="77" ry="77.5" transform="translate(100.614 32.265)" fill="#ffa1ce"/>
                <path
                    d="M90.777,78.029H17.2c-2.969,0-4.536-1.181-5.231-4.126Q6.676,51.507,1.437,29.1a56.671,56.671,0,0,1-1.4-6.661c-.3-2.772,1.5-4.647,4.3-4.852.584-.043,1.172-.015,1.759-.015H105.8c1.092-4.374,2.2-8.7,3.251-13.049.812-3.35,2.21-4.509,5.64-4.512q9.5-.008,19,0c2.55,0,4.432,1.222,4.941,3.136a4.292,4.292,0,0,1-4.307,5.592c-5.393.047-10.788.052-16.182-.017a1.6,1.6,0,0,0-1.9,1.449q-6.184,25.15-12.433,50.284-4.44,17.92-8.9,35.836c-.765,3.053-1.983,3.926-5.091,3.928H86.913c2.815,2.119,4.361,4.583,4.136,7.787a7.968,7.968,0,0,1-2.88,5.639,8.086,8.086,0,0,1-11.227-.66c-3.461-3.672-2.941-8.044,1.585-12.661H34.378c4.457,4.373,4.971,9.227,1.453,12.822a8.156,8.156,0,0,1-11.639,0c-3.55-3.614-3.024-8.369,1.638-12.925-2.59,0-4.954.011-7.318,0-2.676-.015-4.327-1.638-4.259-4.253a4.057,4.057,0,0,1,4.315-4.216c22.279.084,44.559.034,66.838.078,1.442,0,2.177-.3,2.505-1.837.837-3.931,1.862-7.822,2.867-11.957M86.1,26.041,84,43.211H99.455c1.417-5.733,2.819-11.407,4.243-17.17Zm-75.979.017c1.327,5.6,2.591,10.991,3.9,16.366.083.337.552.825.847.828,4.617.053,9.235.037,14.015.037-.657-5.845-1.292-11.5-1.936-17.231Zm25.547.005c.645,5.77,1.277,11.433,1.913,17.12H52.105V26.063ZM61,26.073V43.157H75.27c.713-5.745,1.409-11.357,2.119-17.084ZM82.878,52.114l-2.108,17.2c3.959,0,7.641.036,11.319-.052.4-.009.993-.682,1.124-1.151.861-3.089,1.635-6.2,2.408-9.317.543-2.188,1.038-4.389,1.577-6.678ZM40.516,69.192H52.242V52.141H38.6c.647,5.753,1.279,11.374,1.917,17.051m-8.7.122c-.667-5.906-1.306-11.557-1.943-17.194h-13.6c1.283,5.5,2.521,10.893,3.839,16.266a1.58,1.58,0,0,0,1.188.887c3.388.072,6.777.041,10.515.041m40.22-.064c.718-5.777,1.416-11.391,2.127-17.112H61.049V69.25Z"
                    transform="translate(41.839 16.982)" fill="#1d88db"
                />
            </g>
        </svg>
    </div>
);

export default CartWithCircles;