import React, { useMemo } from "react";

import { useAppState } from "core/contexts/AppContext";
import useHeaderHeight from "core/hooks/useHeaderHeight";
import ChevronContainer from "pages/Components/CartAndCheckout/components/ChevronContainer";
import ConfirmationPopup from "pages/Components/CartAndCheckout/components/ConfirmationPopup";
import EmptyCart from "pages/Components/CartAndCheckout/components/EmptyCart";
import HeaderContainer from "pages/Components/CartAndCheckout/components/HeaderContainer";
import HeaderItemCount from "pages/Components/CartAndCheckout/components/HeaderItemCount";
import HeaderTitle from "pages/Components/CartAndCheckout/components/HeaderTitle";
import MainContainer from "pages/Components/CartAndCheckout/components/MainContainer";
import PageSection from "pages/Components/CartAndCheckout/components/PageSection";
import PageLeftSection from "pages/Components/CartAndCheckout/components/PageLeftSection";
import PageRightSection from "pages/Components/CartAndCheckout/components/PageRightSection";
import ItemGroupContainer from "pages/Components/CartAndCheckout/components/Item/ItemGroupContainer";
import ItemGroupSection from "pages/Components/CartAndCheckout/components/Item/ItemGroupSection";
import useCartDetailSizes from "pages/Components/CartAndCheckout/hooks/useCartDetailSizes";
import CartSummaryContainer from "pages/Components/CartAndCheckout/components/CartSummaryContainer";
import CheckoutButton from "pages/Components/CartAndCheckout/components/CheckoutButton";
import { isEmpty } from "lodash";

const DEFAULT_PAGE_TITLE = 'Shopping Cart';

type Props = {
    showBreadcrumb: boolean,
    hideHeaderCountry: boolean,
};

const CartPage: React.FC<Props> = ({ showBreadcrumb, hideHeaderCountry }) => {
    const [appState, setAppState] = useAppState();
    const headerHeight = useHeaderHeight({ showBreadcrumb, hideHeaderCountry });

    const {
        chevronPaddingTop,
        paddingTop,
        paddingRight,
        itemGroupBoxWidth,
        itemBoxWidth,
        itemBoxImageWidth,
        itemBoxImageHeight,
        itemBoxDescriptionWidth,
        itemBoxDescriptionLeftRightMargin,
        itemBoxPriceWidth,
        itemBoxPriceRightMargin,
        quantityBoxContainerWidth,
        quantityBoxWidth,
        quantityBoxHeight,
        plusMinusCircleButtonSize,
        isSmallBreakpoint,
    } = useCartDetailSizes();

    /**
     * Error message for discount code error
     * @param discountCode
     * @param applyTarget
     * @param errorMessage
     */
    const setValidationError = (discountCode: string, applyTarget: string, errorMessage: string = '') => {
        setAppState((prev: any) => ({
            cart: {
                ...prev.cart,
                discountCodeApplyTarget: applyTarget,
                validateDiscountCode: {
                    triedDiscountCode: discountCode,
                    errorMessage: errorMessage || 'The code is not available.'
                }
            }
        }));
    };

    const cartItems = useMemo(() => (
        appState.cart?.products || []
    ), [appState]);

    const groupedItems = useMemo(() => {
        if (!cartItems.length) return {};

        const products = cartItems.filter((item: CartProduct) => item.type === "product");
        const events = cartItems.filter((item: CartProduct) => item.type === "event");

        return {
            event: events,
            product: products
        };
    }, [cartItems]);

    const cartStatus = useMemo(() => {
        if (isEmpty(groupedItems)) return "empty";
        if (groupedItems.event.length && groupedItems.product.length) return "both";
        if (groupedItems.event.length) return 'event';
        if (groupedItems.product.length) return 'product';
    }, [groupedItems]);

    return (
        <PageSection headerHeight={headerHeight}>
            <PageLeftSection isSmallBreakpoint={isSmallBreakpoint}>
                <ChevronContainer paddingTop={chevronPaddingTop} itemGroupBoxWidth={itemGroupBoxWidth} />
                <MainContainer paddingTop={paddingTop} paddingRight={paddingRight}
                               itemGroupBoxWidth={itemGroupBoxWidth}>
                    <HeaderContainer paddingLeftForSmallBreakpoint={35}>
                        <HeaderTitle>{DEFAULT_PAGE_TITLE}</HeaderTitle>
                        <HeaderItemCount itemCount={appState.cart?.products?.length || 0} />
                    </HeaderContainer>

                    {/* Cart doesn't have any items */}
                    {!cartItems.length && <EmptyCart />}

                    <ItemGroupSection cartStatus={cartStatus}>
                        {(!!cartItems.length && Object.keys(groupedItems)?.map(
                            (key: string, index: number) => (
                                <ItemGroupContainer
                                    key={`${key}-${index}`}
                                    type={key}
                                    items={Object.values(groupedItems)[index]}
                                    groupStatus={cartStatus}
                                    itemGroupBoxWidth={itemGroupBoxWidth}
                                    itemBoxImageHeight={itemBoxImageHeight}
                                    itemBoxWidth={itemBoxWidth}
                                    itemBoxImageWidth={itemBoxImageWidth}
                                    itemBoxDescriptionWidth={itemBoxDescriptionWidth}
                                    itemBoxDescriptionLeftRightMargin={itemBoxDescriptionLeftRightMargin}
                                    itemBoxPriceWidth={itemBoxPriceWidth}
                                    itemBoxPriceRightMargin={itemBoxPriceRightMargin}
                                    quantityBoxContainerWidth={quantityBoxContainerWidth}
                                    quantityBoxWidth={quantityBoxWidth}
                                    quantityBoxHeight={quantityBoxHeight}
                                    plusMinusCircleButtonSize={plusMinusCircleButtonSize}
                                    setValidationError={setValidationError}
                                    isSmallBreakpoint={isSmallBreakpoint}
                                />
                            )
                        ))}
                    </ItemGroupSection>
                </MainContainer>
            </PageLeftSection>
            <PageRightSection paddingTop={paddingTop}>
                <CartSummaryContainer
                    button={<CheckoutButton isClickable={cartItems.length > 0 ?? false} />}
                    cartStatus={cartStatus}
                />
            </PageRightSection>

            {/* Confirmation popup for 'Remove all' */}
            <ConfirmationPopup />
        </PageSection>
    );
};

export default CartPage;