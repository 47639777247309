import { gql } from "@apollo/client";

export const pageMenuQuery = gql`
    query pageMenu($stage: String, $canRenderWebp: Boolean!) {
        pageMenu(stage: $stage) {
            __typename
            ... on StandardMenu {
                segment
                standardPage {
                    id
                    parentID
                    titleForFooter
                    showInMenus
                    isClickable
                    showInFooter
                    linkUrl
                    sort
                    className
                    title
                    metaDescription
                    metaTitle
                    uRLSegment
                    canonical
                    robots
                    oGType
                    oGLocale
                    twitterCard
                    socialImageUrl
                    hideSocial
                    lastEdited
                    absoluteUrl
                    extraHeadTags {
                        id
                        title
                        value
                        type
                    }
                    landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                        id
                        URL
                        FocusPoint
                        ImageSize
                        AltText
                    }
                }
                menuButton {
                    buttonText
                    innerURL
                    outerURL
                    cartURL
                    trackingButtonName
                    trackingLoginButtonName
                }
                selectedCountry
                userCountry
                countries {
                    id
                    code
                    name
                    useFocusPoint
                    taxRate
                    currency
                    taxLabel
                    image: webpWithFallbackImage(webp: $canRenderWebp) {
                        id
                        URL
                        FocusPoint
                        ImageSize
                        AltText
                    }
                }
            }
        }
    }
`;

const pageQuery = gql`
    query page($urlSegment: String!, $stage: String, $canRenderWebp: Boolean!) {
        page(urlSegment: $urlSegment, stage: $stage) {
            __typename
            ... on StandardPage {
                id
                isClickable
                title
                metaDescription
                metaTitle
                uRLSegment
                canonical
                robots
                oGType
                oGLocale
                twitterCard
                socialImageUrl
                hideSocial
                lastEdited
                absoluteUrl
                extraHeadTags {
                    id
                    title
                    value
                    type
                }
                elements {
                    __typename
                    ... on BannerWithFullImage {
                        id
                        header
                        buttonText
                        innerURL
                        outerURL
                        theme
                        secondButtonText
                        secondInnerURL
                        secondOuterURL
                        videoButtonText
                        videoPath
                        useFocusPoint
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on BannerWithImage {
                        id
                        header
                        subheader
                        text
                        theme
                        textAlignType
                        isImageFirst
                        buttonText
                        innerURL
                        outerURL
                        useButtonLine
                        buttonLineColour
                        trackingButtonName
                        secondButtonText
                        secondInnerURL
                        secondOuterURL
                        useSecondButtonLine
                        secondButtonLineColour
                        trackingSecondButtonName
                        videoButtonText
                        videoPath
                        useFocusPoint
                        useFocusPointPortrait
                        opacity
                        gradientColor
                        landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on CollapseList {
                        id
                        listType
                        autoNumbering
                        header
                        text
                        collapseItems {
                            id
                            header
                            text
                            sortOrder
                        }
                    }
                    ... on ContactUs {
                        id
                        header
                        text
                        hubspotFormId
                        useFocusPoint
                        addresses {
                            city
                            address
                            phoneNumber
                            sortOrder
                        }
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on ContentWithImageHorizontalCards {
                        id
                        imageCardList {
                            id
                            header
                            text
                            theme
                            isImageFirst
                            innerURL
                            outerURL
                            buttonText
                            sortOrder
                            useFocusPoint
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                        }
                    }
                    ... on ContentWithImage {
                        id
                        header
                        subheader
                        text
                        theme
                        layout
                        buttonText
                        videoButtonText
                        videoPath
                        videoDisplay
                        autoplay
                        innerURL
                        outerURL
                        useFocusPoint
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        video {
                            id
                            Filename
                            AbsoluteURL
                        }
                    }
                    ... on ContentWithImageVerticalCards {
                        id
                        isImageFirst
                        header
                        subheader
                        verticalCardList {
                            id
                            title
                            header
                            layoutType
                            theme
                            text
                            buttonText
                            trackingButtonName
                            innerURL
                            outerURL
                            videoPath
                            sortOrder
                            useFocusPoint
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                            contentList {
                                id
                                header
                                innerURL
                                outerURL
                                hubspotFormId
                                sortOrder
                            }
                        }
                    }
                    ... on ContentWithList {
                        id
                        header
                        theme
                        useFocusPoint
                        isImageFirst
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        contentList {
                            id
                            header
                            innerURL
                            outerURL
                            hubspotFormId
                            sortOrder
                        }
                    }
                    ...on ContentWithRoundImage {
                        id
                        header
                        subheader
                        text
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on DescriptionWithRichText {
                        id
                        header
                        subheader
                        theme
                        text
                    }
                    ... on DescriptionWithRichTextAndImage {
                        id
                        header
                        subheader
                        theme
                        text
                        useFocusPoint
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on DescriptionWithRichTextSingleColumn {
                        id
                        header
                        text
                    }
                    ... on DescriptionWithSimpleText {
                        id
                        showDoubleQuotes
                        headerTag
                        header
                        text
                        writer
                        moduleHeightType
                        theme
                        buttonText
                        innerURL
                        outerURL
                        useFocusPoint
                        altText
                        landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on DescriptionWithSimpleTextAndImage {
                        id
                        showDoubleQuotes
                        header
                        text
                        writer
                        theme
                        buttonText
                        innerURL
                        outerURL
                        videoPath
                        textBoxPosition
                        textBoxWidth
                        moduleHeight
                        useFocusPoint
                        useFocusPointPortrait
                        altText
                        landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on FullImage {
                        id
                        header
                        subheader
                        moduleHeightType
                        textPosition
                        theme
                        innerURL
                        outerURL
                        useFocusPoint
                        useFocusPointPortrait
                        altText
                        landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on SingleVideo {
                        id
                        header
                        subheader
                        theme
                        videoPath
                        useFocusPoint
                        trackingVideoName
                        videoThumbnail: webpWithFallbackVideoThumbnail(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on SliderWithFeedback {
                        id
                        moduleHeight
                        theme
                        feedbackList {
                            id
                            feedback
                            writer
                            sortOrder
                        }
                    }
                    ... on SliderWithImage {
                        id
                        header
                        showDoubleQuotes
                        writer
                        theme
                        descriptionText
                        innerURL
                        outerURL
                        buttonText
                        isImageSquare
                        isImageWithoutBoxShadow
                        imageList {
                            id
                            header
                            text
                            backgroundColour
                            innerURL
                            outerURL
                            sortOrder
                            useFocusPoint
                            expandedCardHeader
                            expandedCardSubHeader
                            expandedCardText
                            useFocusPointExpandedImageLandscape
                            useFocusPointExpandedImagePortrait
                            expandedAltText
                            expandedImageLandscape: webpWithFallbackExpandedImageLandscape(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                            expandedImagePortrait: webpWithFallbackExpandedImagePortrait(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                        }
                    }
                    ... on SliderWithQuote {
                        id
                        header
                        subheader
                        buttonText
                        innerURL
                        outerURL
                        trackingButtonName
                        secondButtonText
                        secondInnerURL
                        secondOuterURL
                        trackingSecondButtonName
                        disableCarousel
                        disableGrowToFit
                        quoteList {
                            id
                            text
                            author
                        }
                    }
                    ... on SliderWithVideo {
                        id
                        videoList {
                            id
                            header
                            videoPath
                            sortOrder
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                            }
                        }
                    }
                    ... on SliderWithVideoPopup {
                        id
                        header
                        subheader
                        theme
                        videoList {
                            id
                            text
                            videoPath
                            backgroundColour
                            sortOrder
                            useFocusPoint
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                        }
                    }
                    ... on SubscribeForUpdates {
                        id
                        header
                        subheader
                        buttonText
                        theme
                        pinnedCountryCodes
                        hubspotFormIdentifier
                    }
                    ... on Timeline {
                        id
                        header
                        text
                        isLargeItemTitle
                        removeBackgroundImage
                        timelineItemList {
                            id
                            title
                            useNumbering
                            milestoneYear
                            description
                            theme
                            isAvatarRotate
                            sortOrder
                            mainImage: webpWithFallbackMainImage(webp: $canRenderWebp) {
                                id
                                URL
                            }
                            avatarImage: webpWithFallbackAvatarImage(webp: $canRenderWebp) {
                                id
                                URL
                            }
                        }
                    }
                    ... on TopBanner {
                        id
                        buttonText
                        innerURL
                        outerURL
                        buttonTheme
                        secondButtonText
                        secondInnerURL
                        secondOuterURL
                        secondButtonTheme
                        imageList {
                            id
                            sortOrder
                            useFocusPoint
                            useFocusPointPortrait
                            altText
                            landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                            portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                        }
                    }
                    ... on SubscriptionPlan {
                        id
                        header
                        text
                        pricingCardList {
                            id
                            header
                            price
                            paymentType
                            text
                            theme
                            buttonText
                            innerURL
                            outerURL
                            sortOrder
                        }
                    }
                    ... on HubspotForm {
                        id
                        header
                        hubspotFormId
                    }
                    ... on SubscriptionList {
                        id
                        name
                        options {
                            id
                            title
                            description
                            tiers {
                                id
                                isIncluded
                                description
                                category {
                                    id
                                    name
                                    backgroundColour
                                }
                            }
                        }
                    }
                }
            }
            ... on BlogListPage {
                id
                isClickable
                hideHero
                showDoubleQuotes
                doubleQuotesColour
                simpleSubheader
                simpleWriter
                themeType
                theme
                contentHeader
                contentSubheader
                contentText
                title
                metaDescription
                metaTitle
                uRLSegment
                canonical
                robots
                oGType
                oGLocale
                twitterCard
                socialImageUrl
                hideSocial
                lastEdited
                absoluteUrl
                extraHeadTags {
                    id
                    title
                    value
                    type
                }
                contentImage(webp: $canRenderWebp) {
                    id
                    URL
                    FocusPoint
                    ImageSize
                    AltText
                }
            }
            ... on BlogPost {
                id
                isClickable
                subheader
                publishedDate
                lastEdited
                shortDescription
                content
                hideBanner
                useFocusPointBanner
                title
                metaDescription
                metaTitle
                uRLSegment
                canonical
                robots
                oGType
                oGLocale
                twitterCard
                socialImageUrl
                hideSocial
                absoluteUrl
                showButton
                buttonLabel
                buttonBackgroundColour
                buttonHoverBackgroundColour
                buttonTextColour
                buttonHubspotFormId
                buttonExternalUrl
                isNewTab
                banner: webpWithFallbackBanner(webp: $canRenderWebp) {
                    id
                    URL
                    FocusPoint
                    ImageSize
                    AltText
                }
                author {
                    id
                    name
                    webpWithFallbackPortrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                        id
                        URL
                        FocusPoint
                        ImageSize
                        AltText
                    }
                }
                relatedPosts {
                    id
                    title
                    shortDescription
                    uRLSegment
                    previewImage: webpWithFallbackPreviewImage(webp: $canRenderWebp) {
                        id
                        URL
                        FocusPoint
                        ImageSize
                        AltText
                    }
                }
                elements {
                    __typename
                    ... on SubscribeForUpdates {
                        id
                        header
                        subheader
                        buttonText
                        theme
                        pinnedCountryCodes
                        hubspotFormIdentifier
                    }
                    ... on HubspotForm {
                        id
                        header
                        hubspotFormId
                    }
                }
                extraHeadTags {
                    id
                    title
                    value
                    type
                }
            }
            ... on CareerPage {
                id
                isClickable
                department
                employmentType
                salary
                specificationHeader
                specification
                informationHeader
                information
                shortDescription
                description
                hubspotFormId
                theme
                textColour
                useFocusPoint
                title
                metaDescription
                metaTitle
                uRLSegment
                canonical
                robots
                oGType
                oGLocale
                twitterCard
                socialImageUrl
                hideSocial
                lastEdited
                absoluteUrl
                showRibbon
                cityCode {
                    id
                    name
                    countryCode {
                        id
                        name
                    }
                }
                extraHeadTags {
                    id
                    title
                    value
                    type
                }
                imagesAndVideos {
                    id
                    videoPath
                    image: webpWithFallbackImage(webp: $canRenderWebp)  {
                        id
                        URL
                        FocusPoint
                        ImageSize
                        AltText
                    }
                }
            }
            ... on CareerListPage {
                id
                isClickable
                altTitle
                bannerHeader
                bannerSubheader
                header
                subheader
                useFocusPoint
                useFocusPointPortrait
                fontColourForImageA
                fontColourForImageB
                altText
                title
                metaDescription
                metaTitle
                uRLSegment
                canonical
                robots
                oGType
                oGLocale
                twitterCard
                socialImageUrl
                hideSocial
                lastEdited
                absoluteUrl
                extraHeadTags {
                    id
                    title
                    value
                    type
                }
                landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                    id
                    URL
                    FocusPoint
                    ImageSize
                    AltText
                }
                portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                    id
                    URL
                    FocusPoint
                    ImageSize
                    AltText
                }
                imageA: webpWithFallbackImageA(webp: $canRenderWebp) {
                    id
                    URL
                    FocusPoint
                    ImageSize
                    AltText
                }
                imageB: webpWithFallbackImageB(webp: $canRenderWebp) {
                    id
                    URL
                    FocusPoint
                    ImageSize
                    AltText
                }
                elements {
                    __typename
                    ... on CareerListItem {
                        id
                    }
                    ... on BannerWithFullImage {
                        id
                        header
                        buttonText
                        innerURL
                        outerURL
                        theme
                        secondButtonText
                        secondInnerURL
                        secondOuterURL
                        videoButtonText
                        videoPath
                        useFocusPoint
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on BannerWithImage {
                        id
                        header
                        subheader
                        text
                        theme
                        textAlignType
                        isImageFirst
                        buttonText
                        innerURL
                        outerURL
                        useButtonLine
                        buttonLineColour
                        trackingButtonName
                        secondButtonText
                        secondInnerURL
                        secondOuterURL
                        useSecondButtonLine
                        secondButtonLineColour
                        trackingSecondButtonName
                        videoButtonText
                        videoPath
                        useFocusPoint
                        useFocusPointPortrait
                        opacity
                        gradientColor
                        landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on CollapseList {
                        id
                        listType
                        autoNumbering
                        header
                        text
                        collapseItems {
                            id
                            header
                            text
                            sortOrder
                        }
                    }
                    ... on ContactUs {
                        id
                        header
                        text
                        hubspotFormId
                        useFocusPoint
                        addresses {
                            city
                            address
                            phoneNumber
                            sortOrder
                        }
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on ContentWithImageHorizontalCards {
                        id
                        imageCardList {
                            id
                            header
                            text
                            theme
                            isImageFirst
                            innerURL
                            outerURL
                            buttonText
                            sortOrder
                            useFocusPoint
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                        }
                    }
                    ... on ContentWithImage {
                        id
                        header
                        subheader
                        text
                        theme
                        layout
                        buttonText
                        videoButtonText
                        videoPath
                        videoDisplay
                        autoplay
                        innerURL
                        outerURL
                        useFocusPoint
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        video {
                            id
                            Filename
                            AbsoluteURL
                        }
                    }
                    ... on ContentWithImageVerticalCards {
                        id
                        isImageFirst
                        header
                        subheader
                        verticalCardList {
                            id
                            title
                            header
                            layoutType
                            theme
                            text
                            buttonText
                            trackingButtonName
                            innerURL
                            outerURL
                            videoPath
                            sortOrder
                            useFocusPoint
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                            contentList {
                                id
                                header
                                innerURL
                                outerURL
                                hubspotFormId
                                sortOrder
                            }
                        }
                    }
                    ... on ContentWithList {
                        id
                        header
                        theme
                        useFocusPoint
                        isImageFirst
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        contentList {
                            id
                            header
                            innerURL
                            outerURL
                            hubspotFormId
                            sortOrder
                        }
                    }
                    ...on ContentWithRoundImage {
                        id
                        header
                        subheader
                        text
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on DescriptionWithRichText {
                        id
                        header
                        subheader
                        theme
                        text
                    }
                    ... on DescriptionWithRichTextAndImage {
                        id
                        header
                        subheader
                        theme
                        text
                        useFocusPoint
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on DescriptionWithRichTextSingleColumn {
                        id
                        header
                        text
                    }
                    ... on DescriptionWithSimpleText {
                        id
                        showDoubleQuotes
                        headerTag
                        header
                        text
                        writer
                        moduleHeightType
                        theme
                        buttonText
                        innerURL
                        outerURL
                        useFocusPoint
                        altText
                        landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on DescriptionWithSimpleTextAndImage {
                        id
                        showDoubleQuotes
                        header
                        text
                        writer
                        theme
                        buttonText
                        innerURL
                        outerURL
                        videoPath
                        textBoxPosition
                        textBoxWidth
                        moduleHeight
                        useFocusPoint
                        useFocusPointPortrait
                        altText
                        landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on FullImage {
                        id
                        header
                        subheader
                        moduleHeightType
                        textPosition
                        theme
                        innerURL
                        outerURL
                        useFocusPoint
                        useFocusPointPortrait
                        altText
                        landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on SingleVideo {
                        id
                        header
                        subheader
                        theme
                        videoPath
                        useFocusPoint
                        trackingVideoName
                        videoThumbnail: webpWithFallbackVideoThumbnail(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on SliderWithFeedback {
                        id
                        moduleHeight
                        theme
                        feedbackList {
                            id
                            feedback
                            writer
                            sortOrder
                        }
                    }
                    ... on SliderWithImage {
                        id
                        header
                        showDoubleQuotes
                        writer
                        theme
                        descriptionText
                        innerURL
                        outerURL
                        buttonText
                        isImageSquare
                        isImageWithoutBoxShadow
                        imageList {
                            id
                            header
                            text
                            backgroundColour
                            innerURL
                            outerURL
                            sortOrder
                            useFocusPoint
                            expandedCardHeader
                            expandedCardSubHeader
                            expandedCardText
                            useFocusPointExpandedImageLandscape
                            useFocusPointExpandedImagePortrait
                            expandedAltText
                            expandedImageLandscape: webpWithFallbackExpandedImageLandscape(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                            expandedImagePortrait: webpWithFallbackExpandedImagePortrait(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                        }
                    }
                    ... on SliderWithQuote {
                        id
                        header
                        subheader
                        buttonText
                        innerURL
                        outerURL
                        trackingButtonName
                        secondButtonText
                        secondInnerURL
                        secondOuterURL
                        trackingSecondButtonName
                        disableCarousel
                        disableGrowToFit
                        quoteList {
                            id
                            text
                            author
                        }
                    }
                    ... on SliderWithVideo {
                        id
                        videoList {
                            id
                            header
                            videoPath
                            sortOrder
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                            }
                        }
                    }
                    ... on SliderWithVideoPopup {
                        id
                        header
                        subheader
                        theme
                        videoList {
                            id
                            text
                            videoPath
                            backgroundColour
                            sortOrder
                            useFocusPoint
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                        }
                    }
                    ... on Timeline {
                        id
                        header
                        text
                        isLargeItemTitle
                        removeBackgroundImage
                        timelineItemList {
                            id
                            title
                            useNumbering
                            milestoneYear
                            description
                            theme
                            isAvatarRotate
                            sortOrder
                            mainImage: webpWithFallbackMainImage(webp: $canRenderWebp) {
                                id
                                URL
                            }
                            avatarImage: webpWithFallbackAvatarImage(webp: $canRenderWebp) {
                                id
                                URL
                            }
                        }
                    }
                    ... on SubscribeForUpdates {
                        id
                        header
                        subheader
                        buttonText
                        theme
                        pinnedCountryCodes
                        hubspotFormIdentifier
                    }
                    ... on TopBanner {
                        id
                        buttonText
                        innerURL
                        outerURL
                        buttonTheme
                        secondButtonText
                        secondInnerURL
                        secondOuterURL
                        secondButtonTheme
                        imageList {
                            id
                            sortOrder
                            useFocusPoint
                            useFocusPointPortrait
                            altText
                            landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                            portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                        }
                    }
                    ... on SubscriptionPlan {
                        id
                        header
                        text
                        pricingCardList {
                            id
                            header
                            price
                            paymentType
                            text
                            theme
                            buttonText
                            innerURL
                            outerURL
                            sortOrder
                        }
                    }
                    ... on HubspotForm {
                        id
                        header
                        hubspotFormId
                    }
                    ... on SubscriptionList {
                        id
                        name
                        options {
                            id
                            title
                            description
                            tiers {
                                id
                                isIncluded
                                description
                                category {
                                    id
                                    name
                                    backgroundColour
                                }
                            }
                        }
                    }
                }
            }
            ... on EventPage {
                id
                isClickable
                specificationHeader
                specification
                description
                quote
                videoButtonText
                videoPath
                isShowingCart
                isShowingReviews
                information
                hubspotFormId
                eventDate
                eventEndDate
                location
                textColour
                theme
                termsAndConditions
                altText
                title
                metaDescription
                metaTitle
                uRLSegment
                canonical
                robots
                oGType
                oGLocale
                twitterCard
                socialImageUrl
                hideSocial
                lastEdited
                absoluteUrl
                eventStartTime
                eventEndTime
                writer
                showRibbon
                trackingRegisterButtonName
                useCommonTime
                bannerLocationLabel
                eventSubSchedules {
                    startDate
                    endDate
                    startTime
                    endTime
                    sortOrder
                }
                googleScriptTags {
                    id
                    tag
                    isOnClick
                }
                extraHeadTags {
                    id
                    title
                    value
                    type
                }
                imagesAndVideos {
                    id
                    videoPath
                    image: webpWithFallbackImage(webp: $canRenderWebp) {
                        id
                        URL
                        FocusPoint
                        ImageSize
                        AltText
                    }
                }
                price {
                    id
                    amount
                    label
                    subLabel
                }
                cityCode {
                    id
                    name
                }
                countryCode {
                    id
                    name
                }
            }
            ... on EventListPage {
                id
                isClickable
                header
                subheader
                showTextOnBanner
                showButtonOnBanner
                buttonText
                useFocusPoint
                useFocusPointPortrait
                altText
                showDropdownSuitability
                showDropdownGroup
                showDropdownCountry
                showCountryOnRibbon
                showDropdownCity
                showDropdownRegion
                showDropdownMonth
                isCategorisedByTerms
                title
                metaDescription
                metaTitle
                uRLSegment
                canonical
                robots
                oGType
                oGLocale
                twitterCard
                socialImageUrl
                hideSocial
                lastEdited
                absoluteUrl
                extraHeadTags {
                    id
                    title
                    value
                    type
                }
                landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                    id
                    URL
                    FocusPoint
                    ImageSize
                    AltText
                }
                portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                    id
                    URL
                    FocusPoint
                    ImageSize
                    AltText
                }
                elements {
                    __typename
                    ... on EventListItem {
                        id
                    }
                    ... on BannerWithFullImage {
                        id
                        header
                        buttonText
                        innerURL
                        outerURL
                        theme
                        secondButtonText
                        secondInnerURL
                        secondOuterURL
                        videoButtonText
                        videoPath
                        useFocusPoint
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on BannerWithImage {
                        id
                        header
                        subheader
                        text
                        theme
                        textAlignType
                        isImageFirst
                        buttonText
                        innerURL
                        outerURL
                        useButtonLine
                        buttonLineColour
                        trackingButtonName
                        secondButtonText
                        secondInnerURL
                        secondOuterURL
                        useSecondButtonLine
                        secondButtonLineColour
                        trackingSecondButtonName
                        videoButtonText
                        videoPath
                        useFocusPoint
                        useFocusPointPortrait
                        opacity
                        gradientColor
                        landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on CollapseList {
                        id
                        listType
                        autoNumbering
                        header
                        text
                        collapseItems {
                            id
                            header
                            text
                            sortOrder
                        }
                    }
                    ... on ContactUs {
                        id
                        header
                        text
                        hubspotFormId
                        useFocusPoint
                        addresses {
                            city
                            address
                            phoneNumber
                            sortOrder
                        }
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on ContentWithImageHorizontalCards {
                        id
                        imageCardList {
                            id
                            header
                            text
                            theme
                            isImageFirst
                            innerURL
                            outerURL
                            buttonText
                            sortOrder
                            useFocusPoint
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                        }
                    }
                    ... on ContentWithImage {
                        id
                        header
                        subheader
                        text
                        theme
                        layout
                        buttonText
                        videoButtonText
                        videoPath
                        videoDisplay
                        autoplay
                        innerURL
                        outerURL
                        useFocusPoint
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        video {
                            id
                            Filename
                            AbsoluteURL
                        }
                    }
                    ... on ContentWithImageVerticalCards {
                        id
                        isImageFirst
                        header
                        subheader
                        verticalCardList {
                            id
                            title
                            header
                            layoutType
                            theme
                            text
                            buttonText
                            trackingButtonName
                            innerURL
                            outerURL
                            videoPath
                            sortOrder
                            useFocusPoint
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                            contentList {
                                id
                                header
                                innerURL
                                outerURL
                                hubspotFormId
                                sortOrder
                            }
                        }
                    }
                    ... on ContentWithList {
                        id
                        header
                        theme
                        useFocusPoint
                        isImageFirst
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        contentList {
                            id
                            header
                            innerURL
                            outerURL
                            hubspotFormId
                            sortOrder
                        }
                    }
                    ...on ContentWithRoundImage {
                        id
                        header
                        subheader
                        text
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on DescriptionWithRichText {
                        id
                        header
                        subheader
                        theme
                        text
                    }
                    ... on DescriptionWithRichTextAndImage {
                        id
                        header
                        subheader
                        theme
                        text
                        useFocusPoint
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on DescriptionWithRichTextSingleColumn {
                        id
                        header
                        text
                    }
                    ... on DescriptionWithSimpleText {
                        id
                        showDoubleQuotes
                        headerTag
                        header
                        text
                        writer
                        moduleHeightType
                        theme
                        buttonText
                        innerURL
                        outerURL
                        useFocusPoint
                        altText
                        landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on DescriptionWithSimpleTextAndImage {
                        id
                        showDoubleQuotes
                        header
                        text
                        writer
                        theme
                        buttonText
                        innerURL
                        outerURL
                        videoPath
                        textBoxPosition
                        textBoxWidth
                        moduleHeight
                        useFocusPoint
                        useFocusPointPortrait
                        altText
                        landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on FullImage {
                        id
                        header
                        subheader
                        moduleHeightType
                        textPosition
                        theme
                        innerURL
                        outerURL
                        useFocusPoint
                        useFocusPointPortrait
                        altText
                        landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on SingleVideo {
                        id
                        header
                        subheader
                        theme
                        videoPath
                        useFocusPoint
                        trackingVideoName
                        videoThumbnail: webpWithFallbackVideoThumbnail(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on SliderWithFeedback {
                        id
                        moduleHeight
                        theme
                        feedbackList {
                            id
                            feedback
                            writer
                            sortOrder
                        }
                    }
                    ... on SliderWithImage {
                        id
                        header
                        showDoubleQuotes
                        writer
                        theme
                        descriptionText
                        innerURL
                        outerURL
                        buttonText
                        isImageSquare
                        isImageWithoutBoxShadow
                        imageList {
                            id
                            header
                            text
                            backgroundColour
                            innerURL
                            outerURL
                            sortOrder
                            useFocusPoint
                            expandedCardHeader
                            expandedCardSubHeader
                            expandedCardText
                            useFocusPointExpandedImageLandscape
                            useFocusPointExpandedImagePortrait
                            expandedAltText
                            expandedImageLandscape: webpWithFallbackExpandedImageLandscape(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                            expandedImagePortrait: webpWithFallbackExpandedImagePortrait(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                        }
                    }
                    ... on SliderWithQuote {
                        id
                        header
                        subheader
                        buttonText
                        innerURL
                        outerURL
                        trackingButtonName
                        secondButtonText
                        secondInnerURL
                        secondOuterURL
                        trackingSecondButtonName
                        disableCarousel
                        disableGrowToFit
                        quoteList {
                            id
                            text
                            author
                        }
                    }
                    ... on SliderWithVideo {
                        id
                        videoList {
                            id
                            header
                            videoPath
                            sortOrder
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                            }
                        }
                    }
                    ... on SliderWithVideoPopup {
                        id
                        header
                        subheader
                        theme
                        videoList {
                            id
                            text
                            videoPath
                            backgroundColour
                            sortOrder
                            useFocusPoint
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                        }
                    }
                    ... on Timeline {
                        id
                        header
                        text
                        isLargeItemTitle
                        removeBackgroundImage
                        timelineItemList {
                            id
                            title
                            useNumbering
                            milestoneYear
                            description
                            theme
                            isAvatarRotate
                            sortOrder
                            mainImage: webpWithFallbackMainImage(webp: $canRenderWebp) {
                                id
                                URL
                            }
                            avatarImage: webpWithFallbackAvatarImage(webp: $canRenderWebp) {
                                id
                                URL
                            }
                        }
                    }
                    ... on SubscribeForUpdates {
                        id
                        header
                        subheader
                        buttonText
                        theme
                        pinnedCountryCodes
                        hubspotFormIdentifier
                    }
                    ... on TopBanner {
                        id
                        buttonText
                        innerURL
                        outerURL
                        buttonTheme
                        secondButtonText
                        secondInnerURL
                        secondOuterURL
                        secondButtonTheme
                        imageList {
                            id
                            sortOrder
                            useFocusPoint
                            useFocusPointPortrait
                            altText
                            landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                            portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                        }
                    }
                    ... on SubscriptionPlan {
                        id
                        header
                        text
                        pricingCardList {
                            id
                            header
                            price
                            paymentType
                            text
                            theme
                            buttonText
                            innerURL
                            outerURL
                            sortOrder
                        }
                    }
                    ... on HubspotForm {
                        id
                        header
                        hubspotFormId
                    }
                    ... on SubscriptionList {
                        id
                        name
                        options {
                            id
                            title
                            description
                            tiers {
                                id
                                isIncluded
                                description
                                category {
                                    id
                                    name
                                    backgroundColour
                                }
                            }
                        }
                    }
                }
            }
            ... on ProductPage {
                id
                isClickable
                specification
                description
                quote
                videoPath
                isShowingCart
                isShowingReviews
                groupName
                title
                metaDescription
                metaTitle
                uRLSegment
                canonical
                robots
                oGType
                oGLocale
                twitterCard
                socialImageUrl
                hideSocial
                lastEdited
                absoluteUrl
                extraHeadTags {
                    id
                    title
                    value
                    type
                }
                imagesAndVideos {
                    id
                    sortOrder
                    FocusPoint
                    videoPath
                    image: webpWithFallbackImage(webp: $canRenderWebp)  {
                        id
                        URL
                        FocusPoint
                        ImageSize
                        AltText
                    }
                }
                price {
                    id
                    amount
                }
            }
            ... on ProductListPage {
                id
                isTaxIncluded
                isClickable
                useFocusPoint
                useFocusPointPortrait
                discountStartDate
                discountEndDate
                altText
                description
                title
                metaDescription
                metaTitle
                uRLSegment
                canonical
                robots
                oGType
                oGLocale
                twitterCard
                socialImageUrl
                hideSocial
                lastEdited
                absoluteUrl
                orderFormButtonLabel
                extraHeadTags {
                    id
                    title
                    value
                    type
                }
                landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                    id
                    URL
                    FocusPoint
                    ImageSize
                    AltText
                }
                portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                    id
                    URL
                    FocusPoint
                    ImageSize
                    AltText
                }
                elements {
                    __typename
                    ... on ProductListItem {
                        id
                    }
                    ... on BannerWithFullImage {
                        id
                        header
                        buttonText
                        innerURL
                        outerURL
                        theme
                        secondButtonText
                        secondInnerURL
                        secondOuterURL
                        videoButtonText
                        videoPath
                        useFocusPoint
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        portraitImage: webpWithFallbackPortraitImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on BannerWithImage {
                        id
                        header
                        subheader
                        text
                        theme
                        textAlignType
                        isImageFirst
                        buttonText
                        innerURL
                        outerURL
                        useButtonLine
                        buttonLineColour
                        trackingButtonName
                        secondButtonText
                        secondInnerURL
                        secondOuterURL
                        useSecondButtonLine
                        secondButtonLineColour
                        trackingSecondButtonName
                        videoButtonText
                        videoPath
                        useFocusPoint
                        useFocusPointPortrait
                        opacity
                        gradientColor
                        landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on CollapseList {
                        id
                        listType
                        autoNumbering
                        header
                        text
                        collapseItems {
                            id
                            header
                            text
                            sortOrder
                        }
                    }
                    ... on ContactUs {
                        id
                        header
                        text
                        hubspotFormId
                        useFocusPoint
                        addresses {
                            city
                            address
                            phoneNumber
                            sortOrder
                        }
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on ContentWithImageHorizontalCards {
                        id
                        imageCardList {
                            id
                            header
                            text
                            theme
                            isImageFirst
                            innerURL
                            outerURL
                            buttonText
                            sortOrder
                            useFocusPoint
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                        }
                    }
                    ... on ContentWithImage {
                        id
                        header
                        subheader
                        text
                        theme
                        layout
                        buttonText
                        videoButtonText
                        videoPath
                        videoDisplay
                        autoplay
                        innerURL
                        outerURL
                        useFocusPoint
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        video {
                            id
                            Filename
                            AbsoluteURL
                        }
                    }
                    ... on ContentWithImageVerticalCards {
                        id
                        isImageFirst
                        header
                        subheader
                        verticalCardList {
                            id
                            title
                            header
                            layoutType
                            theme
                            text
                            buttonText
                            trackingButtonName
                            innerURL
                            outerURL
                            videoPath
                            sortOrder
                            useFocusPoint
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                            contentList {
                                id
                                header
                                innerURL
                                outerURL
                                hubspotFormId
                                sortOrder
                            }
                        }
                    }
                    ... on ContentWithList {
                        id
                        header
                        theme
                        useFocusPoint
                        isImageFirst
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        contentList {
                            id
                            header
                            innerURL
                            outerURL
                            hubspotFormId
                            sortOrder
                        }
                    }
                    ...on ContentWithRoundImage {
                        id
                        header
                        subheader
                        text
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on DescriptionWithRichText {
                        id
                        header
                        subheader
                        theme
                        text
                    }
                    ... on DescriptionWithRichTextAndImage {
                        id
                        header
                        subheader
                        theme
                        text
                        useFocusPoint
                        image: webpWithFallbackImage(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on DescriptionWithRichTextSingleColumn {
                        id
                        header
                        text
                    }
                    ... on DescriptionWithSimpleText {
                        id
                        showDoubleQuotes
                        headerTag
                        header
                        text
                        writer
                        moduleHeightType
                        theme
                        buttonText
                        innerURL
                        outerURL
                        useFocusPoint
                        altText
                        landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on DescriptionWithSimpleTextAndImage {
                        id
                        showDoubleQuotes
                        header
                        text
                        writer
                        theme
                        buttonText
                        innerURL
                        outerURL
                        videoPath
                        textBoxPosition
                        textBoxWidth
                        moduleHeight
                        useFocusPoint
                        useFocusPointPortrait
                        altText
                        landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on FullImage {
                        id
                        header
                        subheader
                        moduleHeightType
                        textPosition
                        theme
                        innerURL
                        outerURL
                        useFocusPoint
                        useFocusPointPortrait
                        altText
                        landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                        portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on SingleVideo {
                        id
                        header
                        subheader
                        theme
                        videoPath
                        useFocusPoint
                        trackingVideoName
                        videoThumbnail: webpWithFallbackVideoThumbnail(webp: $canRenderWebp) {
                            id
                            URL
                            FocusPoint
                            ImageSize
                            AltText
                        }
                    }
                    ... on SliderWithFeedback {
                        id
                        moduleHeight
                        theme
                        feedbackList {
                            id
                            feedback
                            writer
                            sortOrder
                        }
                    }
                    ... on SliderWithImage {
                        id
                        header
                        showDoubleQuotes
                        writer
                        theme
                        descriptionText
                        innerURL
                        outerURL
                        buttonText
                        isImageSquare
                        isImageWithoutBoxShadow
                        imageList {
                            id
                            header
                            text
                            backgroundColour
                            innerURL
                            outerURL
                            sortOrder
                            useFocusPoint
                            expandedCardHeader
                            expandedCardSubHeader
                            expandedCardText
                            useFocusPointExpandedImageLandscape
                            useFocusPointExpandedImagePortrait
                            expandedAltText
                            expandedImageLandscape: webpWithFallbackExpandedImageLandscape(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                            expandedImagePortrait: webpWithFallbackExpandedImagePortrait(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                        }
                    }
                    ... on SliderWithQuote {
                        id
                        header
                        subheader
                        buttonText
                        innerURL
                        outerURL
                        trackingButtonName
                        secondButtonText
                        secondInnerURL
                        secondOuterURL
                        trackingSecondButtonName
                        disableCarousel
                        disableGrowToFit
                        quoteList {
                            id
                            text
                            author
                        }
                    }
                    ... on SliderWithVideo {
                        id
                        videoList {
                            id
                            header
                            videoPath
                            sortOrder
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                            }
                        }
                    }
                    ... on SliderWithVideoPopup {
                        id
                        header
                        subheader
                        theme
                        videoList {
                            id
                            text
                            videoPath
                            backgroundColour
                            sortOrder
                            useFocusPoint
                            image: webpWithFallbackImage(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                        }
                    }
                    ... on Timeline {
                        id
                        header
                        text
                        isLargeItemTitle
                        removeBackgroundImage
                        timelineItemList {
                            id
                            title
                            useNumbering
                            milestoneYear
                            description
                            theme
                            isAvatarRotate
                            sortOrder
                            mainImage: webpWithFallbackMainImage(webp: $canRenderWebp) {
                                id
                                URL
                            }
                            avatarImage: webpWithFallbackAvatarImage(webp: $canRenderWebp) {
                                id
                                URL
                            }
                        }
                    }
                    ... on SubscribeForUpdates {
                        id
                        header
                        subheader
                        buttonText
                        theme
                        pinnedCountryCodes
                        hubspotFormIdentifier
                    }
                    ... on TopBanner {
                        id
                        buttonText
                        innerURL
                        outerURL
                        buttonTheme
                        secondButtonText
                        secondInnerURL
                        secondOuterURL
                        secondButtonTheme
                        imageList {
                            id
                            sortOrder
                            useFocusPoint
                            useFocusPointPortrait
                            altText
                            landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                            portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                                id
                                URL
                                FocusPoint
                                ImageSize
                                AltText
                            }
                        }
                    }
                    ... on SubscriptionPlan {
                        id
                        header
                        text
                        pricingCardList {
                            id
                            header
                            price
                            paymentType
                            text
                            theme
                            buttonText
                            innerURL
                            outerURL
                            sortOrder
                        }
                    }
                    ... on HubspotForm {
                        id
                        header
                        hubspotFormId
                    }
                    ... on SubscriptionList {
                        id
                        name
                        options {
                            id
                            title
                            description
                            tiers {
                                id
                                isIncluded
                                description
                                category {
                                    id
                                    name
                                    backgroundColour
                                }
                            }
                        }
                    }
                }
            }
            ... on SuccessStoryPage {
                id
                isClickable
                specificationHeader
                specification
                descriptionHeader
                description
                quote
                videoButtonText
                videoPath
                buttonText
                innerURL
                outerURL
                schoolType
                schoolName
                groupName
                theme
                textColour
                useFocusPoint
                useFocusPointLandscape
                useFocusPointPortrait
                altText
                title
                metaDescription
                metaTitle
                uRLSegment
                canonical
                robots
                oGType
                oGLocale
                twitterCard
                socialImageUrl
                hideSocial
                lastEdited
                absoluteUrl
                writer
                shortDescription
                extraHeadTags {
                    id
                    title
                    value
                    type
                }
                landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                    id
                    URL
                    FocusPoint
                    ImageSize
                    AltText
                }
                portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                    id
                    URL
                    FocusPoint
                    ImageSize
                    AltText
                }
                imagesAndVideos {
                    id
                    title
                    sortOrder
                    useFocusPoint
                    videoPath
                    image: webpWithFallbackImage(webp: $canRenderWebp)  {
                        id
                        URL
                        FocusPoint
                        ImageSize
                        AltText
                    }
                }
            }
            ... on SuccessStoryListPage {
                id
                isClickable
                header
                subheader
                showButtonOnBanner
                showHeaderOfList
                showRibbon
                useFocusPointLandscape
                useFocusPointPortrait
                buttonText
                altText
                title
                metaDescription
                metaTitle
                uRLSegment
                canonical
                robots
                oGType
                oGLocale
                twitterCard
                socialImageUrl
                hideSocial
                lastEdited
                absoluteUrl
                extraHeadTags {
                    id
                    title
                    value
                    type
                }
                landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                    id
                    URL
                    FocusPoint
                    ImageSize
                    AltText
                }
                portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                    id
                    URL
                    FocusPoint
                    ImageSize
                    AltText
                }
            }
            ... on RedirectorPage {
                id
                linkUrl
            }
        }
    }
`;

export const commonEventQuery = gql`
    query commonEvent($parentId: String!, $type: String!, $canRenderWebp: Boolean!, $countryCode: String) {
        eventMonth(parentId: $parentId, countryCode: $countryCode)
        eventMain {
            id
            title
            uRLSegment
            linkUrl
            eventDate
            regoEndDate
            useFocusPoint
            bannerHeader
            theme
            textColour
            useFocusPoint
            useFocusPointPortrait
            altText
            showRibbon
            landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                id
                URL
                FocusPoint
                ImageSize
                AltText
            }
            portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                id
                URL
                FocusPoint
                ImageSize
                AltText
            }
            cityCode {
                id
                name
            }
        }
        eventGroup(parentId: $parentId, countryCode: $countryCode) {
            id
            name
        }
        schoolType(type: $type, parentId: $parentId, countryCode: $countryCode) {
            id
            type
        }
        eventCountryCode(parentId: $parentId, countryCode: $countryCode) {
            id
            name
            code
        }
        eventCityCode(parentId: $parentId, countryCode: $countryCode) {
            id
            name
        }
        eventRegion(parentId: $parentId, countryCode: $countryCode) {
            id
            name
        }
    }
`;

export const eventListQuery = gql`
    query eventList(
        $parentId: String!,
        $countryCode: String!,
        $suitabilities: [String],
        $eventId: String,
        $month: String,
        $cityCodeId: String,
        $regionId: String,
        $canRenderWebp: Boolean!
    ) {
        eventList(
            parentId: $parentId,
            countryCode: $countryCode
            suitabilities: $suitabilities,
            eventId: $eventId,
            month: $month,
            cityCodeId: $cityCodeId
            regionId: $regionId
        ) {
            id
            title
            eventDate
            eventEndDate
            linkUrl
            location
            theme
            textColour
            eventStartTime
            eventEndTime
            shortDescription
            showRibbon
            eventTerm(countryCode: $countryCode)
            bannerLocationLabel
            previewImage: webpWithFallbackPreviewImage(webp: $canRenderWebp) {
                id
                URL
                FocusPoint
                ImageSize
                AltText
            }
            price {
                id
                amount
                label
                subLabel
            }
            cityCode {
                id
                name
            }
            countryCode {
                id
                name
            }
        }
    }
`;

export const commonProductDataQuery = gql`
    query commonProductData($type: String!, $parentId: String!) {
        productGroup {
            id
            name
        }
        productSuitability {
            id
            name
        }
        countryCode(type: $type, parentId: $parentId) {
            id
            code
            name
        }
    }
`;

export const productListQuery = gql`
    query productList($parentId: String!, $minPriceRange: String, $maxPriceRange: String, $productGroup: String, $suitability: String, $countryCode: String, $sortField: String, $sortDirection: String, $canRenderWebp: Boolean!) {
        productList(parentId: $parentId, minPriceRange: $minPriceRange, maxPriceRange: $maxPriceRange, productGroup: $productGroup, suitability: $suitability, countryCode: $countryCode, sortField: $sortField, sortDirection: $sortDirection) {
            id
            title
            deliveryCountry
            recommended
            outOfStock
            shouldDiscount
            expectedDate
            specification
            description
            groupName
            suitabilityName
            weight
            depth
            width
            height
            inventoryQuantity
            availableForSale
            imagesAndVideos {
                id
                title
                sortOrder
                useFocusPoint
                videoPath
                image: webpWithFallbackImage(webp: $canRenderWebp)  {
                    id
                    URL
                    FocusPoint
                    ImageSize
                    AltText
                }
            }
            price {
                id
                amount
                discountedPrice
            }
        }
        countryCodes {
            id
            code
            name
            currency
            taxRate
            taxLabel
            includesShippingTax
            shippingRates {
                id
                price
                maxWeight
                maxWidth
                maxDepth
                maxHeight
            }
        }
    }
`;

export const successStoryCommonQuery = gql`
    query successStoryCommon($type: String!, $parentId: String!, $canRenderWebp: Boolean!) {
        successStoryBanner(parentId: $parentId) {
            id
            title
            uRLSegment
            linkUrl
            useFocusPoint
            useFocusPointLandscape
            useFocusPointPortrait
            theme
            textColour
            altText
            landscape: webpWithFallbackLandscape(webp: $canRenderWebp) {
                id
                URL
                FocusPoint
                ImageSize
                AltText
            }
            portrait: webpWithFallbackPortrait(webp: $canRenderWebp) {
                id
                URL
                FocusPoint
                ImageSize
                AltText
            }
        }
        successStoryGroup(parentId: $parentId) {
            id
            name
        }
        schoolType(type: $type, parentId: $parentId) {
            id
            type
        }
        countryCode(type: $type, parentId: $parentId) {
            id
            code
            name
        }
    }
`;

export const successStoryListQuery = gql`
    query successStoryList($parentId: String!, $countryCode: String, $schoolType: String, $canRenderWebp: Boolean!) {
        successStoryList(parentId: $parentId, countryCode: $countryCode, schoolType: $schoolType) {
            id
            title
            location
            linkUrl
            useFocusPoint
            textColour
            theme
            schoolType
            shortDescription
            previewImage: webpWithFallbackPreviewImage(webp: $canRenderWebp) {
                id
                URL
                FocusPoint
                ImageSize
                AltText
            }
        }
    }
`;

export const careerCommonQuery = gql`
    query careerCommon($parentId: String!) {
        cityCode(parentId: $parentId) {
            id
            name
        }
    }
`;

export const careerListQuery = gql`
    query careerList($parentId: String!, $cityCodeID: String, $canRenderWebp: Boolean!) {
        careerList(parentId: $parentId, cityCodeID: $cityCodeID) {
            id
            title
            linkUrl
            department
            shortDescription
            textColour
            theme
            useFocusPoint
            showRibbon
            previewImage: webpWithFallbackPreviewImage(webp: $canRenderWebp) {
                id
                URL
                FocusPoint
                ImageSize
                AltText
            }
            cityCode {
                id
                name
            }
        }
    }
`;

export const getHomeUserQuery = gql`
    query getHomeUser {
        getHomeUser {
            id
            FirstName
            Surname
            Email
            IsEmailVerified
            IsSocialConfirmed
            HasViewedGettingStarted
            PostCode
            WhatBestDescribesYou
            LastLoginType
            StripeCustomerID
            EmailVerification {
                id
                IsValid
            }
            Subscription {
                id
                CurrentPeriodEnd
                NiceCurrentPeriodEnd
                NiceGracePeriodEnd
                GracePeriodEnd
                IsValid
                Quantity
                PaymentIntent
                Price
                ProductName
                SubscriptionID
                CancelStatus
                CancelScheduleRequestedAt
                PaymentStatus
                FirstPaymentStatus
                BillingHistory {
                    id
                    InvoiceID
                    Currency
                    Amount
                    Card
                    CardType
                    Created
                    ReceiptURL
                }
            }
            BillingAddress {
                Phone
                Line
                City
                State
                CountryCode
                PostCode
            }
        }
    }
`;

export const getBlogPosts = gql`
    query blogPost($canRenderWebp: Boolean!) {
        blogPosts {
            id
            publishedDate
            title
            subheader
            shortDescription
            uRLSegment
            hideBanner
            useAsHero
            useFocusPointBanner
            useFocusPointListPageBanner
            useFocusPointPreview
            useCurveForBanner
            banner: webpWithFallbackListPageBanner(webp: $canRenderWebp) {
                id
                URL
                FocusPoint
                ImageSize
                AltText
            }
            author {
                id
                name
                webpWithFallbackPortrait(webp: $canRenderWebp) {
                    id
                    URL
                    FocusPoint
                    ImageSize
                    AltText
                }
            }
            previewImage: webpWithFallbackPreviewImage(webp: $canRenderWebp) {
                id
                URL
                FocusPoint
                ImageSize
                AltText
            }
        }
    }
`;

export const getBlogCategories = gql`
    query blogCategories {
        blogCategories {
            id
            name
            postIds
        }
    }
`;

export const CREATE_RESOURCE_ORDER = gql`
    mutation createResourceOrder (
        $paymentOption: String,
        $firstName: String!,
        $lastName: String!,
        $schoolName: String!,
        $streetNo: String!,
        $streetName: String!,
        $suburb: String!,
        $city: String!,
        $postCode: String!,
        $deliveryCountryId: String!,
        $contactEmail: String!,
        $emailToInvoice: String!,
        $orderNo: String,
        $notes: String,
        $promoCode: String,
        $discountedPrice: Float,
        $subtotal: Float,
        $shippingPrice: Float,
        $taxAmount: Float,
        $totalAmount: Float,
        $taxLabel: String,
        $taxPrefix: String,
        $currency: String,
        $orderList: [String]
    ) {
        createResourceOrder(
            paymentOption: $paymentOption,
            firstName: $firstName,
            lastName: $lastName,
            schoolName: $schoolName,
            streetNo: $streetNo,
            streetName: $streetName,
            suburb: $suburb,
            city: $city,
            postCode: $postCode,
            deliveryCountryId: $deliveryCountryId,
            contactEmail: $contactEmail,
            emailToInvoice: $emailToInvoice,
            orderNo: $orderNo,
            notes: $notes,
            promoCode: $promoCode,
            discountedPrice: $discountedPrice,
            subtotal: $subtotal,
            shippingPrice: $shippingPrice,
            taxAmount: $taxAmount,
            totalAmount: $totalAmount,
            taxPrefix: $taxPrefix,
            taxLabel: $taxLabel,
            currency: $currency,
            orderList: $orderList
        ) {
            status
        }
    }
`;

export const SUBSCRIBE_FOR_UPDATES = gql`
    mutation subscribeForUpdates(
        $hubspotFormId: String!,
        $firstName: String!,
        $lastName: String!,
        $email: String!,
        $school: String!,
        $country: String!
    ) {
        subscribeForUpdates(
            hubspotFormId: $hubspotFormId,
            firstName: $firstName,
            lastName: $lastName,
            email: $email,
            school: $school,
            country: $country,
        ) {
            status
        }
    }
`;

export const getTierCategories = gql`
    query tierCategories($parentId: String!) {
        tierCategories(parentId: $parentId) {
            id
            name
            backgroundColour
        }
    }
`;

export const getFeatureFlags = gql`
    query featureFlags {
        featureFlags
    }
`;

export const CREATE_STRIPE_PAYMENT_INTENT = gql`
    mutation createStripePaymentIntent($input: String!, $countryCode: String) {
        createStripePaymentIntent(input: $input, countryCode: $countryCode)
    } 
`;

export const VALIDATE_PRODUCT_DISCOUNT = gql`
    mutation validateProductDiscount($promoCode: String!, $countryCode: String!, $cartTotal: Float) {
        validateProductDiscount(promoCode: $promoCode, countryCode: $countryCode) {
            id
            code
            productDiscounts(countryCode: $countryCode, cartTotal: $cartTotal) {
                id
                percentDiscount
                minCartTotal
                products {
                    id
                }
            }
        }
    } 
`;

export const UPDATE_OR_CREATE_CART = gql`
    mutation updateShoppingCart($productId: Int!, $quantity: Int, $cartId: Int, $isTaxIncluded: Boolean) {
        updateShoppingCart(productId: $productId, quantity: $quantity, cartId: $cartId, isTaxIncluded: $isTaxIncluded) {
            id
            subtotalAmount
            totalAmount
            products {
                id
                quantity
                unitPrice
                discountedPrice
                totalPrice
                title
                type
                totalQuantity
                image {
                    id
                    URL
                }
            }
        }
    }
`;

export const DELETE_CART = gql`
    mutation deleteShoppingCart($cartId: Int!) {
        deleteShoppingCart(cartId: $cartId)
    }
`;

export const COOKIE_CATEGORIES_QUERY = gql`
    query cookieCategories {
        cookieCategories {
            id
            name
            description
            isManageable
            cookies {
                id
                name
                description
                duration
                domain
            }
        }
    }
`;

export const GET_ELIGIBLE_DISCOUNT = gql`
    query eligibleDiscount($orderCartTotal: Float!, $countryCode: String!) {
        eligibleDiscount (orderCartTotal: $orderCartTotal, countryCode: $countryCode) {
            id
            percentDiscount
            products {
                id
            }
        }
    }
`;
export default pageQuery;
