import { css, Theme, useTheme } from "@emotion/react";
import React from "react";

import CartWithCircles from "core/Components/Icons/CartWithCircles";
import RoundButton from "core/Components/Buttons/RoundButton";

const EmptyCart: React.FC = () => {
    const theme: Theme = useTheme();

    const style = css`
        margin-bottom: 100px;
    `;

    const backgroundCircleStyle = css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 115px auto auto;
        width: 350px;
        height: 350px;
        border-radius: 175px;
        background-color: white;

        ${theme.breakpoints.down("xl")} {
            width: 190px;
            height: 190px;
            border-radius: 95px;
        }
    `;

    const cartStyle = css`
        width: 165px;

        ${theme.breakpoints.down("xl")} {
            width: 90px;
        }
    `;

    const textStyle = css`
        width: 200px;
        font-size: 25px;
        font-weight: ${theme.fonts.weights.bold};
        line-height: 30px;
        text-align: center;

        ${theme.breakpoints.down("xl")} {
            width: 105px;
            font-size: 14px;
            line-height: 16px;
        }
    `;

    const buttonStyle = css`
        margin: 71px auto auto;
        width: 308px;
        height: 60px;
    `;

    return (
        <div css={style}>
            <div css={backgroundCircleStyle}>
                <CartWithCircles css={cartStyle} />
                <div css={textStyle}>Your cart is currently empty</div>
            </div>
            <RoundButton
                linkURL={'classroom-resources'}
                css={buttonStyle}
                textColour={'black'}
                buttonBorderRadius={30}
                backgroundColour={theme.colours.gold}
                buttonHoverColour={theme.colours.mySin}
                useTextHover={false}
            >
                <span css={textStyle}>Go shopping</span>
            </RoundButton>
        </div>
    );
};

export default EmptyCart;