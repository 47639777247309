import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
    hasItems: boolean,
};

const CartPopupMain: React.FC<Props> = ({ children, hasItems }) => {
    const theme: Theme = useTheme();

    const style = css`
        padding-right: 8px;
        width: calc(100% + 14px);
        height: 100%;
        overflow-y: auto;

        ${!hasItems && css`
            display: flex;
            justify-content: center;
            align-items: center;
        `}
        ${theme.breakpoints.down("xs")} {
            width: calc(100% + 17px);
        }

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: ${theme.colours.silverChalice};
            border-radius: 3px;
        }

        &::-webkit-scrollbar-track {
            border-left: 2px solid ${theme.colours.grey[90]};
            border-right: 2px solid ${theme.colours.grey[90]};
            background-color: ${theme.colours.alto};
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default CartPopupMain;