import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";
import OutsideClickHandler from "react-outside-click-handler";

import { useAppState } from "core/contexts/AppContext";
import { useCartItemsContext } from "core/contexts/CartItemsContext";

type Props = {
    children: ReactNode,
    headerHeight: number,
};

const CartPopupSection: React.FC<Props> = ({ children, headerHeight }) => {
    const theme: Theme = useTheme();
    const [appState, setAppState] = useAppState();
    const { confirmationState } = useCartItemsContext();

    const closeCartPopup = () => {
        setAppState({
            isOpenCartPopup: false,
        });
    };

    const style = css`
        position: fixed;
        top: ${headerHeight}px;
        right: ${appState.isOpenCartPopup ? 0 : -427}px;
        width: 427px;
        height: 100%;
        transition: right 300ms ease-in-out;
        box-shadow: ${appState.isOpenCartPopup ? theme.borderAndShadow.boxShadow8 : 'unset'};
        z-index: ${theme.zIndex.zIndexHigh};

        ${theme.breakpoints.only("xl")} {
            right: ${appState.isOpenCartPopup ? 0 : -404}px;
            width: 404px;
        }

        ${theme.breakpoints.between("sm", "lg")} {
            right: ${appState.isOpenCartPopup ? 0 : -374}px;
            width: 374px;
        }

        ${theme.breakpoints.down("xs")} {
            right: ${appState.isOpenCartPopup ? 0 : -360}px;
            width: 360px;
        }
        
        > div {
            height: 100%;
        }
    `;

    return (
        <div css={style}>
            <OutsideClickHandler
                onOutsideClick={closeCartPopup}
                disabled={confirmationState.popupType === 'remove-item-from-panel'}
            >
                {children}
            </OutsideClickHandler>
        </div>
    );
};

export default CartPopupSection;