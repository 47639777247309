import React from "react";
import { css, useTheme } from "@emotion/react";
import DialogContent from "core/Components/Dialog/DialogContent";
import RoundButton from "core/Components/Buttons/RoundButton";
import Dialog from "core/Components/Dialog/Dialog";
import { NOOP } from "core/constants";

type Props = {
    onClose: () => void,
    message: string,
    onLeftButtonClick?: () => void,
    onRightButtonClick?: () => void,
};

const ConfirmationRemoveAlertDialog: React.FC<Props> = (
    {
        onClose,
        message,
        onLeftButtonClick = NOOP,
        onRightButtonClick = NOOP,
    }
) => {
    const theme = useTheme();

    const modalStyle = css`
        display: flex;

        ${theme.breakpoints.up('lg')} {
            height: 217px;
        }

        ${theme.breakpoints.up('sm')} {
            width: 427px;
        }

        ${theme.breakpoints.between('sm', 'md')} {
            height: 212px;
        }

        ${theme.breakpoints.up('xl')} {
            padding: 31px 16px 22px;
        }

        ${theme.breakpoints.between('sm', 'lg')} {
            padding: 50px 26px 22px;
        }

        ${theme.breakpoints.down('xs')} {
            width: 314px;
            height: 164px;
            padding: 33px 15px 22px;
        }
    `;

    const dialogContentStyle = css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: ${theme.colours.shipGray};
    `;

    const areYouSureStyle = css`
        display: flex;
        font-size: 18px;
        font-weight: ${theme.fonts.weights.bold};

        padding: 20px 60px 50px;

        ${theme.breakpoints.down('lg')} {
            padding: 0 30px;
            font-size: 16px;
            line-height: 20px;
        }

        ${theme.breakpoints.between('sm', 'lg')} {
            padding: 0 82px;
        }
    `;

    const buttonGroupStyle = css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    `;

    const buttonStyle = ({ isYes = false } = {}) => css`
        width: 188px;
        height: 50px;

        div {
            font-size: 16px;
            font-weight: ${theme.fonts.weights.normal};
            color: white;
            background-color: ${isYes ? theme.colours.redRibbon : theme.colours.curiousBlue2};

            > div {
                background-color: transparent !important;
            }

            &:hover {
                background-color: ${isYes ? theme.colours.wildWatermelon2 : theme.colours.malibu6};
            }
        }

        ${theme.breakpoints.up('sm')} {
            height: 50px;
            min-height: 50px;
            max-height: 50px;
        }

        ${theme.breakpoints.between('sm', 'lg')} {
            width: 176px;
        }

        ${theme.breakpoints.down('xs')} {
            width: 135px;
            height: 38px;
            min-height: 38px;
            max-height: 38px;

            div {
                font-size: 14px;
            }
        }
    `;

    return (
        <Dialog
            extraStyle={modalStyle}
            onClose={onClose}
        >
            <DialogContent css={dialogContentStyle} center>
                <div css={areYouSureStyle}>
                    {message}
                </div>
                <div css={buttonGroupStyle}>
                    <RoundButton
                        onButtonClick={onLeftButtonClick}
                        css={buttonStyle()}
                    >
                        No
                    </RoundButton>
                    <RoundButton
                        css={buttonStyle({
                            isYes: true
                        })}
                        onButtonClick={onRightButtonClick}
                    >
                        Yes
                    </RoundButton>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default ConfirmationRemoveAlertDialog;