import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    showLayer?: boolean,
    backgroundColour?: string,
    opacity?: number,
};

const LayerToBlockMain: React.FC<Props> = (
    {
        showLayer = false,
        backgroundColour = 'black',
        opacity = 0.1,
    }
) => {
    const theme: Theme = useTheme();

    const style = css`
        position: fixed;

        ${showLayer && css`
            width: 100vw;
            height: 100vh;
            z-index: ${theme.zIndex.zIndexMediumHigh - 1};
            background-color: ${backgroundColour};
            opacity: ${opacity};
        `}
    `;

    return (
        <div css={style} />
    );
};

export default LayerToBlockMain;