import React, { ReactNode, useMemo } from 'react';
import { css, Theme, useTheme } from "@emotion/react";
import pluralize from "pluralize";

import { useAppState } from "core/contexts/AppContext";
import { getCountryCodeAndCurrencyCode } from "core/includes/countries";

import HeaderContainer from "pages/Components/CartAndCheckout/components/HeaderContainer";
import HeaderTitle from "pages/Components/CartAndCheckout/components/HeaderTitle";
import HeaderCurrency from "pages/Components/CartAndCheckout/components/HeaderCurrency";
import SummaryRow from "pages/Components/CartAndCheckout/components/Summary/SummaryRow";
import TitleAndPrice from "pages/Components/CartAndCheckout/components/TitleAndPrice";
import SummaryContainer from "pages/Components/CartAndCheckout/components/Summary/SummaryContainer";
import useCartDetailSizes from "pages/Components/CartAndCheckout/hooks/useCartDetailSizes";
import SummaryAndButtonContainer from "pages/Components/CartAndCheckout/components/Summary/SummaryAndButtonContainer";
import SummaryRowsContainer from "pages/Components/CartAndCheckout/components/Summary/SummaryRowsContainer";
import SummaryItemsContainer from "pages/Components/CartAndCheckout/components/Summary/SummaryItemsContainer";
import HeaderItems from "pages/Components/CartAndCheckout/components/Summary/HeaderItems";
import SummaryItemRow from "pages/Components/CartAndCheckout/components/Summary/SummaryItemRow";
import SummaryItemRowsContainer from "pages/Components/CartAndCheckout/components/Summary/SummaryItemRowsContainer";
import Description from "pages/Components/CartAndCheckout/components/Description";

type Props = {
    isCheckout?: boolean,
    button?: ReactNode,
    productDisclaimer?: string,
    cartStatus?: string,
}

const CartSummaryContainer: React.FC<Props> = (
    {
        isCheckout = false,
        button = null,
        productDisclaimer = null,
        cartStatus = '',
    }
) => {
    const theme: Theme = useTheme();
    const { itemGroupBoxWidth } = useCartDetailSizes();
    const [appState] = useAppState();
    const { currency } = appState.ipCountry?.currency || getCountryCodeAndCurrencyCode(appState.ipCountry.code);

    const cartItems = useMemo(() => (
        appState.cart?.products || []
    ), [appState]);

    const isEmptyCart = cartStatus === 'empty' || !cartItems.length;

    const messageStyle = css`
        margin-top: 38px;
        font-size: 20px;
        line-height: 23px;
        text-align: center;

        ${theme.breakpoints.between('sm', 'xl')} {
            margin-top: 36px;
            font-size: 15px;
            line-height: 18px;
        }

        ${theme.breakpoints.down('md')} {
            margin-top: 26px;
        }

        ${theme.breakpoints.down('xs')} {
            font-size: 14px;
            line-height: 17px;
        }
    `;

    const buttonPlaceholderStyle = css`
        height: 103px;
    `;

    return (
        <SummaryContainer itemGroupBoxWidth={itemGroupBoxWidth}>
            <HeaderContainer alignItem={'end'} borderColour={'white'}>
                <HeaderTitle textColour={'white'}>Order Summary</HeaderTitle>
                <HeaderCurrency>Price in {currency}</HeaderCurrency>
            </HeaderContainer>

            {isEmptyCart ? (
                <div css={messageStyle}>Nothing to show here right now.</div>
            ) : (
                <>
                    {isCheckout && (
                        <SummaryItemsContainer>
                            <HeaderItems>{pluralize('item', cartItems.length, true)}</HeaderItems>
                            <SummaryItemRowsContainer>
                                {cartItems.map((item: CartProduct, index: number) => (
                                    <SummaryItemRow
                                        item={item}
                                        key={item.title}
                                        isLast={index === cartItems.length - 1}
                                    />
                                ))}
                            </SummaryItemRowsContainer>
                        </SummaryItemsContainer>
                    )}

                    <SummaryAndButtonContainer>
                        <SummaryRowsContainer>
                            <SummaryRow type={isCheckout ? 'shipping' : 'subTotal'}>
                                {isCheckout && (
                                    <TitleAndPrice
                                        title={'Shipping fee'}
                                        price={0}
                                    />
                                )}
                                <TitleAndPrice
                                    title={'Subtotal'}
                                    price={appState.cart?.subtotalAmount}
                                />
                                <TitleAndPrice
                                    title={
                                        isCheckout ?
                                            `Total ${appState.ipCountry.taxLabel}` :
                                            appState.ipCountry.taxLabel
                                    }
                                    price={appState.cart?.totalAmount - appState.cart?.subtotalAmount}
                                />
                            </SummaryRow>

                            <SummaryRow type={'total'}>
                                <TitleAndPrice
                                    title={'Total cost'}
                                    price={appState.cart?.totalAmount ?? 0}
                                    isTotal
                                />
                            </SummaryRow>
                        </SummaryRowsContainer>

                        {button ?? <div css={buttonPlaceholderStyle} />}
                    </SummaryAndButtonContainer>

                    {productDisclaimer && (
                        <Description marginTop={30} textAlign={'center'}>
                            {productDisclaimer}
                        </Description>
                    )}
                </>
            )}

        </SummaryContainer>
    );
}

export default CartSummaryContainer;