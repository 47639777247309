import React, { ReactNode } from 'react';
import { css, Theme, useTheme } from "@emotion/react";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    children: ReactNode,
}

const HeaderItems = ({ children }: Props) => {
    const theme: Theme = useTheme();

    const styles = css`
        font-weight: ${theme.fonts.weights.bold};
        justify-content: start;
        margin-top: 31px;
    `;

    return (
        <ContentCombine fontSizeType={'18'} css={styles}>{children}</ContentCombine>
    );
};

export default HeaderItems;