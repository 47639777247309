import React, { ReactNode } from "react";
import { css } from "@emotion/react";

type Props = {
    children: ReactNode,
};

const HeaderCurrency: React.FC<Props> = ({ children }) => {
    const style = css`
        font-size: 15px;
        line-height: 18px;
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default HeaderCurrency;