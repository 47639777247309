import React, { ReactNode } from "react";
import { css } from "@emotion/react";

type Props = {
    children: ReactNode,
    paddingTop: number,
    paddingRight: number,
    itemGroupBoxWidth: number,
};

const MainContainer: React.FC<Props> = ({ children, paddingTop, paddingRight, itemGroupBoxWidth }) => {
    const style = css`
        padding-top: ${paddingTop}px;
        padding-right: ${paddingRight}px;
        width: calc(${itemGroupBoxWidth}px + ${paddingRight}px);
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default MainContainer;