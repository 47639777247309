import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation, Routes, Route } from "react-router";

import AuthPage from "pages/Components/auth/AuthPage";
import Footer from "core/Components/Footer";
import Header from "core/Components/Header";
import { NOOP } from "core/constants";
import { useAppState } from "core/contexts/AppContext";
import { GOOGLE_ANALYTICS_ID } from "core/config/config";

import Main from "pages/Main";
import { CartItemsContextProvider } from "core/contexts/CartItemsContext";
import CartPopup from "core/Components/CartPopup/CartPopup";
import { getURLSegment } from "core/includes/formatters";
import { hasFeatureFlag } from "core/includes/site";
import { FLAG_SHOP_2 } from "core/includes/featureFlags";
import ConfirmationPopup from "pages/Components/CartAndCheckout/components/ConfirmationPopup";
import CartPage from "pages/Components/CartAndCheckout/CartPage";

type Props = {
    showBreadcrumb: boolean,
    breadcrumbHandler: (showBreadcrumb: boolean) => void,
    footerMenu?: { menu: [any], side: [any], bottom: [any] },
    footerMenuHandler: (pages: any) => void,
};

/**
 * Get an id to use on the script tag.
 *
 * @param {string} analyticsId
 * @returns {string}
 */
const getScriptId = (analyticsId: string) => `analytics-${analyticsId}`;

/**
 * Start the tracking.
 *
 * @param analyticsId
 */
const startTracking = (analyticsId: string) => {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
    script.async = true;
    script.id = getScriptId(analyticsId);
    document.getElementsByTagName('head')[0].appendChild(script);
};

const injectGA = (analyticsId: string) => {
    if (typeof window == 'undefined') {
        return;
    }
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).gtag('js', new Date());
    (window as any).gtag('config', analyticsId);
};

const TARGETS_TO_BE_DISABLED_CART_ICON = [
    '/cart-summary',
    '/checkout',
];

const AppPage: React.FC<Props> = (
    {
        showBreadcrumb,
        breadcrumbHandler = NOOP,
        footerMenu = undefined,
        footerMenuHandler = NOOP,
    }
) => {
    const [appState] = useAppState();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const stage = params.get("stage");
    const currentURLSegment = getURLSegment(location.pathname);
    const hasShopFeatureFlag = hasFeatureFlag(appState.featureFlags, FLAG_SHOP_2);

    const TARGETS_TO_HIDE_HEADER_COUNTRY_DROPDOWN: any = useMemo(() => ({
        pages: [
            ...hasShopFeatureFlag ? ["ProductListPage"] : []
        ],
        groupUrlSegment: [
            '/cart-summary',
            '/checkout',
            '/auth/',
        ]
    }), [hasShopFeatureFlag]);

    /**
     * Track a page view.
     *
     * @param location
     */
    const trackPageView = useCallback(() => {
        if (location && location.pathname && (window as any).gtag) {
            (window as any).dataLayer = (window as any).dataLayer || [];

            (window as any).gtag('event', "page_view", {
                page_path: window.location.pathname + window.location.search,
            });
        }
    }, [location]);

    useEffect(() => {
        // check if tag manager already loaded
        if (!document.getElementById(getScriptId(GOOGLE_ANALYTICS_ID))) {
            startTracking(GOOGLE_ANALYTICS_ID);
            injectGA(GOOGLE_ANALYTICS_ID);
            trackPageView();
        }
    }, [location, trackPageView]);

    // The option to hide the header country dropdown
    const hideHeaderCountry = useMemo(() => {
        const currentPage = appState.pageClassNames?.find((pageClassName: PageClassName) => pageClassName.urlSegment === currentURLSegment);
        // Check by ClassName
        if (currentPage) {
            return !!TARGETS_TO_HIDE_HEADER_COUNTRY_DROPDOWN.pages.find(
                (className: string) => currentPage.className.endsWith(className)
            );
            // Check by URLSegment
        } else {
            return !!TARGETS_TO_HIDE_HEADER_COUNTRY_DROPDOWN.groupUrlSegment.find(
                (urlSegment: string) => location.pathname.indexOf(urlSegment) > -1
            );
        }
    }, [appState.pageClassNames, currentURLSegment, TARGETS_TO_HIDE_HEADER_COUNTRY_DROPDOWN.pages, TARGETS_TO_HIDE_HEADER_COUNTRY_DROPDOWN.groupUrlSegment, location.pathname]);

    const isDisabledCartIcon = useMemo(() => {
        return !!TARGETS_TO_BE_DISABLED_CART_ICON.find(
            (urlSegment: string) => location.pathname.indexOf(urlSegment) > -1
        );
    }, [location.pathname]);

    return (
        <div className="App">
            <CartItemsContextProvider>
                <CartPopup showBreadcrumb={showBreadcrumb} hideHeaderCountry={hideHeaderCountry} />
                <Header
                    stage={stage}
                    footerMenuHandler={footerMenuHandler}
                    breadcrumbHandler={breadcrumbHandler}
                    hideHeaderCountry={hideHeaderCountry}
                    isDisabledCartIcon={isDisabledCartIcon}
                />
                <Routes>
                    <Route
                        path={"/"}
                        element={
                            <Main
                                stage={stage}
                                showBreadcrumb={showBreadcrumb}
                                hideHeaderCountry={hideHeaderCountry}
                            />
                        }
                    />
                    <Route
                        path={"/auth/*"}
                        element={
                            <AuthPage />
                        }
                    />
                    <Route
                        path={"*"}
                        element={
                            <Main
                                stage={stage}
                                showBreadcrumb={showBreadcrumb}
                                hideHeaderCountry={hideHeaderCountry}
                            />
                        }
                    />
                    {hasShopFeatureFlag && (
                        <Route
                            path={"/cart-summary"}
                            element={
                                <CartPage
                                    showBreadcrumb={showBreadcrumb}
                                    hideHeaderCountry={hideHeaderCountry}
                                />
                            }
                        />
                    )}
                </Routes>
                {!appState.loading && (
                    <Footer footerMenu={footerMenu} />
                )}
                {!isDisabledCartIcon && (
                    <ConfirmationPopup />
                )}
            </CartItemsContextProvider>

        </div>
    );
}

export default AppPage;
