import React, { useMemo } from "react";
import { useTheme } from "@emotion/react";

import useBreakpoint from "core/hooks/useBreakpoint";

import ProductContainer from "pages/Components/ProductListPage/components/ProductContainer";

type Props = {
    productList: ProductItem[],
};

const BorderForSpaces: React.FC<Props> = ({ productList }) => {
    const theme = useTheme();
    const { minWidth } = useBreakpoint();

    const productListLength = productList?.length;

    const numberOfEmptyComponentForBorder = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes["xl"]:
                return productListLength % 4 ? 4 - productListLength % 4 : 0;
            case minWidth >= theme.breakpoints.sizes["md"]:
                return productListLength % 3 ? 3 - productListLength % 3 : 0;
            default:
                return 0;
        }
    }, [minWidth, theme.breakpoints.sizes, productListLength]);

    const bordersForSpaces = useMemo(() => {
        const components = [];
        for (let i = 0; i < numberOfEmptyComponentForBorder; i++) {
            components.push(<ProductContainer key={i} index={productListLength + i} />);
        }
        return components;
    }, [numberOfEmptyComponentForBorder, productListLength]);

    return (
        <>
            {bordersForSpaces}
        </>
    );
};

export default BorderForSpaces;
