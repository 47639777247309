/**
 * Compare the request price and the available quantity,
 * then return the available order quantity
 *
 * @param inputQuantity
 * @param availableQuantity
 */
const checkAvailableQuantity = ({
                                    inputQuantity,
                                    availableQuantity,
                                    variationValue,
                                }: {
    inputQuantity: number,
    availableQuantity: number,
    variationValue: number,
}) => {
    const quantity = (inputQuantity ?? 1) + variationValue; // Request quantity
    return Math.min(availableQuantity, quantity);
};

/**
 * Maximum quantity that a customer can add for each product
 */
const MAXIMUM_PRODUCT_QUANTITY = 9999;
const MINIMUM_PRODUCT_QUANTITY = 1;

export {
    checkAvailableQuantity,
    MAXIMUM_PRODUCT_QUANTITY,
    MINIMUM_PRODUCT_QUANTITY,
};