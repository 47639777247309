import React from "react";
import { css } from "@emotion/react";

import { NOOP } from "core/constants";

type Props = {
    quantity: string | number,
    textColour?: string,
    width?: number,
    circleButtonWidth?: number,
    title?: string,
    readonly?: boolean,
    onResetValue?: () => void,
    onChange?: (quantity: React.ChangeEvent<HTMLInputElement>) => void,
    placeholder?: string,
};

const RoundQuantityInput: React.FC<Props> = (
    {
        quantity,
        textColour = "black",
        width = undefined,
        circleButtonWidth = 0,
        readonly = false,
        title = '',
        onResetValue = NOOP,
        onChange = NOOP,
        placeholder = '0',
    }
) => {
    const formatInput = (e: React.KeyboardEvent<HTMLElement>) => {
        if (
            e.key !== undefined &&
            e.key !== "Backspace" &&
            e.key !== "ArrowLeft" &&
            e.key !== "ArrowRight" &&
            e.key !== "Delete" &&
            !e.key.match(/^[0-9\b\del]+$/gm)
        ) {
            e.preventDefault();
        }

        if (e.key === "Enter") {
            e.currentTarget.blur();
            if (onResetValue !== NOOP) onResetValue();
        }
    };

    const style = css`
        color: ${textColour};
        text-align: center;
        border: 0;
        outline: none;

        ${width ? css`
            width: ${width}px;
        ` : css`
            width: calc(100% - ${circleButtonWidth * 2}px - 10px);
        `}
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    `;

    return (
        <input
            type={"number"}
            placeholder={placeholder}
            value={quantity}
            onChange={onChange}
            onKeyDown={formatInput}
            readOnly={readonly}
            css={style}
            title={title}
        />
    );
}

export default RoundQuantityInput;