import React from "react";
import { css } from "@emotion/react";

import Input from "core/Components/Form/Input";

type Props = {
    className?: string,
    label?: string,
    name: string,
    value?: string,
    width?: number,
    onChange?: any,
    hasError?: boolean,
    hasTick?: boolean,
};

const FormInput: React.FC<Props> = (
    {
        className = "",
        label,
        name,
        value,
        width = 76, // Percent
        onChange,
        hasError,
        hasTick,
    }
) => {
    const style = css`
        width: ${width}%;
    `;

    return (
        <Input
            hasError={hasError}
            hasTick={hasTick}
            label={label}
            name={name}
            onChange={onChange}
            value={value}
            css={style}
            className={className}
        />
    );
};

export default FormInput;
