import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
    className?: string,
    isSmallBreakpoint?: boolean,
};

const PageLeftSection: React.FC<Props> = ({ children, className = '', isSmallBreakpoint = false }) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        justify-content: end;
        width: 63.5%;
        background-color: ${theme.colours.alabaster};

        ${theme.breakpoints.up("3xl")} {
            width: 62%;
        }

        ${theme.breakpoints.only("xl")} {
            width: 60%;
        }

        ${theme.breakpoints.only("lg")} {
            width: 63%;
        }

        ${theme.breakpoints.down("md")} {
            justify-content: center;
            width: 100%;
            min-height: 300px;
        }

        ${theme.breakpoints.only("sm")} {
            padding-left: 30px;
        }
    `;

    const subDivStyle = css`
        ${theme.breakpoints.only('sm')} {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    `;

    return (
        <div css={style} className={className}>
            {isSmallBreakpoint ? (
                <div css={subDivStyle}>
                    {children}
                </div>
            ) : (
                children
            )}
        </div>
    );
};

export default PageLeftSection;
