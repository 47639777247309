import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
    width: number,
};

const CartPopupFooter: React.FC<Props> = ({ children, width }) => {
    const theme: Theme = useTheme();

    const style = css`
        padding-bottom: 54px;
        width: ${width}px;
        border-top: 2px solid ${theme.colours.alto};
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default CartPopupFooter;