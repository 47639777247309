/**
 * Feature flag that controls if a user can use a credit card to check out.
 * If prefixed with * or no prefix at all, all countries will be able to check out.
 * If prefixed with a country code, then only applied to that country.
 */
export const FLAG_STRIPE_PAYMENTS = "-stripe-payments";
export const FLAG_COOKIE_SETTINGS = "cookie-settings"
export const FLAG_SHOP_2 = "shop-2"

const f = {
    FLAG_STRIPE_PAYMENTS,
    FLAG_COOKIE_SETTINGS,
    FLAG_SHOP_2,
};

export default f;