import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
    headerHeight: number,
    className?: string,
};

const PageSection: React.FC<Props> = ({ children, headerHeight, className = '' }) => {
    const theme: Theme = useTheme();

    const style = css`
        position: relative;
        display: flex;
        margin-top: ${headerHeight}px;
        width: 100%;

        ${theme.breakpoints.up("3xl")} {
            min-height: calc(900px - ${headerHeight}px);
        }

        ${theme.breakpoints.only("xxl")} {
            min-height: calc(899px - ${headerHeight}px);
        }

        ${theme.breakpoints.only("xl")} {
            min-height: calc(720px - ${headerHeight}px);
        }

        ${theme.breakpoints.only("lg")} {
            min-height: calc(768px - ${headerHeight}px);
        }

        ${theme.breakpoints.only("md")} {
            min-height: calc(1024px - ${headerHeight}px);
        }

        ${theme.breakpoints.only("md")} {
            min-height: calc(840px - ${headerHeight}px);
        }

        ${theme.breakpoints.only("sm")} {
            min-height: calc(944px - ${headerHeight}px);
        }

        ${theme.breakpoints.down("xs")} {
            min-height: calc(720px - ${headerHeight}px);
        }

        ${theme.breakpoints.down("md")} {
            flex-direction: column;
        }
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
};

export default PageSection;