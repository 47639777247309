import React, { ReactNode, useRef } from 'react';
import { css, Theme, useTheme } from "@emotion/react";
import useScrollbarWidth from "core/hooks/useScrollbarWidth";

type Props = {
    children: ReactNode,
    borderColour?: string,
}

const SummaryItemRowsContainer = ({ children, borderColour = 'white' }: Props) => {
    const theme: Theme = useTheme();
    const containerRef = useRef<HTMLDivElement>(null);
    const scrollbarWidth = useScrollbarWidth(containerRef.current);

    const styles = css`
        display: flex;
        flex-direction: column;
        margin-top: 38px;
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        border-bottom: 1px solid ${borderColour};

        ${!!scrollbarWidth && css`
            padding-right: 7px;
        `}
        
        ${theme.mixins.forceScrollbar}
    `;

    return (
        <div css={styles} ref={containerRef}>
            {children}
        </div>
    )
};

export default SummaryItemRowsContainer;