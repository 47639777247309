import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import { useAppState } from "core/contexts/AppContext";
import { getPrice } from "core/includes/formatters";
import { taxLabelWithTaxable } from "core/includes/finance";

type Props = {
    price: number,
    isTaxable: boolean,
    width?: number,
};

const ProductItemCartPopupPrice: React.FC<Props> = ({ price, isTaxable, width }) => {
    const [appState] = useAppState();
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px;
        width: ${width ? width + 'px' : '100%'};

        ${theme.breakpoints.down('sm')} {
            justify-content: start;
            margin: 0 26px;
        }
    `;

    const priceStyle = css`
        font-size: 23px;
        font-weight: ${theme.fonts.weights.bold};

        ${theme.breakpoints.down('sm')} {
            font-size: 17px;
        }
    `;

    const gstStyle = css`
        color: ${theme.colours.shipGray};
        
        ${theme.breakpoints.only('md')} {
            font-size: 12px;
        }

        ${theme.breakpoints.down('sm')} {
            padding: 2px 0 0 5px;
            font-size: 10px;
        }

        ${theme.breakpoints.down('xs')} {
            font-size: 8px;
        }
    `;

    return (
        <div css={style}>
            <div css={priceStyle}>{appState.ipCountry.currency}{getPrice(price, true)}</div>
            <div css={gstStyle}>({taxLabelWithTaxable(isTaxable, appState.ipCountry.taxLabel)})</div>
        </div>
    );
}

export default ProductItemCartPopupPrice;