import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
    flexDirection?: string,
    justifyContent?: string,
    minHeight?: number,
    isSmallBreakpoint: boolean,
};

const ItemBoxRow: React.FC<Props> = (
    {
        children,
        flexDirection = '',
        justifyContent = 'space-between',
        minHeight = 0,
        isSmallBreakpoint,
    }
) => {
    const theme: Theme = useTheme();

    const style = css`
        ${theme.breakpoints.down('sm')} {
            min-height: ${minHeight}px;
            display: flex;
            flex-direction: ${flexDirection};
            justify-content: ${justifyContent};
        }

        ${theme.breakpoints.down('xs')} {
            flex-shrink: 0;
        }
    `;

    return isSmallBreakpoint ? (
        <div css={style}>
            {children}
        </div>
    ) : (
        <>
            {children}
        </>
    );
};

export default ItemBoxRow;