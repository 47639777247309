import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import { getPrice } from "core/includes/formatters";
import ContentCombine from "core/Components/ContentCombine";
import { useAppState } from "core/contexts/AppContext";

type Props = {
    title: string,
    price: number,
    isTotal?: boolean,
};

const TitleAndPrice: React.FC<Props> = ({ title, price, isTotal = false }) => {
    const [appState] = useAppState();
    const theme: Theme = useTheme();

    const style = css`
        align-items: end;
        width: 100%;
    `;

    const titlesDivStyle = css`
        display: flex;
        justify-content: space-between;

        ${isTotal && css`
            font-weight: ${theme.fonts.weights.bold};
        `}
    `;

    return (
        <div css={style}>
            <div css={titlesDivStyle}>
                <ContentCombine fontSizeType={'18'}>{title}</ContentCombine>
                <ContentCombine fontSizeType={'18'}>{appState.ipCountry.currency}{getPrice(price)}</ContentCombine>
            </div>
        </div>
    );
};

export default TitleAndPrice;