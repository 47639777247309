import React, { FormEvent, useCallback, useMemo, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { HiPlus as PlusIcon, HiMinus as MinusIcon } from "react-icons/hi";
import OutsideClickHandler from "react-outside-click-handler";
import { isNumber } from "lodash";

import CircleButton from "core/Components/Buttons/CircleButton";

import QuantityContainer from "pages/Components/CartAndCheckout/components/Item/QuantityContainer";
import useBreakpoint from "core/hooks/useBreakpoint";
import RoundQuantityInput from "core/Components/Buttons/QuantityButton/RoundQuantityInput";
import { MAXIMUM_PRODUCT_QUANTITY, MINIMUM_PRODUCT_QUANTITY } from "pages/Components/ProductListPage/includes/includes";

type Props = {
    id: number,
    productInventory?: number,
    quantity: number,
    quantityBoxWidth: number,
    quantityBoxHeight: number,
    circleButtonSize: number,
    updateItemQuantity: (id: number, quantity: number) => void,
};

const QuantityWithPlusMinusButton: React.FC<Props> = (
    {
        id,
        productInventory = MAXIMUM_PRODUCT_QUANTITY,
        quantity,
        quantityBoxWidth,
        quantityBoxHeight,
        circleButtonSize,
        updateItemQuantity,
    }
) => {
    const theme = useTheme();
    const [itemQuantity, setItemQuantity] = useState(quantity);
    const itemQuantityHolder = useRef({
        quantity: quantity || MINIMUM_PRODUCT_QUANTITY,
        lastValidQuantity: quantity || MINIMUM_PRODUCT_QUANTITY,
    });
    const { breakpoint } = useBreakpoint();
    const timeoutHolder = useRef<any>(null);

    const availableMaxQuantity = useMemo(() => (
        Math.min(productInventory, MAXIMUM_PRODUCT_QUANTITY)
    ), [productInventory]);

    const [minLimit, maxLimit] = useMemo(() => [
        itemQuantity <= MINIMUM_PRODUCT_QUANTITY,
        itemQuantity >= (availableMaxQuantity ?? 0)
    ], [itemQuantity, availableMaxQuantity]);

    // Input width: (Box width) - (2 Circle button size) - (Left & right padding of the box)
    const inputBoxWidth = breakpoint === 'xl' ?
        36 :
        quantityBoxWidth - (circleButtonSize * 2) - (quantityBoxHeight - circleButtonSize);

    const setItemQuantityHolder = (quantity: number) => {
        itemQuantityHolder.current = {
            quantity,
            lastValidQuantity: isNumber(quantity) ? quantity : itemQuantityHolder.current.lastValidQuantity,
        }
    }

    const onPlusClickHandler = () => {
        if (!maxLimit) {
            const targetQuantity = (itemQuantityHolder.current.quantity + 1) || 1;
            updateItemQuantity(id, targetQuantity);
            setItemQuantity(targetQuantity);
            setItemQuantityHolder(targetQuantity);
        }
    }

    const onMinusClickHandler = () => {
        if (!minLimit) {
            const targetQuantity = (itemQuantityHolder.current.quantity - 1) || 1;
            updateItemQuantity(id, targetQuantity);
            setItemQuantity(targetQuantity);
            setItemQuantityHolder(targetQuantity);
        }
    }

    const onChangeHandler = useCallback((e: FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        if (timeoutHolder.current) clearTimeout(timeoutHolder.current);
        if (parseInt(value) !== itemQuantityHolder.current.quantity) {
            setItemQuantity(Math.min(parseInt(value), (availableMaxQuantity ?? 0)));
            setItemQuantityHolder(Math.min(parseInt(value), (availableMaxQuantity ?? 0)));

            timeoutHolder.current = setTimeout(() => {
                updateItemQuantity(id, itemQuantityHolder.current.quantity)
            }, 500);
        }
    }, [availableMaxQuantity, updateItemQuantity, id]);

    const onResetValue = useCallback(() => {
        if (!itemQuantity) {
            const q = quantity || itemQuantityHolder.current.lastValidQuantity;
            setItemQuantity(q);
            setItemQuantityHolder(q);
        }
    }, [itemQuantity, quantity]);

    return (
        <OutsideClickHandler onOutsideClick={onResetValue}>
            <QuantityContainer
                quantityBoxWidth={quantityBoxWidth}
                quantityBoxHeight={quantityBoxHeight}
                circleButtonSize={circleButtonSize}
            >
                <CircleButton
                    size={circleButtonSize}
                    svgSize={circleButtonSize - 10}
                    onClick={onMinusClickHandler}
                    roundColour={theme.colours.grey[minLimit ? 90 : 200]}
                    svgColour={minLimit ? theme.colours.alto2 : theme.colours.doveGray}
                    noHoverEffect={minLimit}
                    disabled={minLimit}
                    {...!minLimit && {
                        roundHoverColour: theme.colours.malibu,
                        svgHoverColour: theme.colours.white,
                    }}
                >
                    <MinusIcon />
                </CircleButton>
                <RoundQuantityInput
                    width={inputBoxWidth}
                    quantity={itemQuantity}
                    textColour={theme.colours.doveGray}
                    onChange={onChangeHandler}
                    onResetValue={onResetValue}
                    {...!itemQuantity && {
                        placeholder: ''
                    }}
                />
                <CircleButton
                    size={circleButtonSize}
                    svgSize={circleButtonSize - 10}
                    onClick={onPlusClickHandler}
                    roundColour={theme.colours.grey[maxLimit ? 90 : 200]}
                    svgColour={maxLimit ? theme.colours.alto2 : theme.colours.doveGray}
                    noHoverEffect={maxLimit}
                    disabled={maxLimit}
                    {...!maxLimit && {
                        roundHoverColour: theme.colours.malibu,
                        svgHoverColour: theme.colours.white,
                    }}
                >
                    <PlusIcon />
                </CircleButton>
            </QuantityContainer>
        </OutsideClickHandler>
    );
}

export default QuantityWithPlusMinusButton;