import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    itemCount: number
};

const CartItemCount: React.FC<Props> = ({ itemCount }) => {
    const theme: Theme = useTheme();

    const style = css`
        position: absolute;
        top: -7px;
        left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 18px;
        padding: 0 4px;
        height: 18px;
        border-radius: 9px;
        color: white;
        background-color: ${theme.colours.pizazz};
        z-index: 2;

        ${theme.breakpoints.down('lg')} {
            top: -2px;
        }

        ${theme.breakpoints.down('md')} {
            top: -7px;
        }
    `;

    return itemCount > 0 ? (
        <div css={style}>{itemCount > 99 ? '99+' : itemCount}</div>
    ) : null;
};

export default CartItemCount;