import React from "react";
import theme from "theme/index";

type Props = {
    className?: string,
    colour?: string,
    backgroundColour?: string,
};

const CrossWithCircle: React.FC<Props> = (
    {
        className = "",
        colour = "white",
        backgroundColour = theme.colours.silverChalice,
    }
) => (
    <div className={className}>
        <svg viewBox="0 0 1080 1080">
            <path
                d="m514.44,60.34C246.47,60.34,29.23,277.57,29.23,545.55s217.23,485.21,485.21,485.21,485.21-217.23,485.21-485.21S782.41,60.34,514.44,60.34Z"
                fill={`${backgroundColour}`}
            />
            <path
                d="m731.52,692.58c19.34,19.34,19.34,50.69,0,70.03-19.34,19.34-50.69,19.34-70.03,0l.02.02-147.05-147.05-147.05,147.05c-19.34,19.34-50.69,19.34-70.03,0s-19.34-50.69,0-70.03l147.05-147.05-147.06-147.06c-19.34-19.34-19.34-50.69,0-70.03,19.34-19.34,50.69-19.34,70.03,0l147.05,147.04,147.05-147.04c19.34-19.34,50.69-19.34,70.03,0,19.34,19.34,19.34,50.69,0,70.03l-147.05,147.05,147.05,147.05Z"
                fill={`${colour}`}
            />
        </svg>
    </div>
);

export default CrossWithCircle;