import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

const CartPopupHeaderTitle: React.FC = () => {
    const theme: Theme = useTheme();

    const style = css`
        margin-left: 12px;
        font-size: 16px;
        font-weight: ${theme.fonts.weights.bold};
        white-space: nowrap;

        ${theme.breakpoints.only("xl")} {
            margin-left: 10px;
            font-size: 15px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-left: 19px;
            font-size: 14px;
        }
    `;

    return (
        <div css={style}>
            Recently added items
        </div>
    );
};

export default CartPopupHeaderTitle;