import React, { ChangeEvent } from "react";
import { css, Theme, useTheme } from "@emotion/react";

import { NOOP } from "core/constants";
import RoundQuantityButton from "core/Components/Buttons/QuantityButton/RoundQuantityButton";

type Props = {
    inventoryPolicyIsContinue?: boolean,
    inventoryItemTracked?: boolean,
    inventoryQuantity?: number,
    quantity: number,
    maxQuantity: number,
    buttonSize?: number,
    useMarginTop?: boolean,
    onPlusIconClick: (e: React.MouseEvent) => void,
    onMinusIconClick: (e: React.MouseEvent) => void,
    onCustomQuantity: (quantity: React.ChangeEvent<HTMLInputElement>) => void,
    addToCartHandler?: () => void,
};

const QuantityPlusMinusButton: React.FC<Props> = (
    {
        quantity,
        maxQuantity,
        buttonSize = undefined,
        useMarginTop = true,
        onPlusIconClick,
        onMinusIconClick,
        onCustomQuantity,
        addToCartHandler = NOOP,
    }
) => {
    const theme: Theme = useTheme();

    const style = css`
        ${useMarginTop && theme.breakpoints.up('md')} {
            margin-top: 12px;
        }
    `;

    return (
        <div css={style}>
            <RoundQuantityButton
                quantity={quantity}
                maxQuantity={maxQuantity}
                minQuantity={1}
                buttonSize={buttonSize}
                plusClickHandler={onPlusIconClick}
                minusClickHandler={onMinusIconClick}
                onAddToCart={addToCartHandler}
                onChange={onCustomQuantity}
            />
        </div>
    );
};

export default QuantityPlusMinusButton;