import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    currency: string,
};

const CartPopupHeaderCurrency: React.FC<Props> = ({ currency }) => {
    const theme: Theme = useTheme();

    const style = css`
        width: 100%;
        font-size: 10px;
        text-align: right;
        color: ${theme.colours.shipGray};
    `;

    return (
        <div css={style}>
            Price in {currency}
        </div>
    );
};

export default CartPopupHeaderCurrency;