import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import { useAppState } from "core/contexts/AppContext";
import { getPrice } from "core/includes/formatters";
import { taxLabelWithTaxable } from "core/includes/finance";

type Props = {
    quantity: number,
    price: number,
    isTaxable: boolean,
    discountPrice: number,
    itemBoxPriceWidth: number,
    itemBoxPriceRightMargin: number,
};

const  ItemPriceBox: React.FC<Props> = (
    {
        quantity,
        price,
        isTaxable,
        discountPrice,
        itemBoxPriceWidth,
        itemBoxPriceRightMargin,
    }
) => {
    const [appState] = useAppState();
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: end;
        padding-right: ${itemBoxPriceRightMargin}px;
        width: ${itemBoxPriceWidth}px;
        font-size: 20px;
        line-height: 20px;

        ${theme.breakpoints.only('xl')} {
            font-size: 17px;
        }

        ${theme.breakpoints.only('lg')} {
            font-size: 14px;
            line-height: 18px;
        }

        ${theme.breakpoints.only('md')} {
            font-size: 17px;
            line-height: 22px;
        }

        ${theme.breakpoints.only('sm')} {
            font-size: 16px;
            line-height: 20px;
        }

        ${theme.breakpoints.down('xs')} {
            flex-direction: row;
            justify-content: end;
            width: 100%;
            font-size: 20px;
            line-height: 20px;
        }
    `;

    const compareAtPriceStyle = css`
        font-size: 14px;
        line-height: 17px;
        color: ${theme.colours.gray};
        text-decoration: line-through ${theme.colours.red1};

        ${theme.breakpoints.only('lg')} {
            font-size: 13px;
            line-height: 16px;
        }

        ${theme.breakpoints.only('sm')} {
            font-size: 12px;
            line-height: 14px;
        }

        ${theme.breakpoints.down('xs')} {
            padding: 0 12px 21px 0;
        }
    `;

    const priceStyle = css`
        ${theme.breakpoints.only('sm')} {
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.5px;
        }
    `;

    const priceAndGstStyle = css`
        text-align: right;
    `;

    const gstStyle = css`
        font-size: 10px;
    `;

    const promoteTextStyle = css`
        position: absolute;
        right: ${itemBoxPriceRightMargin}px;
        bottom: 10px;
        font-size: 10px;
        line-height: 13px;
        font-style: italic;
        color: ${theme.colours.red1};

        ${theme.breakpoints.only('xl')} {
            right: 15px;
        }

        ${theme.breakpoints.only('lg')} {
            right: 6px;
        }

        ${theme.breakpoints.only('md')} {
            right: 15px;
        }

        ${theme.breakpoints.only('sm')} {
            right: 8px;
        }

        ${theme.breakpoints.down('xs')} {
            right: 90px;
        }
    `;

    return (
        <div css={style}>
            {discountPrice > 0 && (
                <div css={compareAtPriceStyle}>${getPrice(price * quantity)}</div>
            )}
            <div css={priceAndGstStyle}>
                <div css={priceStyle}>{appState.ipCountry.currency}{getPrice((price * quantity) - discountPrice)}</div>
                <div css={gstStyle}>{taxLabelWithTaxable(isTaxable, appState.ipCountry.taxLabel)}</div>
            </div>
            {discountPrice > 0 && (
                <div css={promoteTextStyle}>Discount applied</div>
            )}
        </div>
    );
};

export default ItemPriceBox;