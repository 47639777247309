import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import Bin from "core/Components/Icons/Bin";
import { useCartItemsContext } from "core/contexts/CartItemsContext";

type Props = {
    lineItemId: number,
    removeItem: (lineItemId: number) => void,
};

const CartItemRemoveButton: React.FC<Props> = ({ lineItemId, removeItem }) => {
    const theme: Theme = useTheme();
    const { setConfirmationPopup } = useCartItemsContext();

    const onClickHandler = () => {
        removeItem(lineItemId);
    }

    const style = css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33px;
        height: 80px;
        background-color: white;
        border: 1px solid white;
        border-radius: 4px;
        cursor: pointer;

        ${theme.breakpoints.down('xl')} {
            width: 27px;
        }

        &:hover {
            border: 1px solid red;
        }
    `;

    const binStyle = css`
        width: 11px;
        height: 15px;
    `;

    return (
        <div
            css={style}
            onClick={() => setConfirmationPopup('remove-item-from-panel', true, { eventFunction: onClickHandler })}
        >
            <Bin css={binStyle} />
        </div>
    );
};

export default CartItemRemoveButton;