import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";

import EmptyCart from "core/Components/CartPopup/EmptyCart";
import CartPopupCloseBar from "core/Components/CartPopup/CartPopupCloseBar";
import CartPopupContainer from "core/Components/CartPopup/CartPopupContainer";
import CartPopupContent from "core/Components/CartPopup/CartPopupContent";
import CartPopupSection from "core/Components/CartPopup/CartPopupSection";
import LayerToBlockMain from "core/Components/CartPopup/LayerToBlockMain";
// Footer folder
import CartPopupFooter from "core/Components/CartPopup/Footer/CartPopupFooter";
import CartPopupGoToCartButton from "core/Components/CartPopup/Footer/CartPopupGoToCartButton";
import CartPopupSelectedItemCount from "core/Components/CartPopup/Footer/CartPopupSelectedItemCount";
import CartPopupSubTotal from "core/Components/CartPopup/Footer/CartPopupSubTotal";
// Header folder
import CartPopupHeader from "core/Components/CartPopup/Header/CartPopupHeader";
import CartPopupHeaderCurrency from "core/Components/CartPopup/Header/CartPopupHeaderCurrency";
import CartPopupHeaderIcon from "core/Components/CartPopup/Header/CartPopupHeaderIcon";
import CartPopupHeaderTitle from "core/Components/CartPopup/Header/CartPopupHeaderTitle";
// Main folder
import CartImageAndTitleContainer from "core/Components/CartPopup/Main/CartImageAndTitleContainer";
import CartPopupItemContainer from "core/Components/CartPopup/Main/CartItemContainer";
import CartItemContent from "core/Components/CartPopup/Main/CartItemContent";
import CartItemImage from "core/Components/CartPopup/Main/CartItemImage";
import CartItemQuantityAndPrice from "core/Components/CartPopup/Main/CartItemQuantityAndPrice";
import CartItemRemoveButton from "core/Components/CartPopup/Main/CartItemRemoveButton";
import CartItemTitle from "core/Components/CartPopup/Main/CartItemTitle";
import CartPopupMain from "core/Components/CartPopup/Main/CartPopupMain";

import { useAppState } from "core/contexts/AppContext";
import useBreakpoint from "core/hooks/useBreakpoint";
import useHeaderHeight from "core/hooks/useHeaderHeight";
import { getCountryCodeAndCurrencyCode } from "core/includes/countries";
import document from "core/includes/document";
import { isBetween, isOnly, isUp } from "theme/breakpoints";
import useCart from "pages/Components/CartPage/hooks/useCart";

type Props = {
    showBreadcrumb: boolean,
    hideHeaderCountry: boolean,
};

const CartPopup: React.FC<Props> = ({ showBreadcrumb, hideHeaderCountry }) => {
    const navigate = useNavigate();
    const [appState, setAppState] = useAppState();
    const { updateCart } = useCart();

    const headerHeight = useHeaderHeight({ showBreadcrumb, hideHeaderCountry });
    const { breakpoint } = useBreakpoint();
    const boxWidth = useMemo(() => {
        switch (true) {
            case isUp(breakpoint, 'xxl'):
                return 363;
            case isOnly(breakpoint, 'xl'):
                return 342;
            case isBetween(breakpoint, 'sm', 'lg'):
                return 310;
            default:
                return 301;
        }
    }, [breakpoint]);

    const subtotalPrice = appState.cart?.subtotalAmount ?? 0;
    const totalTax = (appState.cart?.totalAmount ?? 0) - subtotalPrice;
    const { currency } = appState.ipCountry?.currency || getCountryCodeAndCurrencyCode(appState.ipCountry?.code);
    const cartItems = appState.cart?.products || [];
    const hasItems = (cartItems?.length ?? 0) > 0;

    // Move to the detail page of the selected item
    const linkTo = (id: string) => {
        navigate(id);
        setAppState({
            isOpenCartPopup: false
        });
    };

    useEffect(() => {
        if (appState.isOpenCartPopup) {
            disableBodyScroll(document.body);

            return () => {
                clearAllBodyScrollLocks();
            }
        }
    }, [appState.isOpenCartPopup]);

    return (
        <>
            <CartPopupSection headerHeight={headerHeight}>
                <CartPopupContainer>
                    <CartPopupCloseBar headerHeight={headerHeight} />
                    <CartPopupContent headerHeight={headerHeight}>
                        <CartPopupHeader width={boxWidth}>
                            <CartPopupHeaderIcon />
                            <CartPopupHeaderTitle />
                            <CartPopupHeaderCurrency currency={currency} />
                        </CartPopupHeader>
                        <CartPopupMain hasItems={hasItems}>
                            {hasItems ? cartItems.map((product: CartProduct) => {
                                return (
                                    <CartPopupItemContainer key={product.id} width={boxWidth}>
                                        <CartItemContent>
                                            <CartImageAndTitleContainer>
                                                <CartItemImage
                                                    id={JSON.stringify(product.id)}
                                                    url={product.image?.URL || ""}
                                                    linkTo={linkTo}
                                                />
                                                <CartItemTitle
                                                    id={JSON.stringify(product.id)}
                                                    linkTo={linkTo}
                                                    tippyContent={product.title}
                                                >
                                                    {product.title}
                                                </CartItemTitle>
                                            </CartImageAndTitleContainer>
                                            <CartItemQuantityAndPrice
                                                quantity={product.quantity}
                                                price={product.unitPrice}
                                                isTaxable
                                            />
                                        </CartItemContent>
                                        <CartItemRemoveButton
                                            lineItemId={product.id}
                                            removeItem={updateCart}
                                        />
                                    </CartPopupItemContainer>
                                );
                            }) : (
                                <EmptyCart />
                            )}
                        </CartPopupMain>
                        <CartPopupFooter width={boxWidth}>
                            <CartPopupSelectedItemCount
                                itemCount={cartItems.length}
                            />
                            <CartPopupSubTotal subtotalPrice={subtotalPrice} totalTax={totalTax} />
                            <CartPopupGoToCartButton hasItems={hasItems} />
                        </CartPopupFooter>
                    </CartPopupContent>
                </CartPopupContainer>
            </CartPopupSection>
            <LayerToBlockMain showLayer={appState.isOpenCartPopup} />
        </>
    );
};

export default CartPopup;