import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import CrossWithCircle from "core/Components/Icons/CrossWithCircle";
import { useCartItemsContext } from "core/contexts/CartItemsContext";

const ConfirmationCloseButtonIcon: React.FC = () => {
    const theme: Theme = useTheme();
    const { setConfirmationPopup } = useCartItemsContext();

    const onClickCloseButtonHandler = () => {
        setConfirmationPopup('', false);
    }

    const iconContainerStyle = css`
        position: absolute;
        top: 11px;
        right: 13px;

        ${theme.breakpoints.up('3xl')} {
            top: 13px;
            right: 16px;
        }

        ${theme.breakpoints.only('md')} {
            top: 13px;
            right: 16px;
        }

        ${theme.breakpoints.only('sm')} {
            top: 10px;
            right: 11px;
        }
    `;

    const iconStyle = css`
        width: 35px;
        height: 35px;
        cursor: pointer;

        ${theme.breakpoints.up('3xl')} {
            width: 43px;
            height: 43px;
        }

        ${theme.breakpoints.only('md')} {
            width: 43px;
            height: 43px;
        }

        ${theme.breakpoints.only('sm')} {
            width: 27px;
            height: 27px;
        }
    `;

    return (
        <div css={iconContainerStyle} onClick={onClickCloseButtonHandler}>
            <CrossWithCircle backgroundColour={theme.colours.gray} css={iconStyle} />
        </div>
    );
};

export default ConfirmationCloseButtonIcon;