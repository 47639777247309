import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import Cart from "core/Components/Icons/Cart";
import Time from "core/Components/Icons/Time";

const CartPopupHeaderIcon: React.FC = () => {
    const theme: Theme = useTheme();

    const style = css`
        position: relative;
        display: flex;
        align-items: end;
        margin-left: 15px;
        height: 32px;

        ${theme.breakpoints.down("xs")} {
            height: 26px;
        }
    `;

    const timeIconStyle = css`
        position: absolute;
        top: 0;
        width: 27px;
        height: 27px;

        ${theme.breakpoints.down("xs")} {
            width: 21px;
            height: 21px;
        }
    `;

    const cartIconStyle = css`
        margin-left: 9px;
        width: 26px;
        height: 26px;
        
        ${theme.breakpoints.down("xs")} {
            margin-left: 7px;
            width: 20px;
            height: 20px;
        }
    `;

    return (
        <div css={style}>
            <Time css={timeIconStyle} />
            <Cart css={cartIconStyle} />
        </div>
    );
};

export default CartPopupHeaderIcon;