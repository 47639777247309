import React from "react";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

import { useAppState } from "core/contexts/AppContext";
import useBreakpoint from "core/hooks/useBreakpoint";

import ItemBox from "pages/Components/CartAndCheckout/components/Item/ItemBox";
import ItemBoxContainer from "pages/Components/CartAndCheckout/components/Item/ItemBoxContainer";
import ItemBoxRow from "pages/Components/CartAndCheckout/components/Item/ItemBoxRow";
import ItemContainer from "pages/Components/CartAndCheckout/components/Item/ItemContainer";
import ItemDescription from "pages/Components/CartAndCheckout/components/Item/ItemDescription";
import ItemDescriptionContainer from "pages/Components/CartAndCheckout/components/Item/ItemDescriptionContainer";
import ItemImage from "pages/Components/CartAndCheckout/components/Item/ItemImage";
import ItemGroupEmptyMessage from "pages/Components/CartAndCheckout/components/Item/ItemGroupEmptyMessage";
import ItemHeaderContainer from "pages/Components/CartAndCheckout/components/Item/ItemHeaderContainer";
import ItemPriceBox from "pages/Components/CartAndCheckout/components/Item/ItemPriceBox";
import ItemQuantityBox from "pages/Components/CartAndCheckout/components/Item/ItemQuantityBox";
import ItemRemoveButton from "pages/Components/CartAndCheckout/components/Item/ItemRemoveButton";
import RemoveItemIcon from "pages/Components/CartAndCheckout/components/Item/RemoveItemIcon";
import { isDown } from "theme/breakpoints";
import useCart from "pages/Components/CartPage/hooks/useCart";

const POP_OUT_OFFSET = 11;

type Props = {
    groupStatus?: 'empty' | 'both' | 'event' | 'product',
    itemGroupBoxWidth: number,
    itemBoxWidth: number,
    itemBoxImageWidth: number,
    itemBoxImageHeight: number,
    itemBoxDescriptionWidth: number,
    itemBoxDescriptionLeftRightMargin: number,
    itemBoxPriceWidth: number,
    itemBoxPriceRightMargin: number,
    quantityBoxContainerWidth: number,
    quantityBoxWidth: number,
    quantityBoxHeight: number,
    plusMinusCircleButtonSize: number,
    type: string,
    items: CartProduct[],
    setValidationError: (discountCode: string, applyTarget: string, errorMessage: string) => void,
    isSmallBreakpoint: boolean,
};

const ItemGroupContainer: React.FC<Props> = (
    {
        type = "",
        items = [],
        groupStatus = undefined,
        itemGroupBoxWidth,
        itemBoxWidth,
        itemBoxImageWidth,
        itemBoxImageHeight,
        itemBoxDescriptionWidth,
        itemBoxDescriptionLeftRightMargin,
        itemBoxPriceWidth,
        itemBoxPriceRightMargin,
        quantityBoxContainerWidth,
        quantityBoxWidth,
        quantityBoxHeight,
        plusMinusCircleButtonSize,
        setValidationError,
        isSmallBreakpoint,
    }
) => {
    const { breakpoint } = useBreakpoint();
    const navigate = useNavigate();
    const [, setAppState] = useAppState();

    const {
        updateCart,
    } = useCart();

    // Move to the detail page of the selected item
    const linkTo = (id: string) => {
        navigate((id.startsWith('/') ? '' : '/') + id); // For Product and Event
        setAppState((prev: any) => ({
            cart: {
                ...prev.cart,
                countryCode: prev.cart.countryCode,
                cart: prev.cart.cart,
                validateDiscountCode: prev.cart.validateDiscountCode,
                isOpenCartPopup: false
            }
        }));
    };

    const style = css`
        margin: 25px 0;
    `;

    const descriptionSectionContainerStyle = css`
        display: flex;
        justify-content: center;
        flex-direction: column;
    `;

    const itemBoxAndAttendeesStyle = ({ noMarginBottom = false, areAttendeesExpanded = false } = {}) => {
        const getMarginBottom = () => {
            switch (true) {
                case noMarginBottom:
                    return 0;
                case isDown(breakpoint, 'xs'):
                    return 28 - (areAttendeesExpanded ? POP_OUT_OFFSET : 0);
                default:
                    return 22 - (areAttendeesExpanded ? POP_OUT_OFFSET : 0);
            }
        }

        return css`
            display: flex;
            flex-direction: column;
            margin-bottom: ${getMarginBottom()}px;
        `;
    };

    const xsDescriptionStyle = css`
        margin-top: 6px;
    `;

    return (
        <div css={style}>
            {/* Group name (event vs product), Promotion input, Titles*/}
            <ItemHeaderContainer
                type={type}
                items={items}
                groupStatus={groupStatus}
                itemGroupBoxWidth={itemGroupBoxWidth}
                itemBoxWidth={itemBoxWidth}
                itemBoxImageWidth={itemBoxImageWidth}
                itemBoxDescriptionWidth={itemBoxDescriptionWidth}
                itemBoxDescriptionLeftRightMargin={itemBoxDescriptionLeftRightMargin}
                quantityBoxContainerWidth={quantityBoxContainerWidth}
                itemBoxPriceWidth={itemBoxPriceWidth}
                setValidationError={setValidationError}
                isEmptyItems={!items.length}
            />

            {(groupStatus === 'both' || groupStatus === type) ? (
                <ItemContainer noGap={type === 'event'}>
                    {items.map((item: CartProduct, index: number) => {
                        const urlSegment = item.urlSegment ?? "";

                        // Move to the detail page
                        const onClick = () => {
                            linkTo(urlSegment);
                        };

                        return item ? (
                            <ItemBoxContainer key={`item-box-${index}`} itemBoxImageHeight={itemBoxImageHeight}>
                                <div
                                    css={itemBoxAndAttendeesStyle({
                                        noMarginBottom: (index === (items?.length ?? 0) - 1) || type === 'product',
                                    })}
                                >
                                    <ItemBox itemBoxWidth={itemBoxWidth}>
                                        <ItemBoxRow
                                            isSmallBreakpoint={isSmallBreakpoint}
                                            {...isDown(breakpoint, 'xs') && {
                                                justifyContent: 'flex-start',
                                            }}
                                        >
                                            <ItemImage
                                                storageItem={item}
                                                imageUrl={item.image?.URL || null}
                                                itemBoxImageWidth={itemBoxImageWidth}
                                                itemBoxImageHeight={itemBoxImageHeight}
                                                linkTo={linkTo}
                                            />
                                            <ItemBoxRow
                                                flexDirection={'column'}
                                                minHeight={itemBoxImageHeight}
                                                isSmallBreakpoint={isSmallBreakpoint}
                                                {...isDown(breakpoint, 'xs') && {
                                                    justifyContent: 'flex-start'
                                                }}
                                            >
                                                <div css={descriptionSectionContainerStyle}>
                                                    <ItemDescriptionContainer
                                                        title={item.title}
                                                        description={item.description}
                                                        itemBoxDescriptionWidth={itemBoxDescriptionWidth}
                                                        itemBoxDescriptionLeftRightMargin={itemBoxDescriptionLeftRightMargin}
                                                        onTitleClick={onClick}
                                                        {...type === 'product' && { truncateLineLimit: 3 }}
                                                    />
                                                </div>
                                                <ItemQuantityBox
                                                    item={item}
                                                    type={type}
                                                    quantityBoxContainerWidth={quantityBoxContainerWidth}
                                                    quantityBoxWidth={quantityBoxWidth}
                                                    quantityBoxHeight={quantityBoxHeight}
                                                    plusMinusCircleButtonSize={plusMinusCircleButtonSize}
                                                    updateItemQuantity={updateCart}
                                                />
                                            </ItemBoxRow>
                                        </ItemBoxRow>
                                        <ItemDescription
                                            css={xsDescriptionStyle}
                                            description={item.description}
                                            hideTargetBreakpointFrom={'sm'}
                                            {...type === 'product' && { truncateLineLimit: 3 }}
                                        />
                                        <ItemPriceBox
                                            quantity={item.quantity}
                                            price={item.unitPrice}
                                            discountPrice={item.discountedPrice}
                                            itemBoxPriceWidth={itemBoxPriceWidth}
                                            itemBoxPriceRightMargin={itemBoxPriceRightMargin}
                                            isTaxable
                                        />
                                        <RemoveItemIcon
                                            lineItemId={item.id}
                                            removeItem={updateCart}
                                            itemBoxWidth={itemBoxWidth}
                                        />
                                    </ItemBox>
                                </div>
                                <ItemRemoveButton
                                    lineItemId={item.id}
                                    itemGroupBoxWidth={itemGroupBoxWidth}
                                    itemBoxWidth={itemBoxWidth}
                                    removeItem={updateCart}
                                />
                            </ItemBoxContainer>
                        ) : null;
                    })}
                </ItemContainer>
            ) : (
                <ItemGroupEmptyMessage type={type}/>
            )}
        </div>
    );
};

export default ItemGroupContainer;