import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import QuantityWithLink from "pages/Components/CartAndCheckout/components/Item/QuantityWithLink";
import QuantityWithPlusMinusButton from "pages/Components/CartAndCheckout/components/Item/QuantityWithPlusMinusButton";
import { NOOP } from "core/constants";

type Props = {
    to?: string,
    type: string,
    quantityBoxContainerWidth: number,
    quantityBoxWidth: number,
    quantityBoxHeight: number,
    plusMinusCircleButtonSize: number,
    updateItemQuantity: (id: number, quantity: number) => void,
    onClickEdit?: (index?: number) => void,
    item: CartProduct,
};

const ItemQuantityBox: React.FC<Props> = (
    {
        to,
        type,
        quantityBoxContainerWidth,
        quantityBoxWidth,
        quantityBoxHeight,
        plusMinusCircleButtonSize,
        updateItemQuantity,
        onClickEdit = NOOP,
        item = null,
    }
) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${quantityBoxContainerWidth}px;

        ${theme.breakpoints.down('xs')} {
            margin-top: 10px;
        }
    `;

    if (!item) {
        return null;
    }

    return (
        <div css={style}>
            {type === 'event' ? (
                <QuantityWithLink
                    quantity={item.quantity}
                    quantityBoxWidth={quantityBoxWidth}
                    to={to}
                    onClick={onClickEdit}
                />
            ) : (
                <QuantityWithPlusMinusButton
                    id={item.id}
                    productInventory={item.totalQuantity}
                    quantity={item.quantity}
                    quantityBoxWidth={quantityBoxWidth}
                    quantityBoxHeight={quantityBoxHeight}
                    circleButtonSize={plusMinusCircleButtonSize}
                    updateItemQuantity={updateItemQuantity}
                />
            )}
        </div>
    );
};

export default ItemQuantityBox;