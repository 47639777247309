import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
};

const CartItemContent: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 14px;
        width: 310px;

        ${theme.breakpoints.only("xl")} {
            margin-right: 10px;
            width: 293px;
        }

        ${theme.breakpoints.between("sm", "lg")} {
            margin-right: 10px;
            width: 274px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-right: 5px;
            width: 260px;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default CartItemContent;