import React, { useContext, createContext, useCallback, useState } from 'react';

const CartItemsContext = createContext<any>(null);

type Props = {
    children: React.ReactNode,
}

export const CartItemsContextProvider: React.FC<Props> = ({ children }) => {
    const [confirmationState, setConfirmationState] = useState({
        popupType: '',
        showLayer: false,
        eventFunction: undefined,
    });

    const setConfirmationPopup = useCallback((
        popupType: string,
        showLayer: boolean,
        { eventFunction = undefined } = {},
    ) => {
        setConfirmationState({
            popupType,
            showLayer,
            eventFunction,
        });
    }, [setConfirmationState]);

    return (
        <CartItemsContext.Provider value={{ confirmationState, setConfirmationPopup }}>
            {children}
        </CartItemsContext.Provider>
    );
}

export const useCartItemsContext = () => useContext(CartItemsContext);