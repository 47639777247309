import React from "react";
import { css, Theme, useTheme } from "@emotion/react";
import TruncateWithTippy from "core/Components/TruncateWithTippy";

type Props = {
    description?: string,
    truncateLineLimit?: number,
    hideTargetBreakpointFrom?: 'xxs'|'xs'|'sm'|'md'|'lg'|'xl'|'xxl'|'3xl'|'4xl'|'5xl',
    hideTargetBreakpointTo?: 'xxs'|'xs'|'sm'|'md'|'lg'|'xl'|'xxl'|'3xl'|'4xl'|'5xl',
    className?: string,
};

const ItemDescription: React.FC<Props> = (
    {
        description = undefined,
        truncateLineLimit = undefined,
        hideTargetBreakpointFrom,
        hideTargetBreakpointTo,
        className = '',
    }
) => {
    const theme: Theme = useTheme();

    const style = css`
        font-size: 12px;
        line-height: 16px;
        color: ${theme.colours.grey[900]};

        ${(hideTargetBreakpointFrom) ? css`
            ${hideTargetBreakpointTo ? css`
                ${theme.breakpoints.between(hideTargetBreakpointFrom, hideTargetBreakpointTo)} {
                    ${theme.breakpoints.between(hideTargetBreakpointFrom, hideTargetBreakpointTo)} {
                        display: none;
                    }
                }
            ` : css`
                ${theme.breakpoints.up(hideTargetBreakpointFrom)} {
                    display: none;
                }
            `}
        ` : css`
            ${hideTargetBreakpointTo && css`
                ${theme.breakpoints.down(hideTargetBreakpointTo)} {
                    display: none;
                }
            `}
        `}
        ${theme.breakpoints.down('xs')} {
            font-size: 14px;
            line-height: 18px;
        }
    `;

    return description ? (
        <TruncateWithTippy
            lines={truncateLineLimit}
            enabled={!!truncateLineLimit}
            text={description}
        >
            <div css={style} className={className}>{description}</div>
        </TruncateWithTippy>
    ) : null;
};

export default ItemDescription;