import React from "react";
import { css } from "@emotion/react";

type Props = {
    type: string,
};

const ItemGroupEmptyMessage: React.FC<Props> = ({ type }) => {
    const style = css`
        margin-top: 28px;
        font-size: 18px;
        line-height: 24px;
    `;

    return (
        <div css={style}>
            The {type} cart is currently empty.
        </div>
    );
};

export default ItemGroupEmptyMessage;