import { CardElement } from "@stripe/react-stripe-js";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import theme from "theme/index";
import { css } from "@emotion/react";
import EmailValidator from "email-validator";

import FormModal from "core/Components/FormModal";
import FormContainer from "pages/Components/ProductListPage/components/FormContainer";
import ModalHeader from "pages/Components/ProductListPage/components/ModalHeader";
import ModalText from "pages/Components/ProductListPage/components/ModalText";
import Form from "core/Components/Form/Form";
import FormLabelledField from "pages/Components/ProductListPage/components/FormLabelledField";
import FormInput from "pages/Components/ProductListPage/components/FormInput";
import FormText from "pages/Components/ProductListPage/components/FormText";
import {
    ORDER_DISCOUNT_MESSAGE,
    PAYMENT_OPTIONS,
    PRODUCT_ORDER_INIT_FIELDS
} from "pages/Components/ProductListPage/includes/constants";
import { hasFeatureFlagBasedOnCountryCode } from "core/includes/site";
import { FLAG_STRIPE_PAYMENTS } from "core/includes/featureFlags";
import FormRadioButton from "pages/Components/ProductListPage/components/FormRadioButton";
import OrderSummaryTable from "pages/Components/ProductListPage/components/OrderSummaryTable";
import OrderInfoText from "pages/Components/ProductListPage/components/OrderInfoText";
import Disclaimer from "pages/Components/ProductListPage/components/Disclaimer";
import FormActions from "core/Components/Form/FormActions";
import FormButton from "pages/Components/ProductListPage/components/FormButton";
import useForm, { FieldList } from "core/hooks/useForm";
import { useAppState } from "core/contexts/AppContext";
import { getTaxLabel } from "core/includes/finance";
import { formatAndAddDecimals } from "core/includes/formatters";

type Props = {
    onCloseOderForm: () => void,
    onSubmit: (fields: FieldList) => void,
    disclaimer: string,
    products: OrderListItem[],
    currency?: string,
    taxPrefix?: string,
    cardErrorMessage?: string,
    countryCode?: CountryCodeItem,
    subtotal: number,
    taxAmount: number,
    totalAmount?: number,
    shipping?: number,
    onPromoValidation?: (promoCode: string, reset?: boolean) => Promise<void>|void,
    hasProductDiscount?: boolean,
    isSubmitting: boolean,
    isShippingTaxIncluded: boolean,
};

const OrderForm = (
    {
        onCloseOderForm,
        onSubmit,
        disclaimer,
        products,
        currency = "$",
        taxPrefix = "excl.",
        cardErrorMessage = "",
        countryCode,
        subtotal,
        taxAmount,
        totalAmount = 0,
        shipping = 0,
        onPromoValidation,
        hasProductDiscount = false,
        isSubmitting,
        isShippingTaxIncluded = false,
    }: Props
) => {
    const [hideError, setHideError] = useState(!cardErrorMessage);
    const hasAppliedPromo = useRef(false);

    const [appState] = useAppState();
    const taxLabel = getTaxLabel(countryCode);

    const cardErrorHolder = useRef("");

    useEffect(() => {
        if (cardErrorMessage !== cardErrorHolder.current) {
            cardErrorHolder.current = cardErrorMessage;
        }
    }, [cardErrorMessage]);

    const onCardChange = useCallback((cardInfo: any) => {
        if (cardInfo?.error) {
            cardErrorHolder.current = cardInfo.error.message;
            setHideError(false);
        } else {
            setHideError(true);
        }
    }, []);

    const validator: any = (fields: FieldList, isSubmit: boolean = false) => {
        const errors: any = {};

        if (!fields.firstName) {
            errors.firstName = "Please provide first name";
        }

        if (!fields.lastName) {
            errors.lastName = "Please provide last name";
        }

        if (!fields.schoolName) {
            errors.schoolName = "Please provide school name";
        }

        if (!fields.streetNo) {
            errors.streetNo = "Please provide street number";
        }

        if (!fields.streetName) {
            errors.streetName = "Please provide street name";
        }

        if (!fields.suburb) {
            errors.suburb = "Please provide suburb";
        }

        if (!fields.city) {
            errors.city = "Please provide city";
        }

        if (!fields.postCode) {
            errors.postCode = "Please provide post code";
        }

        if (!fields.contactEmail) {
            errors.contactEmail = "Please provide your contact email";
        }

        if (fields.contactEmail && !EmailValidator.validate(fields.contactEmail) && isSubmit) {
            errors.contactEmail = "Please provide a valid email address";
        }

        if (!fields.emailToInvoice && fields.paymentOption === PAYMENT_OPTIONS[0].name) {
            errors.emailToInvoice = "Please provide your email to invoice";
        }

        if (fields.emailToInvoice && !EmailValidator.validate(fields.emailToInvoice) && isSubmit) {
            errors.emailToInvoice = "Please provide a valid email address";
        }

        return errors;
    };

    const [fields, errors, onFieldChange, , validate] = useForm({
        fields: PRODUCT_ORDER_INIT_FIELDS,
        validator,
    });

    const onPromoCodeChange = (name: any, value: any) => {
        onFieldChange(name, value.toUpperCase());
        hasAppliedPromo.current = false;
        if (onPromoValidation) {
            onPromoValidation("", true);
        }
    }

    const onPaymentOptionSelection = (paymentOptionKey: string) => {
        if (fields.paymentOption !== paymentOptionKey) {
            onFieldChange('paymentOption', paymentOptionKey)
            cardErrorHolder.current = "";
        }
    }

    const _onSubmitOrder = () => {
        if (validate()) {
            return onSubmit(fields);
        }
    }

    const cardElementOptions: any = useMemo(() => ({
        style: {
            base: {
                padding: 40,
                lineHeight: '20px',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                fontFamily: theme.fonts.frutiger,
                '::placeholder': {
                    color: theme.colours.grey[500],
                },
            }
        }
    }), []);

    const hasFeatureFlag = useMemo(
        () => hasFeatureFlagBasedOnCountryCode(countryCode?.code, appState.featureFlags, FLAG_STRIPE_PAYMENTS),
        [countryCode?.code, appState.featureFlags]
    );

    const onPromoCode = () => {
        if (onPromoValidation) {
            onPromoValidation(fields.promoCode)
                ?.then(() => {
                    hasAppliedPromo.current = true;
                })
        }
    }

    const decimalsNum = useMemo(() => {
        const amounts = [
            ...products.map(product => product.totalAmount - product.discountedPrice),
            subtotal,
            taxAmount,
            totalAmount,
            shipping
        ];
        return amounts.some(amount => amount % 1 !== 0) ? 2 : 0;
    }, [products, shipping, subtotal, taxAmount, totalAmount]);

    const hasPromoCodeError = !!fields.promoCode && hasAppliedPromo.current && !hasProductDiscount;

    const paymentOptionsContainer = css`
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 13px;
    `;

    const paymentOptionLabel = css`
        text-align: left;
        font-weight: bold;
        margin-top: 26px;
    `;

    const checkoutFormBlock = css`
        padding: 10px;
        border: 1px solid ${theme.colours.grey[500]};
        margin-top: 30px;
    `;

    const paymentValidationErrorStyle = css`
        display: flex;
        min-height: 20px;
        line-height: 14px;
        top: 100%;
        font-size: 12px;
        color: ${theme.colours.crimson};
    `;

    const errorStyle = (hasError = false) => css`
        ${hasError && css`
            ${theme.breakpoints.down('md')} {
                margin-bottom: 33px;
            }
        `}
    `;

    const promoCodeInputStyle = css`
        display: flex;
        width: 76%;

        align-items: center;
        gap: 5%;
    `;

    const applyButtonStyle = css`
        border-radius: ${theme.borderAndShadow.largeRadius};
        margin: 0 !important;

        ${!fields.promoCode && css`
            pointer-events: none;
            opacity: 0.3;
        `}
    `;

    const additionalLabelStyle = css`
        color: ${theme.colours.gray};
        margin-left: 5px;
    `;

    const formStyle = css`
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    `;

    return (
        <FormModal
            onClose={onCloseOderForm}
            top={theme.sizes.menu.heightMain + 20}
            css={formStyle}
            isVisible
            isFixed
        >
            <FormContainer>
                <ModalHeader>{hasFeatureFlag ? "Delivery Details" : "Order form"}</ModalHeader>
                <ModalText>Please fill in the required fields (*)</ModalText>

                <Form>
                    <FormLabelledField
                        name="firstName"
                        error={errors.firstName}
                        label="First name"
                        css={errorStyle(!!errors.firstName)}
                        errorAsterisk
                    >
                        <FormInput
                            hasError={!!errors.firstName}
                            label="Type full name"
                            name="firstName"
                            onChange={onFieldChange}
                            value={fields.firstName}
                        />
                    </FormLabelledField>

                    <FormLabelledField
                        name="lastName"
                        error={errors.lastName}
                        label="Last name"
                        css={errorStyle(!!errors.lastName)}
                        errorAsterisk
                    >
                        <FormInput
                            hasError={!!errors.lastName}
                            label="Type last name"
                            name="lastName"
                            onChange={onFieldChange}
                            value={fields.lastName}
                        />
                    </FormLabelledField>

                    <FormLabelledField
                        name="schoolName"
                        error={errors.schoolName}
                        label="School name"
                        css={errorStyle(!!errors.schoolName)}
                        errorAsterisk
                    >
                        <FormInput
                            hasError={!!errors.schoolName}
                            label="Type school name"
                            name="schoolName"
                            onChange={onFieldChange}
                            value={fields.schoolName}
                        />
                    </FormLabelledField>

                    <FormText>School delivery address</FormText>

                    <FormLabelledField
                        name="streetNo"
                        error={errors.streetNo}
                        label="Street no."
                        css={errorStyle(!!errors.streetNo)}
                        errorAsterisk
                    >
                        <FormInput
                            hasError={!!errors.streetNo}
                            label="Type street no."
                            name="streetNo"
                            onChange={onFieldChange}
                            value={fields.streetNo}
                        />
                    </FormLabelledField>

                    <FormLabelledField
                        name="streetName"
                        error={errors.streetName}
                        label="Street name"
                        css={errorStyle(!!errors.streetName)}
                        errorAsterisk
                    >
                        <FormInput
                            hasError={!!errors.streetName}
                            label="Type street name"
                            name="streetName"
                            onChange={onFieldChange}
                            value={fields.streetName}
                        />
                    </FormLabelledField>

                    <FormLabelledField
                        name="suburb"
                        error={errors.suburb}
                        label="Suburb"
                        css={errorStyle(!!errors.suburb)}
                        errorAsterisk
                    >
                        <FormInput
                            hasError={!!errors.suburb}
                            label="Type suburb"
                            name="suburb"
                            onChange={onFieldChange}
                            value={fields.suburb}
                        />
                    </FormLabelledField>

                    <FormLabelledField
                        name="city"
                        error={errors.city}
                        label="City"
                        css={errorStyle(!!errors.city)}
                        errorAsterisk
                    >
                        <FormInput
                            hasError={!!errors.city}
                            label="Type city"
                            name="city"
                            onChange={onFieldChange}
                            value={fields.city}
                        />
                    </FormLabelledField>

                    <FormLabelledField
                        name="postCode"
                        error={errors.postCode}
                        label="Post code"
                        css={errorStyle(!!errors.postCode)}
                        errorAsterisk
                    >
                        <FormInput
                            hasError={!!errors.postCode}
                            label="Type post code"
                            name="postCode"
                            onChange={onFieldChange}
                            value={fields.postCode}
                        />
                    </FormLabelledField>

                    <FormLabelledField
                        name="country"
                        label="Country"
                    >
                        <FormInput
                            label="Country"
                            name="country"
                            value={countryCode?.name}
                        />
                    </FormLabelledField>

                    <FormLabelledField
                        name="contactEmail"
                        error={errors.contactEmail}
                        label="Contact email"
                        css={errorStyle(!!errors.contactEmail)}
                        errorAsterisk
                    >
                        <FormInput
                            hasError={!!errors.contactEmail}
                            label="Type contact email"
                            name="contactEmail"
                            onChange={onFieldChange}
                            value={fields.contactEmail}
                        />
                    </FormLabelledField>

                    {fields.paymentOption === PAYMENT_OPTIONS[0].name && (
                        <FormLabelledField
                            name="emailToInvoice"
                            error={errors.emailToInvoice}
                            label="Email to invoice"
                            css={errorStyle(!!errors.emailToInvoice)}
                            errorAsterisk
                        >
                            <FormInput
                                hasError={!!errors.emailToInvoice}
                                label="Type email to invoice"
                                name="emailToInvoice"
                                onChange={onFieldChange}
                                value={fields.emailToInvoice}
                            />
                        </FormLabelledField>
                    )}

                    <FormLabelledField
                        name="orderNo"
                        label="Order no."
                    >
                        <FormInput
                            label="Type order no."
                            name="orderNo"
                            onChange={onFieldChange}
                            value={fields.orderNo}
                        />
                    </FormLabelledField>

                    <FormLabelledField
                        name="promoCode"
                        label="Promo code"
                        error={hasPromoCodeError ? ORDER_DISCOUNT_MESSAGE.fail : ""}
                        css={errorStyle(hasPromoCodeError)}
                    >
                        <div css={promoCodeInputStyle}>
                            <FormInput
                                label="Promo code"
                                name="promoCode"
                                value={fields.promoCode}
                                width={100}
                                onChange={onPromoCodeChange}
                                hasError={hasPromoCodeError}
                                hasTick={!!fields.promoCode && hasAppliedPromo.current && hasProductDiscount}
                            />
                            <FormButton onButtonClick={onPromoCode} borderRadius={8} css={applyButtonStyle}>
                                Apply
                            </FormButton>
                        </div>
                    </FormLabelledField>

                    <FormLabelledField
                        name="notes"
                        label="Notes"
                    >
                        <FormInput
                            label="Notes"
                            name="notes"
                            onChange={onFieldChange}
                            value={fields.notes}
                        />
                    </FormLabelledField>

                    {hasFeatureFlag && (
                        <>
                            <div css={paymentOptionLabel}>Payment option</div>
                            <div css={paymentOptionsContainer}>
                                {PAYMENT_OPTIONS.map((paymentOption) => (
                                    <FormRadioButton
                                        key={paymentOption.name}
                                        label={
                                            <>
                                                {paymentOption.label}
                                                {!!paymentOption.additionalLabel && (
                                                    <span
                                                        css={additionalLabelStyle}>{paymentOption.additionalLabel}</span>
                                                )}
                                            </>
                                        }
                                        name={paymentOption.name}
                                        selected={fields.paymentOption === paymentOption.name}
                                        onChange={onPaymentOptionSelection}
                                        value={paymentOption.name}
                                    />
                                ))}
                            </div>
                        </>
                    )}

                    <FormText>{hasFeatureFlag ? "Order Summary" : "Order"}</FormText>

                    <OrderSummaryTable
                        products={products}
                        hasFeatureFlag={hasFeatureFlag}
                        subtotal={subtotal}
                        taxAmount={taxAmount}
                        totalAmount={totalAmount}
                        countryCode={countryCode}
                        shipping={shipping}
                        isShippingTaxIncluded={isShippingTaxIncluded}
                        decimalsNum={decimalsNum}
                    />

                    {!hasFeatureFlag && (
                        <FormText noMarginTop>
                            Total: {currency}{formatAndAddDecimals(subtotal, decimalsNum)} {taxPrefix}&nbsp;{taxLabel} & postage
                        </FormText>
                    )}

                    {hasFeatureFlag && shipping <= 0 && (
                        <FormText noMarginTop>
                            Total: {currency}{formatAndAddDecimals(totalAmount, decimalsNum)} ({getTaxLabel(countryCode)}) excl. postage
                        </FormText>
                    )}

                    {/* invoice */}
                    {fields.paymentOption === PAYMENT_OPTIONS[0].name && (
                        <OrderInfoText>
                            For school orders, payment is due 10 days following invoice date.
                        </OrderInfoText>
                    )}

                    {/* card payment */}
                    {fields.paymentOption === PAYMENT_OPTIONS[1].name && (
                        <>
                            <div css={checkoutFormBlock}>
                                <CardElement options={cardElementOptions} onChange={onCardChange} />
                            </div>
                            {!hideError && (
                                <div css={paymentValidationErrorStyle}>
                                    {cardErrorHolder.current}
                                </div>
                            )}
                        </>
                    )}

                    <Disclaimer fontSize={12} lineHeight={14}>
                        {disclaimer}
                    </Disclaimer>

                    <FormActions justifyContent={"center"}>
                        <FormButton onButtonClick={_onSubmitOrder} isSubmitting={isSubmitting}>
                            {hasFeatureFlag ? "Submit" : "Submit order"}
                        </FormButton>
                        <FormButton type={"button"} onButtonClick={onCloseOderForm} disabled={isSubmitting}>
                            Cancel
                        </FormButton>
                    </FormActions>
                </Form>
            </FormContainer>
        </FormModal>
    )
};

export default OrderForm;