import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import { useAppState } from "core/contexts/AppContext";
import useSetState from "core/hooks/useSetState";

type DefaultState = {
    isMatchTarget: boolean,
    discountCode: string,
    readOnly: boolean,
};

const INITIAL_STATE = {
    isMatchTarget: false,
    discountCode: '',
    readOnly: false,
};

type Props = {
    items: CartProduct[],
    setValidationError: (discountCode: string, applyTarget: string, errorMessage: string) => void,
    isEmptyItems: boolean,
};

const ItemDiscountCodeInputWithCode: React.FC<Props> = (
    {
        items,
        setValidationError,
        isEmptyItems,
    }
) => {
    const theme: Theme = useTheme();
    const [appState, setAppState] = useAppState();
    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);

    const onChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev: any) => ({
            isMatchTarget: false,
            discountCode: e.target.value,
            readOnly: prev.readOnly,
        }));

        setAppState((prev: any) => ({
            cart: {
                ...prev.cart,
                validateDiscountCode: undefined,
            }
        }));
    };

    const formatInput = (e: React.KeyboardEvent<HTMLElement>) => {
    };

    const buttonClickHandler = () => {
        if (isEmptyItems) {
            return;
        }
    };

    const style = css`
        position: relative;
        display: flex;

        > input:focus {
            outline: none;
        }

        ${!isEmptyItems && css`
            &:hover {
                > input {
                    border: 2px solid ${theme.colours.malibu4};
                }

                > div {
                    border: 2px solid ${theme.colours.malibu4};
                    background-color: ${theme.colours.malibu4};
                }
            }
        `}
    `;

    const codeErrorStyle = css`
        position: absolute;
        top: -15px;
        font-size: 10px;
        color: ${theme.colours.red1};
    `;

    const inputStyle = css`
        width: 136px;
        height: 33px;
        text-align: center;
        border: 2px solid ${theme.colours.anakiwa2};
        border-radius: 5px 0 0 5px;

        ${(state.isMatchTarget && appState.cart.validateDiscountCode?.errorMessage) && css`
            color: ${theme.colours.red1};
        `}
        ${theme.breakpoints.down('lg')} {
            width: 129px;
            height: 28px;
            font-size: 13px;
        }
    `;

    const buttonStyle = css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 65px;
        color: white;
        border-radius: 0 5px 5px 0;
        background-color: ${theme.colours.anakiwa2};

        ${!isEmptyItems && css`
            cursor: pointer;
        `}
        ${theme.breakpoints.down('lg')} {
            font-size: 13px;
        }
    `;

    return (
        <div css={style}>
            {(state.isMatchTarget && appState.cart.validateDiscountCode?.errorMessage) && (
                <span css={codeErrorStyle}>The code is invalid</span>
            )}
            <input
                type={'text'}
                value={state.discountCode || ''}
                onChange={onChangeEvent}
                onKeyDown={formatInput}
                css={inputStyle}
                placeholder={'Enter promo code'}
                readOnly={state.readOnly || isEmptyItems}
            />
            <div css={buttonStyle} onClick={buttonClickHandler}>
                {(!isEmptyItems && state.readOnly) ? 'Delete' : 'Apply'}
            </div>
        </div>
    );
};

export default ItemDiscountCodeInputWithCode;