import React, { ReactNode } from "react";
import { css } from "@emotion/react";

import theme from "theme/index";
import useFontSize from "core/hooks/useFontSize";

type Props = {
    children: ReactNode,
    textColour?: string,
    className?: string,
};

const HeaderTitle: React.FC<Props> = (
    {
        children,
        textColour = theme.colours.curiousBlue2,
        className = ''
    }
) => {
    const [fontSize, lineHeight] = useFontSize('p', '25', '25');

    const style = css`
        font-size: ${fontSize}px;
        line-height: ${lineHeight}px;
        font-weight: ${theme.fonts.weights.bold};
        color: ${textColour};
    `;

    return (
        <div css={style} className={className}>
            {children}
        </div>
    );
};

export default HeaderTitle;