import React, { useMemo } from "react";
import { css, Theme, useTheme } from "@emotion/react";

import RoundButton from "core/Components/Buttons/RoundButton";
import useBreakpoint from "core/hooks/useBreakpoint";
import ConfirmationCloseButtonIcon from "pages/Components/CartAndCheckout/components/ConfirmationCloseButtonIcon";
import { isDown } from "theme/breakpoints";
import { useCartItemsContext } from "core/contexts/CartItemsContext";
import ConfirmationRemoveAlertDialog from "pages/Components/CartAndCheckout/components/ConfirmationRemoveAlertDialog";

type Props = {
    eventFunction?: any,
    message?: string,
    isDialog?: boolean,
};

const ConfirmationRemoveAlert: React.FC<Props> = (
    {
        eventFunction,
        message = 'Are you sure you want to remove all items?',
        isDialog = false,
    }
) => {
    const theme: Theme = useTheme();
    const { breakpoint } = useBreakpoint();

    const { setConfirmationPopup } = useCartItemsContext();

    const [buttonWidth, buttonHeight, buttonTextFontSize] = useMemo(() => {
        switch (true) {
            case isDown(breakpoint, 'sm'):
                return [135, 38, 14];
            default:
                return [188, 50, 19];
        }
    }, [breakpoint]);

    const onClickConfirmHandler = () => {
        eventFunction();
        setConfirmationPopup('', false);
    }

    const onClickCancelHandler = () => {
        setConfirmationPopup('', false);
    };

    const style = css`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px 14px;
        width: 427px;
        height: 217px;
        border-radius: 17px;
        background-color: white;

        ${theme.breakpoints.down('sm')} {
            width: 314px;
            height: 164px;
        }
    `;

    const confirmationMessageStyle = css`
        margin-top: 51px;
        width: 240px;
        font-size: 19px;
        line-height: 23px;
        font-weight: ${theme.fonts.weights.bold};
        text-align: center;

        ${theme.breakpoints.down('sm')} {
            margin-top: 32px;
            font-size: 18px;
        }
    `;

    const confirmationButtonContainerStyle = css`
        display: flex;
        justify-content: space-between;
        width: 100%;
    `;

    return isDialog ? (
        <ConfirmationRemoveAlertDialog
            onLeftButtonClick={onClickCancelHandler}
            onClose={onClickCancelHandler}
            onRightButtonClick={onClickConfirmHandler}
            message={message}
        />
    ) : (
        <div css={style}>
            <div css={confirmationMessageStyle}>{message}</div>
            <div css={confirmationButtonContainerStyle}>
                <RoundButton
                    onButtonClick={onClickCancelHandler}
                    buttonWidth={buttonWidth}
                    buttonHeight={buttonHeight}
                    textFontSize={buttonTextFontSize}
                    backgroundColour={theme.colours.curiousBlue2}
                    buttonHoverColour={theme.colours.malibu3}
                    useTextHover={false}
                    textColour={theme.colours.white}
                >
                    No
                </RoundButton>
                <RoundButton
                    onButtonClick={onClickConfirmHandler}
                    buttonWidth={buttonWidth}
                    buttonHeight={buttonHeight}
                    textFontSize={buttonTextFontSize}
                    backgroundColour={theme.colours.redRibbon}
                    buttonHoverColour={theme.colours.wildWatermelon}
                    textColour={theme.colours.white}
                    useTextHover={false}
                >
                    Yes
                </RoundButton>
            </div>
            <ConfirmationCloseButtonIcon />
        </div>
    );
};

export default ConfirmationRemoveAlert;