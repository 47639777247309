import React from "react";
import { css, useTheme } from "@emotion/react";
import { useLocation } from "react-router-dom";

import BlogCard from "pages/Components/BlogListPage/components/BlogCard";
import LinkTo from "core/Components/LinkTo";
import { getURLSegmentsString } from "core/includes/formatters";

type Props = {
    posts: Array<BlogPost>
};

const Container: React.FC<Props> = ({ posts }) => {
    const theme = useTheme();
    const location = useLocation();
    const urlSegments = getURLSegmentsString(location.pathname);

    const containerStyle = css`
        height: 428px;
        width: 100%;
        display: flex;
        justify-content: center;

        ${theme.breakpoints.up('3xl')} {
            height: 526px;
        }

        ${theme.breakpoints.only('xl')} {
            height: 480px;
        }

        ${theme.breakpoints.only('lg')} {
            height: 381px;
        }

        ${theme.breakpoints.only('md')} {
            height: 702px;
        }

        ${theme.breakpoints.only('sm')} {
            height: 1143px;
        }

        ${theme.breakpoints.down('xs')} {
            height: 1044px;
        }
    `;

    const flexContainerStyle = css`
        height: 100%;
        display: flex;
        flex-direction: column;
        
        ${theme.breakpoints.down('sm')} {
            align-items: center;
        }
    `;

    const relatedPostsTitleStyle = css`
        font-size: 28px;
        color: ${theme.colours.curiousBlue2};
        margin-bottom: 20px;
        
        ${theme.breakpoints.only('md')} {
            padding-left: 125px;
        }
    `;

    const blogCardsContainerStyle = css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 52px;

        ${theme.breakpoints.only('xxl')} {
            column-gap: 30px;
        }

        ${theme.breakpoints.only('lg')} {
            column-gap: 30px;
        }
        
        ${theme.breakpoints.only('md')} {
            display: flex;
            column-gap: 50px;
            row-gap: 30px;
            flex-wrap: wrap;
            justify-content: center;
        }

        ${theme.breakpoints.down('sm')} {
            display: flex;
            flex-direction: column;
        }

        ${theme.breakpoints.only('sm')} {
            row-gap: 49px;
        }

        ${theme.breakpoints.down('xs')} {
            row-gap: 28px;
        }
    `;

    const blogCardStyle = css`
        flex-shrink: 0;

        .text-container {
            padding-top: 0;
        }

        .header {
            font-size: 19px;
            line-height: 23px;
            margin-bottom: 0;
        }

        .description {
            font-size: 14px;
            line-height: 18px;
        }

        ${theme.breakpoints.up('xl')} {
            width: 326px;
            height: 290px;
        }

        ${theme.breakpoints.down('lg')} {
            width: 290px;
            height: 280px;
        }

        ${theme.breakpoints.down('xs')} {
            .header {
                font-size: 16px;
                line-height: 23px;
            }
        }
    `;

    const linkStyle = css`
        height: auto;
    `;

    if (!posts.length) {
        return null;
    }

    return (
        <div css={containerStyle}>
            <div css={flexContainerStyle}>
                <div css={relatedPostsTitleStyle}>
                    You may also like...
                </div>
                <div css={blogCardsContainerStyle}>
                    {posts.map((bp: BlogPost) => (
                        <LinkTo
                            to={urlSegments + '/' + bp.uRLSegment}
                            key={bp.id}
                            css={linkStyle}
                        >
                            <BlogCard
                                blog={bp}
                                imagePercent={70}
                                css={blogCardStyle}
                                hideDateAndAuthor
                            />
                        </LinkTo>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Container;
