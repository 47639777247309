import { useState, useEffect } from "react";
import useElementDimensions from 'core/hooks/useElementDimensions';
import { getScrollBarWidth } from "core/includes/domUtils";

const useScrollbarWidth = (element: HTMLElement|null) => {
    const elementDimensions = useElementDimensions(element);
    const [scrollBarWidth, setScrollBarWidth] = useState(0);

    useEffect(() => {
        const newScrollBarWidth = getScrollBarWidth(element);

        if (scrollBarWidth !== newScrollBarWidth) {
            setScrollBarWidth(newScrollBarWidth);
        }
    }, [elementDimensions, element, scrollBarWidth]);

    return scrollBarWidth;
};

export default useScrollbarWidth;