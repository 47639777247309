import { useCallback } from "react";
import { persistValue, retrieveValue, removeItem } from "core/includes/localStorage";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_CART, UPDATE_OR_CREATE_CART } from "core/includes/queries";
import useToast from "core/hooks/useToast";
import { useAppState } from "core/contexts/AppContext";

const CART_LOCAL_STORAGE_KEY = 'cart';

const useCart = () => {
    const [appState, setAppState] = useAppState();
    const [updateOrCreateCart] = useMutation(UPDATE_OR_CREATE_CART);
    const [deleteCart] = useMutation(DELETE_CART);
    const [successToast] = useToast();

    const updateCart = useCallback((
        productId: number,
        quantity: number = 0,
        isTaxIncluded: boolean = false,
    ) => {
        const cartLocalStorage = retrieveValue(CART_LOCAL_STORAGE_KEY) || "{}";
        const { id } = JSON.parse(cartLocalStorage);
        const cartId = id ? parseInt(id) : 0;
        updateOrCreateCart({
            variables: {
                productId,
                quantity,
                cartId: appState.cart?.id || cartId,
                isTaxIncluded,
            }
        }).then((data) => {
            const cart = data.data.updateShoppingCart;
            setAppState((prevState: any) => ({
                cart: {
                    ...prevState.cart,
                    ...cart
                }
            }));
            if (!cart) {
                removeItem(CART_LOCAL_STORAGE_KEY);
            } else {
                persistValue(CART_LOCAL_STORAGE_KEY, JSON.stringify(cart));
            }
            if (quantity) {
                successToast('Product was added to your cart.');
            } else {
                successToast('Product was removed from your cart.')
            }
        })
    }, [appState.cart?.id, setAppState, successToast, updateOrCreateCart]);

    const clearCart = useCallback(() => {
        const cartLocalStorage = retrieveValue(CART_LOCAL_STORAGE_KEY) ?? "{}";
        const { id } = JSON.parse(cartLocalStorage);
        const cartId = id ? parseInt(id) : 0;
        deleteCart({
            variables: {
                cartId
            }
        }).then(() => {
            removeItem(CART_LOCAL_STORAGE_KEY)
            setAppState({
                cart: null
            })
        })
    }, [deleteCart, setAppState]);

    const getCart = useCallback(() => {
        const cartLocalStorage = retrieveValue(CART_LOCAL_STORAGE_KEY) ?? "{}";
        return JSON.parse(cartLocalStorage);
    }, []);

    return {
        updateCart,
        getCart,
        clearCart,
    }
};

export default useCart;