import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
    itemBoxWidth: number,
};

const ItemBox: React.FC<Props> = ({ children, itemBoxWidth }) => {
    const theme: Theme = useTheme();

    const style = css`
        position: relative;
        display: flex;
        justify-content: space-between;
        width: ${itemBoxWidth}px;
        height: 100%;
        border-radius: 12px;
        background-color: white;
        z-index: 2;

        ${theme.breakpoints.down('xs')} {
            height: 219px;
            padding: 6px 8px;
            flex-direction: column;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default ItemBox;