import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
    type: string,
};

const SummaryRow: React.FC<Props> = ({ children, type }) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        flex-direction: column;

        ${type === 'shipping' && css`
            gap: 13px;
            margin-top: 14.5px;
            width: 100%;

            ${theme.breakpoints.between('sm', 'lg')} {
                margin-top: 14.5px;
                gap: 10px;
            }
        `}

        ${type === 'subTotal' && css`
            gap: 13px;
            margin-top: 31px;
            width: 100%;

            ${theme.breakpoints.between('sm', 'lg')} {
                margin-top: 20px;
                gap: 10px;
            }
        `}

        ${type === 'total' && css`
            margin-top: 33px;
            width: 100%;

            ${theme.breakpoints.only('lg')} {
                margin-top: 24px;
            }

            ${theme.breakpoints.between('sm', 'lg')} {
                margin-top: 28px;
            }
        `}
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default SummaryRow;