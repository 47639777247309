import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
    width: number,
};

const CartPopupItemContainer: React.FC<Props> = ({ children, width }) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        justify-content: space-between;
        padding: 12px 7px 13px;
        width: ${width}px;
        border-bottom: 2px solid ${theme.colours.alto};

        ${theme.breakpoints.only("xl")} {
            padding: 7px;
        }

        ${theme.breakpoints.between("sm", "lg")} {
            padding: 6px 1px 8px;
        }

        ${theme.breakpoints.down("xs")} {
            padding: 5px 5px 5px 7px;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default CartPopupItemContainer;