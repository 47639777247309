import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import RoundButton from "core/Components/Buttons/RoundButton";
import { useAppState } from "core/contexts/AppContext";

type Props = {
    hasItems: boolean,
};

const CartPopupGoToCartButton: React.FC<Props> = ({ hasItems }) => {
    const theme: Theme = useTheme();
    const [, setAppState] = useAppState();

    const onClickHandler = () => {
        setAppState({
            isOpenCartPopup: false
        });
    };

    const style = css`
        display: flex;
        justify-content: center;
        margin-top: 28px;
    `;

    const textStyle = css`
        font-size: 21px;
    `;

    return (
        <div css={style}>
            <RoundButton
                buttonWidth={232}
                buttonHeight={51}
                backgroundColour={theme.colours.curiousBlue2}
                buttonHoverColour={theme.colours.malibu3}
                useTextHover={false}
                textColour={theme.colours.white}
                {...hasItems ? {
                    linkURL: 'cart-summary',
                    onLinkClick: onClickHandler
                } : {
                    disabled: true
                }}
            >
                <span css={textStyle}>Go to cart</span>
            </RoundButton>
        </div>
    );
};

export default CartPopupGoToCartButton;