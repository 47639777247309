import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import Bin from "core/Components/Icons/Bin";

type Props = {
    lineItemId: number,
    removeItem: (lineItemId: number) => void,
    itemBoxWidth: number,
};

const RemoveItemIcon: React.FC<Props> = (
    {
        lineItemId,
        removeItem,
        itemBoxWidth,
    }
) => {
    const theme: Theme = useTheme();

    const onClickHandler = () => {
        removeItem(lineItemId);
    };

    const style = css`
        position: absolute;
        top: -13px;
        right: calc((100% - ${itemBoxWidth}px) / 2 - 10px);

        ${theme.breakpoints.up('sm')} {
            display: none;
        }
    `;

    const roundStyle = css`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 27px;
        height: 27px;
        cursor: pointer;
        border-radius: 14px;
        background-color: ${theme.colours.red1};

        &:hover {
            background-color: ${theme.colours.brickRed};
        }
    `;

    const binStyle = css`
        width: 11px;
        height: 15px;
    `;

    return (
        <div css={style}>
            <div css={roundStyle} onClick={onClickHandler}>
                <Bin colour={'white'} css={binStyle} />
            </div>
        </div>
    );
};

export default RemoveItemIcon;
