import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    type: string,
};

const ItemGroupTitle: React.FC<Props> = ({ type }) => {
    const theme: Theme = useTheme();

    const style = css`
        font-size: 22px;
        line-height: 26px;
        font-weight: ${theme.fonts.weights.bold};
        color: ${theme.colours.curiousBlue};

        ${theme.breakpoints.only('xl')} {
            font-size: 19px;
            line-height: 23px;
        }

        ${theme.breakpoints.down('lg')} {
            font-size: 18px;
            line-height: 23px;
        }
    `;

    return (
        <div css={style}>
            {type === 'event' ? 'Events' : 'Products'}
        </div>
    );
};

export default ItemGroupTitle;