import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
};

const ItemBoxTitlesContainer: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        justify-content: space-between;
        margin: 14px 0 11px;
        font-size: 16px;
        line-height: 19px;
        text-align: center;

        ${theme.breakpoints.between('md', 'xl')} {
            font-size: 13px;
            line-height: 16px;
        }

        ${theme.breakpoints.only('sm')} {
            font-size: 10px;
            line-height: 13px;
        }

        ${theme.breakpoints.down('xs')} {
            margin-bottom: 37px;
            font-size: 14px;
            line-height: 17px;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default ItemBoxTitlesContainer;