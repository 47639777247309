import { ElementType } from "react";
import { useTheme } from "@emotion/react";

import useBreakpoint from "core/hooks/useBreakpoint";

const useFontSize = (
    tag: string|ElementType = "div",
    fontSizeType: string = "",
    lineHeightType: string = "",
    defaultValues: [number, number] = [0, 0],
) => {
    const theme = useTheme()
    const { breakpoint } = useBreakpoint();

    // Only the tags p & heading can set the default font-size (& line-height) of the fontSizes list
    // Other tags like Div use the default font-size (& line-height) of root
    const pSizeType = tag === "p" ? "9" : "";
    const hSizeType = (typeof tag === "string" && tag.startsWith("h")) ? tag.substring(1) : "";
    const sizeType = fontSizeType || pSizeType || hSizeType

    const hHeightType = (typeof tag === "string" && tag.startsWith("h")) ? tag.substring(1) : "";
    const heightType = lineHeightType || sizeType || hHeightType;

    const fontTagSizes = theme.fontSizes.sizes[sizeType as keyof typeof theme.fontSizes.sizes];
    const fontTagHeights = theme.fontSizes.heights[heightType as keyof typeof theme.fontSizes.heights];

    // It will use the font-size & line-height of the root default when the return value is 0.
    const fontSize:number = sizeType ? fontTagSizes[breakpoint as keyof typeof fontTagSizes] : 0;
    const lineHeight:number = heightType ? fontTagHeights[breakpoint as keyof typeof fontTagHeights] : 0;

    if (fontSize === 0 && lineHeight === 0) {
        return defaultValues;
    }

    return [fontSize, lineHeight]
}

export default useFontSize;