import React, { useEffect } from "react";
import { css, Theme, useTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";

import Cart from "core/Components/Icons/Cart";
import { hasFeatureFlag } from "core/includes/site";
import { FLAG_SHOP_2 } from "core/includes/featureFlags";
import { useAppState } from "core/contexts/AppContext";
import CartItemCount from "core/Components/Navigations/CartItemCount";
import useCart from "pages/Components/CartPage/hooks/useCart";

type Props = {
    to: string,
    closeBurgerMenu: ({ forcedClose }: any) => void,
    isDisabledCartIcon?: boolean,
}

const CartIcon: React.FC<Props> = ({ to, closeBurgerMenu, isDisabledCartIcon = false }) => {
    const [appState, setAppState] = useAppState();
    const hasShopFeatureFlag = hasFeatureFlag(appState.featureFlags, FLAG_SHOP_2);
    const theme: Theme = useTheme();
    const itemCount = appState.cart?.products?.length || 0;

    const { getCart } = useCart();

    useEffect(() => {
        setAppState({
            cart: getCart()
        })
    }, [getCart, setAppState]);

    const onClickHandler = () => {
        if (hasShopFeatureFlag) {
            if (!isDisabledCartIcon) {
                setAppState({
                    isOpenCartPopup: true,
                });
                closeBurgerMenu({ forcedClose: true });
            }
        } else {
            closeBurgerMenu({ forcedClose: true });
        }
    };

    const style = css`
        ${hasShopFeatureFlag ? css`
            > div {
            ${appState.isOpenCartPopup && css` pointer-events: none; `};
            
            > .cart-background-circle {
                display: none;
            }
        }

        ${!isDisabledCartIcon && css`
            cursor: pointer;
            
            &:hover {
                > div > .cart-icon {
                    > svg > path {
                        fill: ${theme.colours.scienceBlue};
                    }
                }

                > div > .cart-background-circle {
                    display: block;
                }
            }
        `}
        ` : css`
            display: flex;
            align-items: center;
            height: 100%;
        `}

        ${theme.breakpoints.up("xxl")} {
            margin-right: 20px;
        }

        ${theme.breakpoints.between("lg", "xl")} {
            margin-right: 10px;
        }

        ${theme.breakpoints.down("md")} {
            margin-right: ${theme.sizes.menu.sideMargin}px;
        }
    `;

    const cartStyle = css`
        width: 25px;
        height: 25px;
        
        ${hasShopFeatureFlag ? css`
            z-index: 1;
        ` : css`
            &:hover {
                > svg > path {
                    fill: ${theme.colours.scienceBlue};
                }
            }
        }
        `}
    `;

    const cartCircleStyle = css`
        display: none;
        position: absolute;
        top: -5px;
        left: -6px;
        width: 36px;
        height: 36px;
        border-radius: 18px;
        background-color: ${theme.colours.onahau};
        z-index: 0;

        ${theme.breakpoints.down('lg')} {
            top: 0;
        }

        ${theme.breakpoints.down('md')} {
            top: -5px;
        }
    `;

    const clickableDivStyle = css`
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
    `;

    return (
        <>
            {hasShopFeatureFlag ? (
                <div css={style}>
                    <div onClick={onClickHandler} css={clickableDivStyle}>
                    <Cart className={'cart-icon'} css={cartStyle} />
                    <div className={'cart-background-circle'} css={cartCircleStyle}></div>
                        {!isDisabledCartIcon && (
                            <CartItemCount itemCount={itemCount} />
                        )}
                    </div>
                </div>
            ) : (
                <NavLink to={to} onClick={onClickHandler}>
                    <div css={style}>
                        <Cart css={cartStyle} />
                    </div>
                </NavLink>
            )}
        </>
    );
};

export default CartIcon;