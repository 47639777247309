import React, { useEffect } from "react";
import { css } from "@emotion/react";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet-async";

import { useAppState } from "core/contexts/AppContext";
import useMetadata, { MetaData } from "core/hooks/useMetadata";
import useRouter from "core/hooks/useRouter";

import ErrorPage from "pages/Components/ErrorPage/ErrorPage";
import pages from "pages/config/pages";
import StripeLoader from "core/Components/Utils/StripeLoader";
import useHeaderHeight from "core/hooks/useHeaderHeight";

type Props = {
    stage: string | null,
    showBreadcrumb: boolean,
    hideHeaderCountry: boolean,
};

/**
 * Handles the main rendering of pages.  Returns error pages if needed.
 * @constructor
 */
const Main: React.FC<Props> = ({ stage, showBreadcrumb, hideHeaderCountry = false }) => {
    const navigate = useNavigate();
    const [, setAppState] = useAppState();
    const headerHeight = useHeaderHeight({ showBreadcrumb, hideHeaderCountry });

    const [data, loading, error] = useRouter(stage);
    const page = data?.page;
    const metaData = useMetadata(page);


    useEffect(() => {
        // loading is used for displaying <Footer>
        setAppState({ loading });
    }, [loading, setAppState]);

    if (page?.__typename === 'RedirectorPage') {
        if (page.linkUrl.indexOf('http') === 0) {
            window.location = page.linkUrl;
        } else {
            navigate(page.linkUrl);
        }
        return null;
    }

    let Component = page && pages.hasOwnProperty(page.__typename) && pages[page.__typename];
    const isProductListPage = page && page.__typename === "ProductListPage";

    const style = css`
        padding-top: ${headerHeight}px;
    `;

    return (
        <div css={style}>
            {(error || (page && !page.isClickable)) && (
                <ErrorPage error={error === "404" ? error : "500"} />
            )}
            {(!error && page && page.isClickable && Component) && (
                <>
                    <Helmet>
                        {metaData.map((meta: MetaData, index) => {
                            switch (meta.element) {
                                case 'link': return <link key={`meta-key-${index}`} {...meta.props} />;
                                case 'title': return <title key={`meta-key-${index}`} {...meta.props} />;
                                default: return <meta key={`meta-key-${index}`} {...meta.props} />;
                            }
                        })}
                    </Helmet>

                    {isProductListPage ? (
                        <StripeLoader>
                            <Component {...page && { data: page }} />
                        </StripeLoader>
                    ) : <Component {...page && { data: page }} />}
                </>
            )}
        </div>
    );
};

export default Main;
