import { useMemo } from "react";
import useBreakpointLib from 'use-breakpoint';

import breakpoints, { isBetween, isOnly, isUp } from 'theme/breakpoints';

const useCartDetailSizes = () => {
    const { breakpoint } = useBreakpointLib(breakpoints.sizes, "xxs");

    const [
        chevronPaddingTop,
        paddingTop,
        paddingRight,
        itemGroupBoxWidth,
        itemBoxWidth,
        itemBoxImageWidth,
        itemBoxImageHeight,
        itemBoxDescriptionWidth,
        itemBoxDescriptionLeftRightMargin,
        itemBoxPriceWidth,
        itemBoxPriceRightMargin,
        quantityBoxContainerWidth,
        quantityBoxWidth,
        quantityBoxHeight,
        plusMinusCircleButtonSize,
        isSmallBreakpoint,
    ] = useMemo(() => {
        switch (true) {
            case isUp(breakpoint, '5xl'):
                return [31, 31, 62, 843, 748, 126, 126, 328, 20, 140, 25, 106, 106, 34, 26, false];
            case isBetween(breakpoint, '3xl', '4xl'):
                return [34, 31, 62, 843, 748, 126, 126, 328, 20, 140, 25, 106, 106, 34, 26, false];
            case isOnly(breakpoint, 'xxl'):
                return [35, 31, 46, 784, 682, 126, 126, 290, 10, 140, 25, 106, 106, 34, 26, false];
            case isOnly(breakpoint, 'xl'):
                return [24, 20, 39, 634, 558, 134, 153, 224, 10, 110, 25, 75, 127, 40, 30, false];
            case isOnly(breakpoint, 'lg'):
                return [26, 20, 65, 525, 456, 110, 125, 183, 8, 98, 20, 50, 110, 40, 30, false];
            case isOnly(breakpoint, 'md'):
                return [30, 32, 46, 638, 566, 134, 153, 224, 10, 105, 30, 78, 110, 40, 30, false];
            case isOnly(breakpoint, 'sm'):
                return [24, 24, 46, 480, 431, 95, 132, 188, 10, 98, 25, 58, 110, 40, 30, false];
            default:
                return [31, 27, 0, 322, 322, 95, 95, 186, 8, 186, 8, 148, 148, 34, 26, true];
        }
    }, [breakpoint]);

    return {
        chevronPaddingTop,
        paddingTop,
        paddingRight,
        itemGroupBoxWidth,
        itemBoxWidth,
        itemBoxImageWidth,
        itemBoxImageHeight,
        itemBoxDescriptionWidth,
        itemBoxDescriptionLeftRightMargin,
        itemBoxPriceWidth,
        itemBoxPriceRightMargin,
        quantityBoxContainerWidth,
        quantityBoxWidth,
        quantityBoxHeight,
        plusMinusCircleButtonSize,
        isSmallBreakpoint,
    };
};

export default useCartDetailSizes;