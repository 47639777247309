import React, { ReactNode } from "react";
import { css, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
    quantityBoxWidth: number,
    quantityBoxHeight: number,
    circleButtonSize: number,
};

const QuantityContainer: React.FC<Props> = (
    {
        children,
        quantityBoxWidth,
        quantityBoxHeight,
        circleButtonSize,
    }
) => {
    const theme = useTheme();

    const containerStyle = css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: calc((${quantityBoxHeight}px - ${circleButtonSize}px - 4px) / 2);
        width: ${quantityBoxWidth}px;
        height: ${quantityBoxHeight}px;
        border-radius: ${quantityBoxHeight / 2}px;
        border: 2px solid ${theme.colours.anakiwa2};
        user-select: none;
        background-color: white;

        ${theme.breakpoints.between('sm', 'xl')} {
            flex-direction: column-reverse;
            width: ${quantityBoxHeight}px;
            height: ${quantityBoxWidth}px;
        }

        > input:focus {
            outline: none;
        }

        > input {
            font-size: 16px;
            width: ${quantityBoxHeight - 4}px;
        }
    `;

    return (
        <div css={containerStyle}>
            {children}
        </div>
    );
}

export default QuantityContainer;