import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ItemDiscountCodeInputWithButton
    from "pages/Components/CartAndCheckout/components/Item/ItemDiscountCodeInputWithButton";

type Props = {
    items: CartProduct[],
    setValidationError: (discountCode: string, applyTarget: string, errorMessage: string) => void,
    isEmptyItems: boolean,
};

const ItemDiscountCode: React.FC<Props> = (
    {
        items,
        setValidationError,
        isEmptyItems,
    }
) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        align-items: center;
    `;

    const textStyle = css`
        margin-right: 32px;
        font-size: 13px;
        line-height: 17px;
        color: ${theme.colours.gray};

        ${theme.breakpoints.only('xl')} {
            margin-right: 21px;
        }

        ${theme.breakpoints.down('lg')} {
            display: none;
        }
    `;

    return (
        <div css={style}>
            <div css={textStyle}>Do you have a promo code?</div>
            <ItemDiscountCodeInputWithButton
                items={items}
                setValidationError={setValidationError}
                isEmptyItems={isEmptyItems}
            />
        </div>
    );
};

export default ItemDiscountCode;