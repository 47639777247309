import React, { ReactNode } from 'react';

type Props = {
    children: ReactNode,
};

const SummaryItemsContainer = ({ children }: Props) => {
    return (
        <div>{children}</div>
    );
}

export default SummaryItemsContainer;