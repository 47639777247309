import React, { useMemo } from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ConfirmationRemoveAlert from "pages/Components/CartAndCheckout/components/ConfirmationRemoveAlert";
import { useCartItemsContext } from "core/contexts/CartItemsContext";
import useCart from "pages/Components/CartPage/hooks/useCart";

const ConfirmationPopup: React.FC = () => {
    const {
        clearCart,
    } = useCart();
    const {
        confirmationState,
    } = useCartItemsContext();

    const theme: Theme = useTheme();

    const confirmationLayer = useMemo(() => {
        switch (true) {
            case confirmationState.popupType === 'remove-items':
                return (
                    <ConfirmationRemoveAlert
                        eventFunction={clearCart}
                    />
                );
            case confirmationState.popupType === 'remove-item' || confirmationState.popupType === 'remove-item-from-panel':
                return (
                    <ConfirmationRemoveAlert
                        eventFunction={confirmationState.eventFunction}
                        message={'Are you sure you want to remove this item?'}
                        isDialog={confirmationState.popupType === 'remove-item-from-panel'}
                    />
                );
            default:
                return '';
        }
    }, [confirmationState, clearCart]);

    const style = css`
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;

        ${confirmationState.showLayer && confirmationState.popupType !== 'remove-item-from-panel' && css`
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: ${theme.zIndex.zIndexHigh};
        `}
    `;

    return (
        <div css={style}>
            {confirmationLayer}
        </div>
    );
};

export default ConfirmationPopup;