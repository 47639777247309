import React from "react";
import { css, Theme, useTheme } from "@emotion/react";
import pluralize from "pluralize";

import HeaderTitle from "pages/Components/CartAndCheckout/components/HeaderTitle";

type Props = {
    itemCount: number,
};

const HeaderItemCount: React.FC<Props> = ({ itemCount }) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        align-items: center;
        margin-right: 7px;
        color: ${theme.colours.curiousBlue2};

        ${theme.breakpoints.down('xs')} {
            flex-direction: column;
            align-items: end;
            margin-right: 0;
        }
    `;

    const totalStyle = css`
        margin-right: 17px;
        font-size: 14px;
        line-height: 17px;

        ${theme.breakpoints.down('xs')} {
            margin-right: 0;
            margin-bottom: 3px;
        }
    `;

    return (
        <div css={style}>
            <span css={totalStyle}>Total</span>
            <HeaderTitle>{pluralize('item', itemCount, true)}</HeaderTitle>
        </div>
    );
};

export default HeaderItemCount;