import React, { memo, useMemo } from "react";
import { css, SerializedStyles } from "@emotion/react";

import LinkTo from "core/Components/LinkTo";
import Loading from "core/Components/Utils/Loading";
import { NOOP } from "core/constants";
import useBreakpoint from "core/hooks/useBreakpoint";
import { isDown, isUp } from "theme/breakpoints";
import theme from "theme/index";


type Props = {
    children: React.ReactNode,
    onButtonClick?: (e: React.MouseEvent) => void,
    linkURL?: string,
    className?: string,
    backgroundColour?: string,
    textColour?: string,
    textFontSize?: number,
    buttonOpacity?: number,
    isTransparentButton?: boolean,
    buttonTextCustomStyle?: SerializedStyles,
    customFontWeight?: string|number,
    buttonHeight?: number,
    buttonWidth?: number,
    buttonSidePadding?: number,
    buttonBorderRadius?: number,
    onLinkClick?: Function,
    type?: "button" | "submit" | "reset",
    target?: string,
    borderColour?: string,
    buttonHoverColour?: string,
    forceLoading?: boolean,
    disabled?: boolean,
    useTextHover?: boolean,
}

const RoundButton: React.FC<Props> = ({
    children,
    onButtonClick = NOOP,
    linkURL = "",
    className = "",
    backgroundColour = theme.colours.cerulean,
    textColour = theme.colours.black,
    textFontSize = 20,
    isTransparentButton = false,
    buttonOpacity = 1,
    buttonTextCustomStyle,
    customFontWeight = theme.fonts.weights.bold,
    buttonHeight = null,
    buttonWidth = 0,
    buttonSidePadding = 22,
    buttonBorderRadius = 26,
    onLinkClick = NOOP,
    type = "button",
    target = "_blank",
    borderColour = "",
    buttonHoverColour = "",
    forceLoading = false,
    disabled = false,
    useTextHover = true,
}) => {
    const { breakpoint } = useBreakpoint();
    const height = useMemo(() => {
        if (buttonHeight) return buttonHeight;

        switch(true) {
            case isUp(breakpoint, '5xl'):
                return 54;
            case isDown(breakpoint, 'sm'):
                return 38;
            default:
                return 42;
        }
    }, [breakpoint, buttonHeight]);

    const loadingNode = useMemo(() => <Loading small ringIndicator onTop overlayRingIndicator />, []);

    const style = css`
        display: flex;
        position: relative;
        align-items: center;
        padding: 0;
        border: 0;
        min-height: ${height}px;
        height: ${height}px;
        width: fit-content;
        background: none;
        z-index: ${theme.zIndex.zIndexOne};
        transition: all 0.3s ease-in-out;
        
        ${(disabled || forceLoading) && css`
            pointer-events: none;
        `}
        
        ${buttonWidth && css`
            width: ${buttonWidth}px;
        `}

        ${useTextHover && css`
            &:hover {
                filter: brightness(90%);
            }
        `}
    `;

    const buttonStyle = css`
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        ${!(disabled || forceLoading) && css`cursor: pointer;`}

        font-size: ${theme.fonts.buttonBaseSize};
        font-weight: ${customFontWeight};

        background-color: ${backgroundColour};
        opacity: ${(disabled || forceLoading) ? "0.5" : buttonOpacity};
        border-radius: ${buttonBorderRadius}px;
        box-shadow: ${theme.borderAndShadow.boxShadow5};
        transition: all 0.3s ease-in-out;

        ${isTransparentButton && css`
            background-color: unset;
            border: 2px solid ${textColour};
        `};
        
        ${borderColour && css`
            border: 2px solid ${borderColour};
        `};

        &:hover {
            transition: all 0.3s ease-in-out;
            ${buttonHoverColour && css`
                background-color: ${buttonHoverColour};
            `}
        }
    `;

    const buttonTextStyle = css`
        position: relative;
        white-space: pre;
        text-align: center;
        font-size: ${textFontSize}px;
        line-height: ${textFontSize}px;
        color: ${textColour};
        padding: 0 ${buttonSidePadding}px;
        margin: auto;

        ${buttonTextCustomStyle};
    `;

    const rectangularButtonNode = (
        <div className={"text-button"} css={buttonStyle}>
            <div css={buttonTextStyle}>{children}</div>
        </div>
    );

    if (onButtonClick !== NOOP || type === "submit" || type === "reset") {
        return (
            <button css={style} onClick={onButtonClick} className={className} type={type}>
                {forceLoading && loadingNode}
                {rectangularButtonNode}
            </button>
        )
    }

    return (
        <LinkTo css={style} to={linkURL} className={className} onClick={onLinkClick} target={target}>
            {rectangularButtonNode}
        </LinkTo>
    );
};

export default memo(RoundButton);
