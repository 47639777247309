import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
};

const SummaryRowsContainer: React.FC<Props> = ({ children }) => {
    const theme: Theme = useTheme();

    const style = css`
        ${theme.breakpoints.only('md')} {
            width: 305px;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default SummaryRowsContainer;