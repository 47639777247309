import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import RoundButton from "core/Components/Buttons/RoundButton";

type Props = {
    inventoryPolicyIsContinue?: boolean,
    inventoryItemTracked?: boolean,
    inventoryQuantity?: number,
    quantity: number,
    width: number,
    addToCartHandler: () => void,
};

const ProductItemCartPopupAddButton: React.FC<Props> = (
    {
        inventoryPolicyIsContinue = true,
        inventoryItemTracked = false,
        inventoryQuantity = 0,
        quantity,
        width,
        addToCartHandler
    }
) => {
    const theme: Theme = useTheme();

    const isClickable = (!inventoryItemTracked || inventoryPolicyIsContinue) || quantity <= inventoryQuantity;

    const style = css`
        margin-top: 8px;
    `;

    return (
        <RoundButton
            css={style}
            textFontSize={17}
            textColour={'white'}
            buttonWidth={width}
            buttonHeight={49}
            backgroundColour={theme.colours.curiousBlue2}
            buttonHoverColour={theme.colours.malibu3}
            onButtonClick={addToCartHandler}
            useTextHover={false}
            disabled={!isClickable}
        >
            Add to cart
        </RoundButton>
    );
}

export default ProductItemCartPopupAddButton;