import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
    headerHeight: number,
};

const CartPopupContent: React.FC<Props> = ({ children, headerHeight }) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        flex-direction: column;
        padding: 0 28px 0 12px;
        width: calc(100% - 22px);
        height: calc(100% - ${headerHeight}px);
        background-color: ${theme.colours.grey[90]};
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default CartPopupContent;