import React from "react";
import { useTheme, css } from "@emotion/react";
import { getCurrencyByCountry, getTaxLabel } from "core/includes/finance";

type Props = {
    subtotal: number|string,
    taxAmount: number|string,
    totalAmount: number|string,
    shipping?: number|string,
    countryCode?: CountryCodeItem,
    isShippingTaxIncluded?: boolean,
};

const OrderSummaryTable: React.FC<Props> = (
    {
        subtotal,
        taxAmount,
        totalAmount,
        shipping = 0,
        countryCode,
        isShippingTaxIncluded = false,
    }
) => {
    const theme = useTheme();
    const currency = getCurrencyByCountry(countryCode);

    const totalStyle = css`
        font-weight: ${theme.fonts.weights.bold};
        color: ${theme.colours.curiousBlue};
    `;

    const rightAlignedRowStyle = css`
        text-align: right;
    `;

    return (
        <>
            <tr>
                <td colSpan={3}>
                    <hr />
                </td>
            </tr>
            <tr>
                <td>Subtotal</td>
                <td></td>
                <td css={rightAlignedRowStyle}>{currency}{subtotal}</td>
            </tr>
            {!isShippingTaxIncluded && !!shipping && (
                <tr>
                    <td>Shipping</td>
                    <td></td>
                    <td css={rightAlignedRowStyle}>{currency}{shipping}</td>
                </tr>
            )}
            <tr>
                <td>{getTaxLabel(countryCode)}</td>
                <td></td>
                <td css={rightAlignedRowStyle}>{currency}{taxAmount}</td>
            </tr>
            {!!shipping && isShippingTaxIncluded && (
                <tr>
                    <td>Shipping</td>
                    <td></td>
                    <td css={rightAlignedRowStyle}>{currency}{shipping}</td>
                </tr>
            )}
            <tr css={totalStyle}>
                <td>Total</td>
                <td></td>
                <td css={rightAlignedRowStyle}>{currency}{totalAmount}</td>
            </tr>
        </>
    );
};

export default OrderSummaryTable;
