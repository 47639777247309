import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import ItemDescription from "pages/Components/CartAndCheckout/components/Item/ItemDescription";
import { NOOP } from "core/constants";

type Props = {
    title: string,
    description?: string,
    itemBoxDescriptionWidth: number,
    itemBoxDescriptionLeftRightMargin: number,
    truncateLineLimit?: number,
    onTitleClick?: () => void,
    onDescriptionClick?: () => void,
};

const ItemDescriptionContainer: React.FC<Props> = (
    {
        title,
        description = undefined,
        itemBoxDescriptionWidth,
        itemBoxDescriptionLeftRightMargin,
        truncateLineLimit = undefined,
        onTitleClick = NOOP,
        onDescriptionClick = NOOP,
    }
) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 ${itemBoxDescriptionLeftRightMargin}px;
        width: ${itemBoxDescriptionWidth}px;

        ${theme.breakpoints.up('3xl')} {
            padding-bottom: 20px;
        }

        ${theme.breakpoints.only('xl')} {
            padding-bottom: 20px;
        }
    `;

    const titleStyle = css`
        font-size: 18px;
        line-height: 22px;
        font-weight: ${theme.fonts.weights.bold};
        color: ${theme.colours.grey[900]};
        cursor: pointer;

        ${theme.breakpoints.only('xl')} {
            padding-bottom: 4px;
        }

        ${theme.breakpoints.between('sm', 'xl')} {
            font-size: 16px;
            line-height: 20px;
        }

        ${theme.breakpoints.down('xs')} {
            font-size: 17px;
            line-height: 23px;
        }
    `;

    const descriptionStyle = css`
        ${onDescriptionClick !== NOOP && css` cursor: pointer; `};

        ${theme.breakpoints.down('xs')} {
            display: none;
        }
    `;

    return (
        <div css={style}>
            <div css={titleStyle} onClick={onTitleClick}>{title}</div>
            <div css={descriptionStyle} onClick={onDescriptionClick}>
                <ItemDescription
                    description={description}
                    truncateLineLimit={truncateLineLimit}
                    hideTargetBreakpointTo={'xs'}
                />
            </div>
        </div>
    );
};

export default ItemDescriptionContainer;