import React, { ChangeEvent } from "react";
import { css, Theme, useTheme } from "@emotion/react";
import OutsideClickHandler from "react-outside-click-handler";

import ProductItemCartPopupAddButton from "pages/Components/ProductListPage/components/ProductItemCartPopupAddButton";
import ProductItemCartPopupPrice from "pages/Components/ProductListPage/components/ProductItemCartPopupPrice";
import QuantityPlusMinusButton from "core/Components/Buttons/QuantityButton/QuantityPlusMinusButton";

type Props = {
    selectedProductId?: string,
    price: number,
    isTaxable: boolean,
    inventoryQuantity?: number,
    quantity: number,
    maxQuantity: number,
    innerWidth: number,
    cartPopupHandler: (productId?: string) => void,
    addToCartHandler: () => void,
    onPlusIconClick: () => void,
    onMinusIconClick: () => void,
    onCustomQuantity: (quantityElement: ChangeEvent<HTMLInputElement>) => void,
    isCurrent: boolean,
};

const ProductItemCartPopup: React.FC<Props> = (
    {
        price,
        isTaxable = false,
        inventoryQuantity = 0,
        quantity,
        maxQuantity,
        innerWidth,
        cartPopupHandler,
        addToCartHandler,
        onPlusIconClick,
        onMinusIconClick,
        onCustomQuantity,
        isCurrent,
    }
) => {
    const theme: Theme = useTheme();

    const style = css`
        position: absolute;
        display: flex;
        align-items: center;
        flex-direction: column;
        top: -28px;
        right: -23px;
        padding: 17px 0 16px;
        min-width: 207px;
        min-height: 178px;
        border-radius: 11px;
        box-shadow: ${theme.borderAndShadow.dropShadow};
        background-color: white;
        z-index: 1;

        ${theme.breakpoints.up('3xl')} {
            right: -40px;
        }

        ${theme.breakpoints.only('xl')} {
            top: -23px;
            right: -27px;
        }

        ${theme.breakpoints.only('lg')} {
            top: -26px;
            right: -26px;
        }

        ${theme.breakpoints.only('md')} {
            top: -26px;
            right: -23px;
            min-width: 193px;
            min-height: 179px;
        }
    `;

    const outsideClick = () => {
        cartPopupHandler(undefined);
    };

    return isCurrent ? (
        <OutsideClickHandler onOutsideClick={outsideClick}>
            <div css={style}>
                <ProductItemCartPopupPrice
                    price={price * quantity}
                    isTaxable={isTaxable}
                    width={innerWidth}
                />
                <QuantityPlusMinusButton
                    quantity={quantity}
                    maxQuantity={maxQuantity}
                    onPlusIconClick={onPlusIconClick}
                    onMinusIconClick={onMinusIconClick}
                    onCustomQuantity={onCustomQuantity}
                    addToCartHandler={addToCartHandler}
                />
                {/* Button (Add to cart) */}
                <ProductItemCartPopupAddButton
                    inventoryQuantity={inventoryQuantity}
                    quantity={quantity}
                    width={innerWidth}
                    addToCartHandler={addToCartHandler}
                />
            </div>
        </OutsideClickHandler>
    ) : null;
}

export default ProductItemCartPopup;