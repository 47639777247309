import { css } from "@emotion/react";
import transitions from "./transitions";
import { fonts } from "./fonts";
import colours from "./colours";
import transform from "theme/transform";

const bloopTransitionProperty = (transitionTime?: string) => css`
    transition: transform ${transitionTime || transitions.transitionFaster};
    will-change: transform;
`;

const bloopScaleTransitionProperty = css`
    transition: scale ${transitions.transitionFaster};
    will-change: scale;
`;

const forceScrollbar = css`
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: ${colours.gold};
        -webkit-box-shadow: 0 0 1px ${colours.gold};
    }
`;

const mixins = {
    transformedBloop: (extraTransform?: string, transitionTime?: string, transformFirst?: boolean) => css`
        ${bloopTransitionProperty(transitionTime)};

        &:hover {
            transform: ${transformFirst ? `${extraTransform} ${transform.bloopScale}` : `${transform.bloopScale} ${extraTransform}`};
        }
    `,
    bloop: css`
        ${bloopTransitionProperty()}
        &:hover {
            transform: ${transform.bloopScale};
        }
    `,
    bloopScale: `
        transform: ${transform.bloopScale};
    `,

    placeholderAttributes: (content?: any) => `
        font-family: ${fonts.frutiger};
        font-weight: ${fonts.weights.light};
        color: ${colours.grey[450]};
        opacity: 1; /** Safari / Firefox **/

        ${content};
    `,

    placeholder: (content?: any) => `
        &::placeholder { /** Chrome **/
            ${mixins.placeholderAttributes(content)};

            /** We put @content after the above include, so that the @content can override **/
            ${content};
        }

        /** This css doesn't work with the list below **/
        &::-webkit-input-placeholder, /** Opera/Safari **/
        &::-moz-placeholder, /** Firefox 19+ **/
        &:-moz-placeholder { /** Firefox 18- **/
            ${mixins.placeholderAttributes(content)};

            /** We put @content after the above include, so that the @content can override **/
            ${content};
        }

        /** IE: This css doesn't work with the list above **/
        &:-ms-input-placeholder {
            ${mixins.placeholderAttributes(content)};

            /** We put @content after the above include, so that the @content can override **/
            ${content};
        }

        /** Edge: This css doesn't work with the list above **/
        &::-ms-input-placeholder {
            ${mixins.placeholderAttributes(content)};

            /** We put @content after the above include, so that the @content can override **/
            ${content};
        }
    `,
    bloopScaleTransitionProperty,
    forceScrollbar,
};

export default mixins;