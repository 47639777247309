import React from "react";
import { Theme, useTheme, css } from "@emotion/react";

import Title from "core/Components/Title";
import ContentCombine from "core/Components/ContentCombine";

type Props = {
    children: React.ReactNode,
    currency?: string,
};

const PageHeader: React.FC<Props> = ({ children, currency = "" }) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        align-items: center;

        ${theme.breakpoints.down('sm')} {
            width: 100%;
            justify-content: space-between;
        }
    `;

    const headerStyle = css`
        font-weight: ${theme.fonts.weights.bold};
        color: ${theme.colours.codGray};
        font-size: 35px;
        line-height: 30px;

        ${theme.breakpoints.up("5xl")} {
            font-size: 40px;
            line-height: 41px;
        }

        ${theme.breakpoints.only("xl")} {
            font-size: 30px;
            line-height: 26px;
        }

        ${theme.breakpoints.down("lg")} {
            font-size: 25px;
            line-height: 21px;
        }
    `;

    const currencyStyle = css`
        color: ${theme.colours.silverChalice4};

        ${theme.breakpoints.up('xl')} {
            margin-left: 30px;
            padding-top: 8px;
        }

        ${theme.breakpoints.up('5xl')} {
            padding-top: 10px;
        }

        ${theme.breakpoints.between('3xl', '4xl')} {
            padding-top: 8px;
        }

        ${theme.breakpoints.between('md', 'lg')} {
            margin-left: 17px;
            padding-top: 3px;
        }

        ${theme.breakpoints.down('sm')} {
            padding-top: 6px;
        }
    `;

    return (
        <div css={style}>
            <Title css={headerStyle}>
                {children}
            </Title>

            {currency && (
                <ContentCombine Tag={'p'} fontSizeType={'21'} css={currencyStyle}>
                    *Price in {currency}
                </ContentCombine>
            )}
        </div>
    );
}

export default PageHeader;
