import React from "react";
import theme from "theme/index";

type Props = {
    className?: string,
    colour?: string,
    backgroundColour?: string,
};

const ArrowBox: React.FC<Props> = (
    {
        className = "",
        colour = "white",
        backgroundColour = theme.colours.curiousBlue,
    }
) => (
    <div className={className}>
        <svg viewBox="0 0 200 200">
            <g transform="translate(0 0)">
                <ellipse cx="78.72" cy="78.719" rx="78.72" ry="78.719" fill={`${backgroundColour}`}/>
                <path
                    d="M-6205.979-2449.7a7.839,7.839,0,0,1-4.543-1.452l-30.569-21.713a7.876,7.876,0,0,1-3.307-6.436c.087-18.615.171-48.473.073-53.682a7.233,7.233,0,0,1-.047-1.689,7.911,7.911,0,0,1,7.871-7.391c.1,0,.2,0,.3.006a7.837,7.837,0,0,1,7.532,7.53c.006.159.02.407.026.72.015.434.026,1.069.035,1.879.011,1.442.018,3.564.026,6.289.006,4.571.006,10.936-.02,18.926-.021,9.11-.061,18.3-.073,23.407l27.249,19.355a7.861,7.861,0,0,1,1.859,10.942A7.872,7.872,0,0,1-6205.979-2449.7Z"
                    transform="translate(6315.044 2567.198)" fill={`${colour}`}
                />
            </g>
        </svg>
    </div>
);

export default ArrowBox;