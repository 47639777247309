import { useMemo } from "react";
import { Theme, useTheme } from "@emotion/react";

import useBreakpointLib from 'use-breakpoint';
import breakpoints, { isBetween, isUp } from 'theme/breakpoints';

const useHeaderHeight = (
    {
        showBreadcrumb = false,
        hideHeaderCountry = true,
    }: {
        showBreadcrumb?: boolean,
        hideHeaderCountry?: boolean,
    }
) => {
    const theme: Theme = useTheme();
    const { breakpoint } = useBreakpointLib(breakpoints.sizes, "xxs");

    return useMemo(() => {
        switch (true) {
            case isUp(breakpoint, 'xl'):
                return theme.sizes.menu.heightMain + (showBreadcrumb ? theme.sizes.menu.breadcrumbHeight : 0);
            case isBetween(breakpoint, 'sm', 'lg'):
                return theme.sizes.menu.burgerHeightMain + (showBreadcrumb ? theme.sizes.menu.breadcrumbBurgerHeight : 0);
            default:
                return (
                    theme.sizes.menu.burgerHeightMain +
                    (hideHeaderCountry ? 0 : theme.sizes.menu.breadcrumbBurgerSmallHeight) +
                    (showBreadcrumb ? theme.sizes.menu.breadcrumbBurgerHeight : 0)
                );
        }
    }, [
        breakpoint,
        showBreadcrumb,
        hideHeaderCountry,
        theme.sizes.menu.heightMain,
        theme.sizes.menu.breadcrumbHeight,
        theme.sizes.menu.burgerHeightMain,
        theme.sizes.menu.breadcrumbBurgerHeight,
        theme.sizes.menu.breadcrumbBurgerSmallHeight
    ]);
};

export default useHeaderHeight;