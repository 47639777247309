import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
    width: number,
};

const CartPopupHeader: React.FC<Props> = ({ children, width }) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        align-items: center;
        padding: 15px 0 20px;
        width: ${width}px;
        border-bottom: 2px solid ${theme.colours.alto};

        ${theme.breakpoints.down("xs")} {
            padding: 12px 0 13px;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default CartPopupHeader;