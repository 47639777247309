import React, { memo } from "react";
import { css } from "@emotion/react";
import theme from "theme/index";

type Props = {
    center?: boolean,
    children?: React.ReactNode,
    className?: string,
};

const DialogContent: React.FC<Props> = ({ center, children, className }) => {
    const styles = css`
        ${center ? css`
            text-align: center;
        ` : css`
            text-align: left;
        `}
        
        fontFamily: ${theme.fonts.frutiger};
        font-size: 16px;
        line-height: 24px;
        color: ${theme.colours.doveGray2};
    `;

    return (
        <div css={styles} className={className}>{children}</div>
    );
};

DialogContent.defaultProps = {
    center: false,
};

export default memo(DialogContent);