import React from "react";
import { css, Theme, useTheme } from "@emotion/react";
import pluralize from "pluralize";

type Props = {
    itemCount: number,
};

const CartPopupSelectedItemCount: React.FC<Props> = ({ itemCount }) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        align-items: center;
        padding-left: 7px;
        height: 42px;
        font-size: 16px;
        font-weight: ${theme.fonts.weights.bold};
        border-bottom: 2px solid ${theme.colours.alto};
    `;

    return (
        <div css={style}>
            {pluralize('item', itemCount, true)}
        </div>
    );
};

export default CartPopupSelectedItemCount;