import React, { ElementType } from "react";
import { css } from "@emotion/react";

import useFontSize from "core/hooks/useFontSize";

type Props = {
    className?: string,
    children: React.ReactNode,
    marginTop?: number,
    padding?: string,
    textAlign?: string,
    Tag?: ElementType,
    fontSizeType?: string,
    backgroundColour?: string,
    useRadius?: boolean,
};

const Description: React.FC<Props> = (
    {
        className = '',
        children,
        marginTop = 0,
        padding = '',
        textAlign = '',
        Tag = 'div',
        fontSizeType = '',
        backgroundColour = '',
        useRadius = false,
    }
) => {
    const [fontSize, lineHeight] = useFontSize(Tag, fontSizeType, '', [14, 16]);

    const styles = css`
        ${marginTop > 0 && css`
            margin-top: ${marginTop}px;
        `}

        ${padding && css`
            padding: ${padding};
        `}

        ${textAlign && css`
            text-align: center;
        `}

        ${fontSize && css`
            font-size: ${fontSize}px;
        `}

        ${useRadius && css`
            border-radius: 21px;
        `}

        ${lineHeight && css`
            line-height: ${lineHeight}px;
        `}

        ${backgroundColour && css`
        background-color: ${backgroundColour};
    `}
    `;

    return (
        <Tag css={styles} className={className}>{children}</Tag>
    )
};

export default Description;