import React from "react";
import { Theme, useTheme, css } from "@emotion/react";

import ContentSection from "core/Components/ContentSection";

type Props = {
    children: React.ReactNode,
    isNoData?: boolean,
};

const ProductsSection: React.FC<Props> = ({ isNoData, children }) => {
    const theme: Theme = useTheme();

    const style = css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        width: 100%;
        margin: 54px 0;
        min-height: 442px;

        ${isNoData && css`
            display: flex;
        `};

        ${theme.breakpoints.up("5xl")} {
            margin: 74px 0;
            min-height: 608px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            margin: 65px 0;
            min-height: 500px;
        }

        ${theme.breakpoints.only("xl")} {
            margin: 48px 0;
            min-height: 390px;
        }

        ${theme.breakpoints.only("lg")} {
            grid-template-columns: 1fr 1fr 1fr;
            margin: 40px 0;
            min-height: 440px;
        }

        ${theme.breakpoints.only("md")} {
            grid-template-columns: 1fr 1fr 1fr;
            margin: 47px 0;
            min-height: 326px;
        }

        ${theme.breakpoints.only("sm")} {
            grid-template-columns: 1fr;
            margin: 30px 0;
            min-height: 416px;
        }

        ${theme.breakpoints.down("xs")} {
            grid-template-columns: 1fr;
            margin: 28px 0;
            min-height: 347px;
        }
    `;

    return (
        <ContentSection css={style}>
            {children}
        </ContentSection>
    );
}

export default ProductsSection;
