/**
 * Return the current URLSegment
 */
const getURLSegment = (path: string) => {
    const segments = getURLSegments(path);

    if (segments[segments.length - 1] === '') {
        return 'home';
    }

    return segments[segments.length - 1];
};

/**
 * Return the URLSegments including the current URLSegment
 * @param path
 */
const getURLSegments = (path: string) => {
    let strSegments = path.startsWith('/') ? path.substring(1) : path;
    strSegments = strSegments.endsWith('/') ? strSegments.substring(0, strSegments.length - 1) : strSegments;

    return strSegments.split('/');
}

/**
 * Return the URLSegments including the current URLSegment
 * @param path
 */
const getURLSegmentsString = (path: string) => {
    let strSegments = path.startsWith('/') ? path.substring(1) : path;
    return strSegments.endsWith('/') ? strSegments.substring(0, strSegments.length - 1) : strSegments;
}

/**
 * The formatter for the all menu of Footer
 * @param allFooterMenu
 */
const getFooterMenu = (allFooterMenu: [StandardPage]) => {
    const resultMenu: any = {
        menu: [],
        side: [],
        bottom: [],
        all: allFooterMenu,
    };

    allFooterMenu.forEach((menu: StandardPage) => {
        // Set header menu on footer
        if (menu.showInFooter === 'menu') {
            // Root level (level-1) menu
            if (menu.parentID === 0) {
                let id = menu.id.toString();
                resultMenu.menu.push({ ...{ [id]: [menu] } });
                // Set submenu that is involved each header menu on footer
            } else {
                resultMenu.menu.forEach((rootMenu: any) => {
                    // Set submenu into parent menu array
                    if (rootMenu[menu.parentID]) {
                        rootMenu[menu.parentID].push({ ...menu });
                    }
                });
            }

            // Set footer side menu
        } else if (menu.showInFooter === 'side') {
            resultMenu.side.push(menu);

            // Set footer bottom text link
        } else {
            resultMenu.bottom.push(menu);
        }
    });

    return resultMenu;
};

/**
 * All decimals are removed when a number only contains them as 00 e.g. 50.00.
 * Number is formatted to have 2 decimals if it contains a decimal that is not a zero e.g. 50.03. 50.3
 * @param number
 */
const improveDecimals = (number: string|number) => {
    if (typeof number === "number") {
        return Number(number.toFixed(2).replace(/[.]00$/, ""));
    }
    return Number(number.replace(/[.]00$/, ""));
};

const convertNumberToHaveDecimals = (number: number, numOfDecimals: number = 2) => {
    return number.toFixed(numOfDecimals);
};

/**
 * Return the price by the defined condition
 * @param price
 * @param useInteger
 */
const getPrice = (price: number | string | null, useInteger: boolean = false) => {
    let value: any = useInteger ? 0 : 0.00;

    if (price) {
        if (typeof price === 'number') {
            value = (useInteger && (price % 1 === 0)) ? Math.floor(price) : price.toFixed(2);
        } else {
            const tempPrice = parseFloat(price);
            value = (useInteger && (tempPrice % 1 === 0)) ? Math.floor(tempPrice) : tempPrice.toFixed(2);
        }
    }

    return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};

const removeHTML = (htmlText: string) => {
    return htmlText.replace(/<[^>]*>?/g, '');
};

export const formatAndAddDecimals = (number: number, decimalsNum: number = 0) => {
    let numberString = number.toFixed(decimalsNum);
    const [integerPart, decimalPart] = numberString.split('.');

    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (decimalsNum) {
        return `${formattedIntegerPart}.${decimalPart}`;
    }

    return formattedIntegerPart;
}

export {
    getURLSegment,
    getURLSegments,
    getURLSegmentsString,
    getFooterMenu,
    improveDecimals,
    convertNumberToHaveDecimals,
    getPrice,
    removeHTML,
};
