import document from 'core/includes/document';

const MOBILE_WIDTH = 768;

export const getScrollBarWidth = (el: HTMLElement|null) => el ? el.offsetWidth - el.clientWidth : 0;

export function offset(el: HTMLElement | null) {
    // return offset(el).top;
    let distanceX = 0;
    let distanceY = 0;

    // Loop up the DOM
    if (el && el.offsetParent) {
        do {
            distanceY += el.offsetTop;
            distanceX += el.offsetLeft;
            el = el.offsetParent as HTMLElement;
        } while (el);
    }

    // Return our distance
    return {
        top: distanceY,
        left: distanceX
    };
}

export function windowScrollTop() {
    return Math.max(document.documentElement.scrollTop, document.body.scrollTop);
}


export class DOMUtils {

    /**
     * Stops the body behind the dialog scrolling when the end of the dialog is reached.
     */
    static preventBodyScrolling() {
        document.body.style.overflow = "hidden";
        document.body.style.position = "fixed";
        document.body.style.width = "100%";
    }

    /**
     * Renables normal scrolling behaviour for the body.
     */
    static enableBodyScrolling() {
        document.body.style.removeProperty("overflow");
        document.body.style.removeProperty("position");
        document.body.style.removeProperty("width");
    }

    /**
     * Returns true if window width is less than MOBILE_WIDTH.
     *
     * @returns {boolean}
     */
    static isMobile() {
        return DOMUtils.windowWidth() < MOBILE_WIDTH;
    }

    /**
     * Returns true if the device supports touch.
     *
     * @returns {boolean}
     */
    static isTouch() {
        return "ontouchstart" in document.documentElement;
    }

    /**
     * Get the offset of an element from the top left of the document.
     *
     * @param el
     * @returns {{top: number, left: number}}
     */
    static offset(el: HTMLElement | null) {
        // return offset(el).top;
        let distanceX = 0;
        let distanceY = 0;

        // Loop up the DOM
        if (el && el.offsetParent) {
            do {
                distanceY += el.offsetTop;
                distanceX += el.offsetLeft;
                el = el.offsetParent as HTMLElement;
            } while (el);
        }

        // Return our distance
        return {
            top: distanceY,
            left: distanceX
        };
    }

    /**
     * Get the scroll top position of the window.
     *
     * @returns {number}
     */
    static windowScrollTop() {
        return Math.max(document.documentElement.scrollTop, document.body.scrollTop);
    }

    /**
     * Check whether an element is in the viewport.
     *
     * @param el
     *
     * @returns {boolean}
     */
    static inViewport(el: HTMLElement) {

        if (!el) {
            return false;
        }

        const rect = el.getBoundingClientRect();

        const windowHeight = DOMUtils.windowHeight();
        const windowWidth = DOMUtils.windowWidth();

        const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
        const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

        return (vertInView && horInView);
    }

    /**
     * Gets the width the of browser window.
     *
     * @returns {number}
     */
    static windowWidth() {
        return window.innerWidth || document.documentElement.clientWidth;
    }

    /**
     * Gets the height of the browser window.
     *
     * @returns {number}
     */
    static windowHeight() {
        return window.innerHeight || document.documentElement.clientHeight;
    }

    static scrollToFirstMessage() {
        let errors = document.querySelectorAll("[role=alert],[role=status]");

        if (errors.length) {
            let firstError = errors[0] as HTMLElement;
            window.scrollTo({
                top: offset(firstError).top - 80,
                behavior: "smooth"
            });
        }
    }
}