export const getCurrencyByCountry = (country?: CountryCodeItem) => {
    if (country?.currency) {
        return country.currency;
    }

    switch (country?.code) {
        case 'gb':
            return '£';
        default:
            return '$';
    }
}

export const getTaxRateByCountry = (country?: CountryCodeItem) => {
    return getTaxRateToShow(country) / 100;
}

export const getTaxRateToShow = (country?: CountryCodeItem) => {
    if (country?.taxRate) {
        return parseFloat(country.taxRate);
    }

    switch (country?.code) {
        case 'au':
            return 10;
        case 'gb':
            return 20;
        case 'nz':
        default:
            return 15;
    }
}

export const getTaxLabel = (country?: CountryCodeItem) => {
    if (country?.taxLabel) {
        return country.taxLabel;
    }

    switch (country?.code) {
        case 'gb':
            return 'VAT';
        default:
            return 'GST';
    }
}

const f = {
    getCurrencyByCountry,
    getTaxRateByCountry,
    getTaxRateToShow,
    getTaxLabel,
};

/**
 * @param isTaxable
 * @param taxLabel
 */
export const taxLabelWithTaxable = (isTaxable: boolean, taxLabel: string) => {
    return (isTaxable ? "excl. " : "incl. ") + taxLabel;
};

export default f;