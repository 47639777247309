import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
    itemBoxImageHeight: number,
};

const ItemBoxContainer: React.FC<Props> = ({ children, itemBoxImageHeight }) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        width: 100%;
        min-height: ${itemBoxImageHeight}px;

        ${theme.breakpoints.down('xs')} {
            min-height: 219px;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default ItemBoxContainer;