import React from "react";
import { css, useTheme } from "@emotion/react";

import Image from "core/Components/Image";

type Props = {
    storageItem?: CartProduct,
    imageUrl: string|null,
    itemBoxImageWidth: number,
    itemBoxImageHeight: number,
    linkTo: (id: string) => void,
};

const ItemImage: React.FC<Props> = (
    {
        storageItem = undefined,
        imageUrl,
        itemBoxImageWidth,
        itemBoxImageHeight,
        linkTo,
    }
) => {
    const theme = useTheme();
    const urlSegment = storageItem?.urlSegment ?? ''

    // Move to the detail page
    const onClickHandler = () => {
        linkTo(urlSegment);
    };

    const style = css`
        width: ${itemBoxImageWidth}px;
        height: ${itemBoxImageHeight}px;
        flex-shrink: 0;
        border-radius: 12px 0 0 12px;
        cursor: pointer;

        ${theme.breakpoints.down('xs')} {
            border-radius: 12px;
        }
    `;

    return (
        <div css={style} onClick={onClickHandler}>
            {imageUrl ? (
                <Image image={imageUrl} useFocusPoint />
            ) : null}
        </div>
    );
};

export default ItemImage;