import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";
import TruncateWithTippy from "core/Components/TruncateWithTippy";

type Props = {
    children: ReactNode,
    id: string,
    linkTo: (id: string) => void,
    tippyContent?: string,
};

const CardItemTitle: React.FC<Props> = ({ children, id, linkTo, tippyContent = '' }) => {
    const theme: Theme = useTheme();

    const onClickHandler = () => {
        linkTo(id);
    };

    const style = css`
        margin-left: 10px;
        width: 150px;
        height: 67px;
        line-height: 18px;
        color: ${theme.colours.shipGray};

        ${theme.breakpoints.only("xl")} {
            width: 135px;
        }

        ${theme.breakpoints.between("sm", "lg")} {
            width: 122px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-left: 7px;
            width: 111px;
        }
    `;

    const spanStyle = css`
        cursor: pointer;
    `;

    return (
        <div css={style}>
            <TruncateWithTippy text={tippyContent} lines={4}>
                <span css={spanStyle} onClick={onClickHandler}>{children}</span>
            </TruncateWithTippy>
        </div>
    );
};

export default CardItemTitle;