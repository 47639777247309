import React from "react";
import { css } from "@emotion/react";

import Image from "core/Components/Image";

type Props = {
    id: string,
    url: string|null,
    linkTo: (id: string) => void,
};

const CartPopupItemImage: React.FC<Props> = ({ id, url, linkTo }) => {
    const onClickHandler = () => {
        linkTo(id);
    };

    const style = css`
        width: 67px;
        height: 67px;
        border-radius: 4px;
        cursor: pointer;
    `;

    return (
        <div css={style} onClick={onClickHandler}>
            {url ? (
                <Image image={url} useFocusPoint />
            ) : null}
        </div>
    );
};

export default CartPopupItemImage;