import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import Cart from "core/Components/Icons/Cart";

type Props = {
    availableForSale?: boolean,
    inventoryQuantity?: number
    cartPopupHandler?: () => void,
    isCurrent: boolean,
};

const ProductItemCartIcon: React.FC<Props> = (
    {
        availableForSale,
        inventoryQuantity,
        cartPopupHandler,
        isCurrent = false,
    }
) => {
    const theme: Theme = useTheme();

    // inventoryItemTracked: Track quantity, inventoryPolicyIsContinue: Continue selling when out of stock
    const allowForSale = !(!availableForSale || inventoryQuantity === null || inventoryQuantity === 0);

    // Open the cart & quantity popup
    const onClickHandler = () => {
        if (allowForSale && cartPopupHandler) {
            cartPopupHandler();
        }
    };

    const style = css`
        display: flex;
        justify-content: end;
        width: 100%;
    `;

    const roundStyle = css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 47px;
        height: 47px;
        border-radius: 24px;

        ${theme.breakpoints.only('xl')} {
            width: 38px;
            height: 38px;
        }

        ${theme.breakpoints.only('md')} {
            width: 30px;
            height: 30px;
        }

        ${allowForSale && css`
            cursor: pointer;

            ${isCurrent ? css`
                background-color: ${theme.colours.carnationPink};

                > div > svg > path {
                    fill: white;
                }
            ` : css`
                &:hover {
                    background-color: ${theme.colours.carnationPink};

                    > div > svg > path {
                        fill: white;
                    }
                }
            `}
        `}
    `;

    // Cart icon size
    const cartStyle = css`
        width: 28px;
        height: 28px;

        ${theme.breakpoints.only('xl')} {
            width: 24px;
            height: 24px;
        }

        ${theme.breakpoints.only('md')} {
            width: 22px;
            height: 22px;
        }
    `;

    return (
        <div css={style}>
            <div css={roundStyle} onClick={onClickHandler}>
                <Cart
                    {...!allowForSale && { colour: theme.colours.silver }}
                    css={cartStyle}
                />
            </div>
        </div>
    );
}

export default ProductItemCartIcon;