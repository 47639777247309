import { useMemo } from "react";
import { Theme, useTheme } from "@emotion/react";

import useBreakpointLib from 'use-breakpoint';
import breakpoints, { isBetween, isOnly, isUp } from 'theme/breakpoints';

const useChevron = (type: string = '') => {
    const theme: Theme = useTheme();
    const { breakpoint } = useBreakpointLib(breakpoints.sizes, "xxs");

    const chevronColour = theme.colours.white;
    const chevronBackground = theme.colours.grey[500];
    const chevronBackgroundHover = theme.colours.azureRadiance;

    const chevronSize = useMemo(() => {
        switch (true) {
            case isUp(breakpoint, '5xl'):
                return 41;
            case isBetween(breakpoint, 'xxl', '4xl'):
                return 32;
            case type === 'cart' && isOnly(breakpoint, 'xl'):
                return 32;
            case type !== 'cart' && isOnly(breakpoint, 'xl'):
                return 28;
            case type === 'cart' && isBetween(breakpoint, 'md', 'lg'):
                return 26;
            case type === 'cart' && isOnly(breakpoint, 'sm'):
                return 22;
            default:
                return 24;
        }
    }, [type, breakpoint]);

    return {
        chevronSize,
        chevronColour,
        chevronBackground,
        chevronBackgroundHover
    };
};

export default useChevron;