import React from "react";
import { css, Theme, useTheme } from "@emotion/react";
import CartWithCircles from "core/Components/Icons/CartWithCircles";

const EmptyCart: React.FC = () => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 248px;
        height: 248px;
        border-radius: 124px;
        background-color: white;

        ${theme.breakpoints.down("xl")} {
            width: 190px;
            height: 190px;
            border-radius: 95px;
        }
    `;

    const cartStyle = css`
        width: 117px;

        ${theme.breakpoints.down("xl")} {
            width: 90px;
        }
    `;

    const textStyle = css`
        width: 130px;
        font-size: 17px;
        line-height: 20px;
        text-align: center;

        ${theme.breakpoints.down("xl")} {
            width: 105px;
            font-size: 14px;
            line-height: 16px;
        }
    `;

    return (
        <div css={style}>
            <CartWithCircles css={cartStyle} />
            <div css={textStyle}>Your cart is currently empty</div>
        </div>
    );
};

export default EmptyCart;