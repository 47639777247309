export const hasFeatureFlagBasedOnCountryCode = (countryCode = "", featureFlags = [], featureFlag = "") => {
    if (!featureFlags) {
        return false;
    }

    let hasFeatureFlag = false;
    featureFlags.forEach((featFlag: string) => {
        // if our CMS feature flag and comparing to feature flag is the same, OR
        if (featFlag === featureFlag ||
            // if our CMS feature flag starts with * and it would match to passed feature flag prefixed with *, OR
            (featFlag.startsWith("*") && featFlag === `*${featureFlag}`) ||
            // if our CMS feature flag starts with a country code, and it would match to passed feature flag prefixed with country code
            (countryCode !== "" && featFlag.startsWith(countryCode) && featFlag === `${countryCode}${featureFlag}`)
        ) {
            hasFeatureFlag = true
            return;
        }
    });

    return hasFeatureFlag;
}

/**
 * Checks if a feature flag is part of the feature flags.
 *
 * @param featureFlags
 * @param featureFlag
 */
export const hasFeatureFlag = (featureFlags: string[] = [], featureFlag = "") => {
    if (!featureFlag) return false;
    return featureFlags.includes(featureFlag);
}

const c = {
    hasFeatureFlagBasedOnCountryCode
};

export default c;