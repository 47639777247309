import React, { ReactNode } from "react";
import { css } from "@emotion/react";

type Props = {
    children: ReactNode,
    cartStatus?: 'empty'|'both'|'event'|'product'
};

const ItemGroupSection: React.FC<Props> = ({ children, cartStatus = undefined }) => {
    const style = css`
        display: flex;
        flex-direction: column;

        ${cartStatus === 'product' && css`
            flex-direction: column-reverse;
        `}
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default ItemGroupSection;