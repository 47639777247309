import React, { memo, useCallback, useEffect } from "react";
import { css, SerializedStyles, useTheme } from "@emotion/react";
import FocusLock from "react-focus-lock";

import { DOMUtils } from "core/includes/domUtils";
import Portal from "core/Components/Portal";

type SizeType = "small" | "medium" | "max";

type Props = {
    children: React.ReactNode,
    innerRef?: () => void,
    onClose?: (e: React.MouseEvent) => void,
    onOverlayClick?: () => void,
    overlay?: boolean,
    size?: SizeType,
    showCloseButton?: boolean,
    extraStyle?: SerializedStyles,
};

const Dialog: React.FC<Props> = ({
    children,
    innerRef,
    onClose,
    onOverlayClick,
    overlay,
    showCloseButton,
    size,
    extraStyle,
}) => {
    const theme = useTheme();

    /**
     * Disable body scrolling on mobile.
     */
    useEffect(() => {
        if (DOMUtils.isMobile()) {
            DOMUtils.preventBodyScrolling();
        }

        return () => {
            if (DOMUtils.isMobile()) {
                DOMUtils.enableBodyScrolling();
            }
        };
    }, []);

    /**
     * Handle close button click.
     */
    const handleClose = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (typeof onClose === "function") {
            onClose(e);
        } else if (typeof onOverlayClick === "function") {
            onOverlayClick();
        }
    }, [onClose, onOverlayClick]);

    const closeText = size === "max" ? "CLOSE" : "x";

    const overlayStyles = css`
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
    `;

    const containerStyles = (size?: SizeType, extraStyle?: SerializedStyles) => css`
        ${theme.popover.container};
        max-width: 80%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 101;
        border-radius: 25px;
        
        ${theme.breakpoints.up("5xl")} {
            width: 752px;
            padding: 61px 46px 55px;
        }

        ${theme.breakpoints.between("md", "4xl")} {
            width: 608px;
        }

        ${theme.breakpoints.between("3xl", "4xl")} {
            padding: 40px 38px 36px;
        }

        ${theme.breakpoints.only("xxl")} {
            padding: 35px 38px 36px;
        }

        ${theme.breakpoints.between("md", "xl")} {
            padding: 30px 37px 29px;
        }

        ${theme.breakpoints.down("sm")} {
            padding: 15px 18px 22px;
        }

        ${theme.breakpoints.only("sm")} {
            width: 398px;
        }

        ${theme.breakpoints.down("xs")} {
            max-width: 332px;
        }
    
        ${extraStyle};
    `;

    const closeStyles = css`
        position: absolute;
        right: 0;
        top: 0;
        border-width: 0 0 1px 1px;
        border-color: ${theme.colours.grey[700]};
        border-style: solid;
        font-size: 16px;
        cursor: pointer;
        color: ${theme.colours.white};
        line-height: 20px;
        vertical-align: middle;
        text-align: center;
        display: block;
        width: 24px;
        height: 24px;
        background: ${theme.colours.grey[700]};
    
        &:hover {
            border-color: ${theme.colours.grey[500]};
            background: ${theme.colours.grey[500]};
        }
    `;

    return (
        <Portal id="dialog-portal">
            <FocusLock>
                {overlay && (
                    <div
                        css={overlayStyles}
                        onClick={onOverlayClick ? onOverlayClick : () => {
                        }}
                    />
                )}
                <div css={containerStyles(size, extraStyle)} ref={innerRef}>
                    {showCloseButton && <span css={closeStyles} onClick={handleClose}>{closeText}</span>}
                    {children}
                </div>
            </FocusLock>
        </Portal>
    );
};

Dialog.defaultProps = {
    overlay: true,
    showCloseButton: true,
};

export default memo(Dialog);