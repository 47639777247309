import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import Arrow from "core/Components/Icons/Arrow";
import { useAppState } from "core/contexts/AppContext";

type Props = {
    headerHeight: number,
};

const CartPopupCloseBar: React.FC<Props> = ({ headerHeight }) => {
    const theme: Theme = useTheme();
    const [, setAppState] = useAppState();

    const onClickHandler = () => {
        setAppState({
            isOpenCartPopup: false
        });
    };

    const style = css`
        display: flex;
        align-items: center;
        width: 22px;
        height: calc(100% - ${headerHeight}px);
        cursor: pointer;
        background-color: ${theme.colours.alto};
        
        &:hover {
            background-color: ${theme.colours.curiousBlue};
            
            > div > svg > path {
                fill: white;
            }
        }
    `;

    const arrowStyle = css`
        width: 20px;
        transform: rotate(-90deg);
    `;

    return (
        <div css={style} onClick={onClickHandler}>
            <Arrow colour={theme.colours.curiousBlue} css={arrowStyle} viewBox={'0 -1 17 17'} />
        </div>
    );
};

export default CartPopupCloseBar;