import React, { useState } from "react";
import { css, useTheme } from "@emotion/react";
import Tippy from "@tippy.js/react";
import TruncateWithMarkup from "react-truncate-markup";

import defaultTippyProps from "core/includes/tippy";

type Props = {
    lines?: number,
    text?: string,
    enabled?: boolean,
    children: React.ReactNode,
};

const TruncateWithTippy: React.FC<Props> = (
    {
        lines = undefined,
        text = '',
        enabled = true,
        children,
    }
) => {
    const [isTruncated, setIsTruncated] = useState(false);
    const theme = useTheme();

    const style = css`
        border-radius: 10px;
        padding: 10px;
        background-color: ${theme.colours.alto5};
        box-shadow: ${theme.borderAndShadow.boxShadow};

        ::before {
            display: block;
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%) rotate(135deg);
            height: 20px;
            width: 20px;
            top: -10px;
            background-color: ${theme.colours.alto5};
            box-shadow: -3px 3px 3px 0 rgba(6, 0, 0, 0.3);
        }
    `;

    if (!lines || !enabled) {
        return (
            <>{children}</>
        );
    }

    return (
        <Tippy
            css={style}
            content={text}
            hideOnClick={false}
            enabled={enabled && isTruncated}
            {...defaultTippyProps}
        >
            <div>
                <TruncateWithMarkup
                    lines={lines}
                    onTruncate={setIsTruncated}
                >
                    {children}
                </TruncateWithMarkup>
            </div>
        </Tippy>
    );
}

export default TruncateWithTippy;