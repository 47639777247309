import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
    itemGroupBoxWidth?: number,
};

const SummaryContainer: React.FC<Props> = ({ children, itemGroupBoxWidth = 0 }) => {
    const theme: Theme = useTheme();

    const style = css`
        margin-bottom: 45px;
        width: 425px;
        color: white;

        ${theme.breakpoints.only('lg')} {
            width: 350px;
        }

        ${theme.breakpoints.down('md')} {
            width: ${itemGroupBoxWidth}px;
        }

        ${theme.breakpoints.down('xs')} {
            width: 100%;
            padding: 27px 11px 0 12px;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default SummaryContainer;