import { useQuery } from "@apollo/client";

import { useAppState } from "core/contexts/AppContext";
import { getCanRenderWebp } from "core/includes/image";
import { productListQuery, commonProductDataQuery } from "core/includes/queries";

import {
    PRODUCT_PAGE_TYPE,
    PRODUCT_SUITABILITY_INIT_OPTION,
    PRODUCT_GROUP_INIT_OPTION,
} from "pages/Components/ProductListPage/includes/constants";
import { retrieveValue } from "core/includes/localStorage";
import { hasFeatureFlag } from "core/includes/site";
import { FLAG_SHOP_2 } from "core/includes/featureFlags";

const useProductListPageData = (
    pageData: ProductListPage,
    minPriceRange?: number,
    maxPriceRange?: number,
    productGroup?: Option,
    productSuitability?: Option,
    sortField?: string,
    sortDirection?: string,
) => {
    const [appState] = useAppState();
    const canRenderWebp = getCanRenderWebp();
    const hasShopFeatureFlag = hasFeatureFlag(appState.featureFlags, FLAG_SHOP_2);

    const countryCodeSelected = hasShopFeatureFlag ?
        appState.ipCountry.code :
        retrieveValue('selected-country')?.replace(/"/g, "") || appState.countryCode?.code.toLowerCase();

    // If Global is selected, show AU products.
    const country = countryCodeSelected === 'other' ? "au" : countryCodeSelected;

    const { data: commonData } = useQuery(commonProductDataQuery, {
        variables: {
            type: PRODUCT_PAGE_TYPE,
            parentId: pageData?.id,
        }
    });

    const { data, loading } = useQuery(productListQuery, {
        variables: {
            parentId: pageData?.id,
            minPriceRange: minPriceRange,
            maxPriceRange: maxPriceRange,
            productGroup: productGroup?.value || PRODUCT_GROUP_INIT_OPTION.value,
            suitability: productSuitability?.value || PRODUCT_SUITABILITY_INIT_OPTION.value,
            countryCode: country,
            sortField: sortField,
            sortDirection: sortDirection,
            canRenderWebp,
        }
    });

    const productList = data?.productList;

    const productGroupOptions = [PRODUCT_GROUP_INIT_OPTION];
    commonData?.productGroup.forEach((item: ProductGroupItem) => {
        productGroupOptions.push({ value: item.id, label: item.name });
    });

    const productSuitabilityOptions = [PRODUCT_SUITABILITY_INIT_OPTION];
    commonData?.productSuitability.forEach((item: ProductSuitabilityItem) => {
        productSuitabilityOptions.push({ value: item.id, label: item.name });
    });

    const countryCode = data?.countryCodes.find((codeItem: CountryCodeItem) => codeItem.code === country);

    return {
        productList,
        loading,
        productGroupOptions,
        productSuitabilityOptions,
        countryCode: countryCode || '',
        country,
    };
};

export default useProductListPageData;
