import React, { memo, useCallback } from 'react';
import { Theme } from "@emotion/react";

const styles = {
    container: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
    },
    radio: (selected?: boolean) => (theme: Theme) => ({
        border: `1px solid ${theme.colours.curiousBlue}`,
        borderRadius: '50%',
        height: 24,
        minWidth: 24,
        marginRight: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    label: (theme: Theme) => ({
        cursor: 'pointer',
        // fontSize: theme.forms.defaults.fontSize,
        // fontFamily: theme.forms.defaults.fontFamily,
    }),
    selection: (theme: Theme) => ({
        backgroundColor: theme.colours.curiousBlue,
        borderRadius: '50%',
        height: 14,
        width: 14,
    }),
};

type Props = {
    label: string|React.ReactNode,
    name: string,
    onChange: (name: string, value: any) => void,
    selected: boolean,
    value: any,
};

const FormRadioButton: React.FC<Props> = ({ label, name, onChange, selected, value }) => {
    const onClick = useCallback((e: any) => {
        onChange(name, value);
    }, [name, onChange, value]);

    return (
        <div css={styles.container} onClick={onClick}>
            <div css={styles.radio(selected)}>
                {selected ? <div css={styles.selection} /> : null}
            </div>
            <label css={styles.label}>{label}</label>
        </div>
    );
};

export default memo(FormRadioButton);
