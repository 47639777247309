import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import { getPrice } from "core/includes/formatters";
import { useAppState } from "core/contexts/AppContext";

type Props = {
    subtotalPrice: number,
    totalTax: number,
};

const CartPopupSubTotal: React.FC<Props> = ({ subtotalPrice, totalTax }) => {
    const [appState] = useAppState();
    const theme: Theme = useTheme();

    const style = css`
        margin-top: 16px;
        padding-left: 7px;
        color: ${theme.colours.shipGray};
        font-weight: ${theme.fonts.weights.bold};
    `;

    const priceBoxStyle = css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 4px;
    `;

    const subtotalStyle = css`
        color: ${theme.colours.boulder};
    `;

    const priceStyle = css`
        font-size: 19px;
    `;

    return (
        <div css={style}>
            <div css={priceBoxStyle}>
                <span css={subtotalStyle}>Subtotal</span>
                <span css={priceStyle}>{appState.ipCountry.currency}{getPrice(subtotalPrice)}</span>
            </div>
            <div css={priceBoxStyle}>
                <span css={subtotalStyle}>{appState.ipCountry.taxLabel}</span>
                <span css={priceStyle}>{appState.ipCountry.currency}{getPrice(totalTax)}</span>
            </div>
        </div>
    );
};

export default CartPopupSubTotal;
