import React from "react";
import { NOOP } from "core/constants";

type Props = {
    className?: string,
    colour?: string,
    onClick?: () => void,
};

const Bin: React.FC<Props> = (
    {
        className = "",
        colour = "red",
        onClick = NOOP,
    }
) => (
    <div className={className} onClick={onClick}>
        <svg viewBox="0 0 147.022 203.541">
            <path
                d="M3.925,34.241l11.59,133.447a12.7,12.7,0,0,0,12.329,11.544h91.289a12.7,12.7,0,0,0,12.329-11.544l11.59-133.447H3.925Zm40.034,128.09H39.526a5.623,5.623,0,0,1-5.587-5.125L26.366,57.467c-.323-4.433,2.4-8.081,6.049-8.081h5.68c3.648,0,6.788,3.694,6.972,8.081l3.925,99.739A4.924,4.924,0,0,1,43.959,162.331Zm37.079-4.387a5.266,5.266,0,0,1-5.31,5.079H71.295a5.266,5.266,0,0,1-5.31-5.079l-1.8-99.323c-.092-4.387,2.863-8.081,6.511-8.081h5.68c3.648,0,6.6,3.648,6.511,8.081Zm32.046-.739a5.694,5.694,0,0,1-5.587,5.125h-4.433a4.952,4.952,0,0,1-5.033-5.125l3.925-99.739c.185-4.433,3.325-8.081,6.972-8.081h5.68c3.648,0,6.372,3.694,6.049,8.081ZM147.022,23.02H0V11.338A4.631,4.631,0,0,1,4.618,6.72H142.4a4.631,4.631,0,0,1,4.618,4.618Z"
                transform="translate(0 24.31)" fill={`${colour}`}
            />
            <path
                d="M57.171,47.33H24.663c-8.635,0-15.653-10.62-15.653-23.688S16.029,0,24.663,0H57.171c8.635,0,15.653,10.62,15.653,23.688S65.806,47.33,57.171,47.33ZM24.663,15.423c-1.986,0-4.2,3.371-4.2,8.219s2.216,8.219,4.2,8.219H57.171c1.986,0,4.2-3.371,4.2-8.219s-2.216-8.219-4.2-8.219Z"
                transform="translate(32.594)" fill={`${colour}`}
            />
        </svg>
    </div>
);

export default Bin;