import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import LabelledField from "core/Components/Form/LabelledField";

type Props = {
    children: React.ReactNode,
    error?: string,
    name: string,
    label?: string,
    errorAsterisk?: boolean,
    labelWidth?: string,
    labelLeft?: string,
    className?: string,
};

const FormLabelledField: React.FC<Props> = (
    {
        children,
        error,
        name,
        label,
        errorAsterisk,
        labelWidth,
        labelLeft,
        className = "",
    }
) => {
    const theme: Theme = useTheme();

    const style = css`
        width: 100%;
        text-align: left;
        margin-bottom: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        label, input {
            font-size: 14px;

            ${theme.breakpoints.up("5xl")} {
                font-size: 16px;
            }

            ${theme.breakpoints.down("md")} {
                font-size: 12px;
            }
        }

        label {
            left: 24%;

            ${labelLeft && css`
                left: ${labelLeft};
            `};
        }
    ;

        .label {
            width: 22%;

            ${labelWidth && css`
                width: ${labelWidth};
            `};
        }

        .error-label {
            text-wrap: initial;
        }
    `;

    return (
        <LabelledField
            name={name}
            error={error}
            label={label}
            css={style}
            errorAsterisk={errorAsterisk}
            className={className}
        >
            {children}
        </LabelledField>
    );
};

export default FormLabelledField;
