import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import RoundButton from "core/Components/Buttons/RoundButton";

type Props = {
    isClickable: boolean
};

const CheckoutButton: React.FC<Props> = ({ isClickable }) => {
    const theme: Theme = useTheme();

    const style = css`
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 52px;
    `;

    return (
        <div css={style}>
            <RoundButton
                buttonWidth={232}
                buttonHeight={51}
                textFontSize={21}
                textColour={theme.colours.shipGray}
                // textHoverColour={theme.colours.malibu4}
                backgroundColour={'white'}
                useTextHover={false}
                // isClickable={isClickable}
                {...isClickable && {
                    linkURL: 'checkout'
                }}
            >
                <span>Checkout</span>
            </RoundButton>
        </div>
    );
};

export default CheckoutButton;