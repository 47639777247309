import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
    noGap?: boolean,
};

const ItemContainer: React.FC<Props> = ({ children, noGap = false }) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        flex-direction: column;
        
        ${!noGap && css`
            gap: 22px;

            ${theme.breakpoints.down('xs')} {
                gap: 28px;
            }
        `};
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default ItemContainer;