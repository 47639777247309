import React from "react";
import { css, Theme, useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

import RoundChevronButton from "core/Components/Buttons/RoundChevronButton";
import useChevron from "core/hooks/useChevron";

type Props = {
    paddingTop: number,
    itemGroupBoxWidth: number,
};

const ChevronContainer: React.FC<Props> = ({ paddingTop, itemGroupBoxWidth }) => {
    const theme: Theme = useTheme();
    const navigate = useNavigate();

    const { chevronSize, chevronColour, chevronBackground } = useChevron('cart');
    const chevronBackgroundHover = theme.colours.red[550];

    const style = css`
        padding-top: ${paddingTop}px;
        padding-right: 21px;

        ${theme.breakpoints.only('xl')} {
            padding-right: 19px;
        }

        ${theme.breakpoints.only('lg')} {
            padding-right: 17px;
        }

        ${theme.breakpoints.only('md')} {
            padding-top: ${paddingTop + 7}px;
            padding-right: 23px;
        }

        ${theme.breakpoints.only('sm')} {
            padding-top: ${paddingTop + 3}px;
            padding-right: 16px;
        }

        ${theme.breakpoints.down('xs')} {
            position: absolute;
            top: 17px;
            left: calc((100% - ${itemGroupBoxWidth}px) / 2);
        }
    `;

    return (
        <div css={style}>
            <RoundChevronButton
                type={"left"}
                onClick={() => navigate(-1)}
                roundColour={chevronBackground}
                roundHoverColour={chevronBackgroundHover}
                chevronColour={chevronColour}
                size={chevronSize}
            />
        </div>
    );
};

export default ChevronContainer;