import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
    itemGroupBoxWidth?: number,
};

const SummaryAndButtonContainer: React.FC<Props> = ({ children, itemGroupBoxWidth = 0 }) => {
    const theme: Theme = useTheme();

    const style = css`
        ${theme.breakpoints.only('md')} {
            display: flex;
            justify-content: space-between;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default SummaryAndButtonContainer;