import colours from 'theme/colours';

export const PRODUCT_PAGE_TYPE = "product";

export const DROPDOWN_BACKGROUND_COLOUR = colours.grey[100];

export const SORT_DROPDOWN_OPTION_BACKGROUND_COLOUR = colours.orangePeel;

export const SORT_DROPDOWN_OPTION_BACKGROUND_HOVERED_COLOUR = colours.koromiko3;

export const PRODUCT_PRICE_RANGE_OPTIONS = [
    { value: "", label: "All price range" },
    { value: "0/50", label: "$0 - $50" },
    { value: "50/150", label: "$50 - $150" },
    { value: "150/", label: "$150+" },
];

export const PRODUCT_SORT_OPTIONS = [
    { value: "", label: "Sort by" },
    { value: "price/asc", label: "Price low-high" },
    { value: "price/desc", label: "Price high-low" },
    { value: "popularity/desc", label: "Most popular" },
];

export const HEAR_FROM_OPTIONS = [
    {
        id: "",
        value: "Select an option",
    },
    {
        id: "facebook",
        value: "Facebook",
    },
    {
        id: "instagram",
        value: "Instagram",
    },
    {
        id: "youtube",
        value: "YouTube",
    },
    {
        id: "wtbox",
        value: "Writer’s Toolbox website",
    },
    {
        id: "wordOfMouth",
        value: "Word of mouth",
    },
    {
        id: "email",
        value: "Email",
    },
    {
        id: "writingCoach",
        value: "Writing coach",
    },
];

export const DROPDOWN_INIT_OPTION = { id: "", value: "Select an option" };

export const PRODUCT_GROUP_INIT_OPTION = { value: "", label: "All categories" };

export const PRODUCT_SUITABILITY_INIT_OPTION = { value: "", label: "All suitabilities" };

export const PAYMENT_OPTIONS = [
    {
        name: "invoice",
        label: "Invoice"
    },
    {
        name: "credit-card",
        label: "Credit card",
        additionalLabel: "(Fastest to ship)"
    },
];

export const PRODUCT_ORDER_INIT_FIELDS = {
    firstName: "",
    lastName: "",
    schoolName: "",
    streetNo: "",
    streetName: "",
    suburb: "",
    city: "",
    postCode: "",
    deliveryCountry: {},
    contactEmail: "",
    emailToInvoice: "",
    orderNo: "",
    notes: "",
    promoCode: "",
    paymentOption: PAYMENT_OPTIONS[0].name,
};

export const ORDER_RESPONSE_MESSAGE = {
    success: "Order placed.",
    fail: "Failed to place order.",
};

export const ORDER_DISCOUNT_MESSAGE = {
    fail: "Oops, that Promo code doesn't apply to the items in your order.",
};
