import React, { memo, useRef } from 'react';
import { css, Theme, useTheme } from "@emotion/react";

import useElementDimensions from "core/hooks/useElementDimensions";
import { getPrice } from "core/includes/formatters";

type Props = {
    item: CartProduct,
    hasPromoPrice?: boolean,
    isLast?: boolean,
    biggestQuantity?: number,
}

const SummaryItemRow: React.FC<Props> = (
    {
        item,
        hasPromoPrice = false,
        isLast = false,
        biggestQuantity = 1,
    }
) => {
    const theme: Theme = useTheme();
    const itemHasDiscount = false;
    const totalPrice = (Math.round(item.unitPrice * item.quantity * 100) / 100).toFixed(2);
    const promoPrice = 0;
    const priceRef = useRef<HTMLDivElement>(null);
    const priceWidth = useElementDimensions(priceRef.current).width;
    const extraContent = "\\00a0\\00a0".repeat(biggestQuantity.toString().length - item.quantity.toString().length);

    const styles = css`
        display: flex;
        width: 100%;
        font-size: 18px;
        line-height: 25px;
        justify-content: space-between;

        ${theme.breakpoints.down('lg')} {
            font-size: 16px;
            line-height: 19px;
        }

        ${isLast ? css`
            margin-bottom: 43px;
        ` : css`
            margin-bottom: 30px;
            ${itemHasDiscount && css`
                margin-top: -20px;
            `}
        `}
    `;

    const titleStyle = css`
        width: 212px;
        margin-right: 34px;

        ${itemHasDiscount && css`
            margin-top: 20px;
        `}
        ${theme.breakpoints.only('lg')} {
            width: 183px;
        }

        ${theme.breakpoints.only('md')} {
            width: 287px;
        }

        ${theme.breakpoints.down('sm')} {
            width: 291px;
        }
    `;

    const promoLabelStyle = css`
        font-size: 13px;
        line-height: 16px;
        color: ${theme.colours.gold};

        ${theme.breakpoints.down('lg')} {
            font-size: 10px;
            line-height: 13px;
        }
    `;

    const priceWrapperStyle = css`
        text-align: right;
        ${hasPromoPrice && css`
            width: 115px;

            ${theme.breakpoints.down('sm')} {
                width: fit-content;
                min-width: 115px;
            }
        `}
    `;

    const priceContainerStyle = css`
        position: relative;
        text-align: right;
        margin-right: 6px;
    `;

    const priceLabelStyle = css`
        width: fit-content;
        margin-left: auto;
    `;

    const lineThroughStyle = css`
        position: absolute;
        top: 50%;
        right: -5%;
        height: 3px;
        // Needs to be longer than the price itself
        width: ${priceWidth + 10}px;
        transform: translateY(-50%);
        background: ${theme.colours.gold};
        border-radius: 3px;

        ${theme.breakpoints.only('md')} {
            right: -3%;
        }
    `;

    const promoPriceStyle = css`
        text-align: right;
        color: ${theme.colours.gold};

        ${theme.breakpoints.up('xl')} {
            font-size: 18px;
            line-height: 25px;
        }
    `;

    const quantityStyle = css`
        white-space: nowrap;
        ${itemHasDiscount && css`
            margin-top: 20px;
        `};

        // To make all the quantities the same size and make sure they are aligned the same

        :after {
            content: "${extraContent}";
            white-space: pre;
        }
    `;

    return (
        <div css={styles}>
            <div css={titleStyle}>{item.title}</div>
            <div css={quantityStyle}>x {item.quantity}</div>
            <div css={priceWrapperStyle}>
                {itemHasDiscount && (
                    <span css={promoLabelStyle}>With discount</span>
                )}
                <div css={priceContainerStyle}>
                    <div css={priceLabelStyle} ref={priceRef}>
                        ${getPrice(totalPrice)}
                    </div>
                    {itemHasDiscount && (
                        <div css={lineThroughStyle} />
                    )}
                </div>
                {itemHasDiscount && (
                    <div css={promoPriceStyle}>${getPrice(promoPrice)}</div>
                )}
            </div>
        </div>
    );
};

export default memo(SummaryItemRow);