import React, { ReactNode } from "react";
import { css, Theme, useTheme } from "@emotion/react";

type Props = {
    children: ReactNode,
    paddingTop: number,
};

const PageRightSection: React.FC<Props> = ({ children, paddingTop }) => {
    const theme: Theme = useTheme();

    const style = css`
        display: flex;
        flex-direction: column;
        padding-top: ${paddingTop}px;
        padding-left: 55px;
        width: 36.5%;
        background-color: ${theme.colours.curiousBlue2};

        ${theme.breakpoints.up("3xl")} {
            padding-left: 90px;
            width: 38%;
        }

        ${theme.breakpoints.only("xl")} {
            padding-left: 63px;
            width: 40%;
        }

        ${theme.breakpoints.only("lg")} {
            padding-left: 20px;
            width: 37%;
        }

        ${theme.breakpoints.down("md")} {
            align-items: center;
            padding-left: unset;
            width: 100%;
        }

        ${theme.breakpoints.only("md")} {
            min-height: 256px;
        }

        ${theme.breakpoints.only("sm")} {
            min-height: 343px;
        }

        ${theme.breakpoints.down("xs")} {
            min-height: 417px;
        }
    `;

    return (
        <div css={style}>
            {children}
        </div>
    );
};

export default PageRightSection;