import React from "react";
import { css, Theme, useTheme } from "@emotion/react";

import Bin from "core/Components/Icons/Bin";
import { useCartItemsContext } from "core/contexts/CartItemsContext";

type Props = {
    lineItemId: number,
    itemGroupBoxWidth: number,
    itemBoxWidth: number,
    removeItem: (lineItemId: number) => void,
};

const ItemRemoveButton: React.FC<Props> = ({ lineItemId, itemGroupBoxWidth, itemBoxWidth, removeItem }) => {
    const theme: Theme = useTheme();
    const { setConfirmationPopup } = useCartItemsContext();

    const onClickHandler = () => {
        removeItem(lineItemId);
    };

    const style = css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(${itemGroupBoxWidth - itemBoxWidth}px);
        height: 100%;
        padding-top: 54px;

        ${theme.breakpoints.down('xs')} {
            display: none;
        }
    `;

    const iconDivStyle = css`
        cursor: pointer;
    `;

    const binStyle = css`
        width: 11px;
        height: 15px;
    `;

    return (
        <div css={style}>
            <div css={iconDivStyle}>
                <Bin
                    css={binStyle}
                    onClick={() => setConfirmationPopup('remove-item', true, { eventFunction: onClickHandler })}
                />
            </div>
        </div>
    );
};

export default ItemRemoveButton;